import { CheckInputModel } from '../model';

let unique = 1;

export class ViewModel extends CheckInputModel {
  constructor(params, element) {
    super(params, element);

    if (params.partially) {
      this.setPartiallyState(ko.toJS(params.partially));
      if (ko.isObservable(params.partially)) {
        this.subscriptions.push(
          params.partially.subscribe((v) => {
            this.setPartiallyState(v);
          })
        );
      }
    }
  }

  setPartiallyState(state) {
    this.element.classList.toggle('input-checkbox--partially', state);
  }

  onLabelClick() {
    if (typeof this.onChange === 'function') this.onChange(!this.checked());
    else return true;
  }
}
