export default {
  getParam: function(paramName) {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has(paramName)) return null;
    return searchParams.get(paramName);
  },
  setParam: function(paramName, paramValue) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramName, paramValue);
    this.saveState(searchParams.toString());
  },
  removeParam: function(paramName) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
    this.saveState(searchParams.toString());
  },
  saveState: function (searchParams) {
    history.pushState(
      null,
      '',
      location.origin + location.pathname + '?' + searchParams,
    );
  },
  removeSearchParams: function (currentParams, cb) {
    currentParams = new URLSearchParams(currentParams);
    const keys = [];
    for (let key of currentParams.keys()) {
      if (cb(key)) keys.push(key);
    }
    keys.forEach(key => currentParams.delete(key));

    return currentParams.toString();
  },
  setSearchParams: function (currentParams, params) {
    currentParams = new URLSearchParams(currentParams);
    const paramsString = $.param(params);
    const newParams = new URLSearchParams(paramsString);

    for (let pair of newParams.entries()) {
      currentParams.append(pair[0], pair[1]);
    }

    return currentParams.toString();
  },
};
