export class ViewModel {
  constructor(params, componentInfo) {
    this.subscriptions = [];

    this.element = componentInfo.element;

    if ('text' in params) this.text = params.text;
    else this.text = componentInfo.templateNodes.map(n => n.textContent).join('');
  }

  onRender() {
    this.element.classList.add('inited');
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
