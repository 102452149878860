export class ViewModel {
  constructor(params, element) {
    this.element = element;

    this.disabled = params.disabled;

    this.afterAddTag = params.afterAddTag;

    this.value = params.value;
    this.list = params.list;
    this.clientId = params.clientId;

    this.addButton =
      'addButton' in params
        ? params.addButton
        : {
            label: null,
          };

    this.popoverOptions = params.popover || {};

    this.dropdownMenuSearchTerm = ko.observable('');

    this.dropdownMenuFilteredList = ko.computed(() => {
      const value = this.value();
      if (typeof this.list === 'function') {
        const list = this.list()
          .filter((t) => !t.isAuto)
          .filter((t) => !value.find((tag) => tag.name == t.name))
          .filter((t) => {
            if (!this.dropdownMenuSearchTerm()) return true;
            const term = this.dropdownMenuSearchTerm().toLowerCase();
            return t.name.toLowerCase().includes(term);
          });
        list.sort((a, b) => (a.name < b.name ? -1 : 1));
        return list;
      } else {
        return false;
      }
    });

    this.dropdownMenuFoundExactTagInFilteredList = ko.computed(() => {
      if (
        typeof this.dropdownMenuFilteredList === 'function' &&
        this.dropdownMenuFilteredList()
      ) {
        return this.dropdownMenuFilteredList().find(
          (t) => this.dropdownMenuSearchTerm() === t.name,
        );
      } else {
        return false;
      }
    });

    this.dropdownMenuFoundExactTagInValue = ko.computed(() => {
      return this.value().find((t) => this.dropdownMenuSearchTerm() === t.name);
    });

    this.dropdownMenuControlCreateTagError = ko.observable(false);
    this.serverError = ko.observable('');
    this.dropdownMenuControlCreateTagButtonDisabled = ko.computed(() => {
      return (
        this.serverError() ||
        this.dropdownMenuFoundExactTagInFilteredList() ||
        this.dropdownMenuFoundExactTagInValue()
      );
    });

    this.dropdownMenuSearchTerm.subscribe((_) => {
      this.dropdownMenuControlCreateTagError(false);
      this.serverError('');
    });

    this.dropdownMenuControlCreateTagButtonTitle = ko.computed(() => {
      if (this.dropdownMenuFoundExactTagInValue()) {
        return 'Тег уже выбран';
      } else if (this.dropdownMenuFoundExactTagInFilteredList()) {
        return 'Тег уже существует';
      } else if (this.serverError()) {
        return this.serverError();
      } else {
        return 'Создать новый тег';
      }
    });

    this.dropdownMenuHidden = function () {
      this.dropdownMenuSearchTerm('');
    };

    this.createTag = function () {
      if (this.dropdownMenuControlCreateTagButtonDisabled()) {
        this.dropdownMenuControlCreateTagError(true);
        return;
      }

      const newTag = {
        id: this.dropdownMenuSearchTerm(),
        name: this.dropdownMenuSearchTerm(),
      };
      var obj = {
        clients: [this.clientId],
        tags: [newTag],
      };

      $.ajax({
        method: 'POST',
        url: '/foquz/foquz-contact/add-tags',
        data: obj,
        dataType: 'json',
        success: () => {
          this.value.push(newTag);
          this.list.push(newTag);
          if (typeof this.afterAddTag === 'function') {
            this.afterAddTag();
          }
          $(this.popover).popover('hide');
        },
        error: (response) => {
          this.serverError(response.responseJSON.errors.message);
          this.dropdownMenuControlCreateTagError(true);
        },
      });
    };

    this.removeTag = function (tag) {
      if (this.clientId === undefined) {
        this.value.remove(tag);
      } else {
        var obj = {
          clients: [this.clientId],
          tags: [tag.name],
        };
        $.post(
          '/foquz/foquz-contact/remove-tags',
          obj,
          (response) => {
            if (response.success) {
              this.value.remove(tag);
            }
          },
          'json',
        );
      }
    };

    this.addTag = function (tag) {
      if (this.clientId == undefined) {
        this.value.push(tag);
      } else {
        var obj = {
          clients: [this.clientId],
          tags: [{ id: tag.name, name: tag.name }],
        };

        $.post(
          '/foquz/foquz-contact/add-tags',
          obj,
          (response) => {
            if (response.success) {
              this.value.push(tag);
            }
          },
          'json',
        );
      }
    };

    this.popover = null;
    this.updatePopover = window.utils.debounce(() => {
      this.popover.popover('update');
      setTimeout(() => {
        this.popover.popover('update');
      }, 100);
    }, 100);

    this.initializing = ko.observable(true);

    this.onInit = () => {
      const tagPopover = $(this.element).find(
        '.clients__tag-input-add-tag-button-wrapper',
      );
      tagPopover.on('hidden.bs.popover', () => {
        this.dropdownMenuHidden();
      });
      this.popover = tagPopover;
    };
  }
}
