function updateUpwardButton() {
  $('.upward-button').toggleClass(
    'upward-button--hidden',
    $(window).scrollTop() < 200
  );
}

$(document).ready(() => {
  updateUpwardButton();

  let throttled = _.throttle(updateUpwardButton, 200);
  $(window).scroll(throttled);
})
