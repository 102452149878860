ko.bindingHandlers.tagsSelectPopover = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewMode,
    bindingContext,
  ) {
    const $element = $(element);

    let parent = $element.parents('.modal-content,.foquz-dialog__content').get(0);

    $element.popover({
      html: true,
      sanitize: false,
      placement: 'bottom',
      container: parent,
      boundary: 'viewport',
      template: `
                    <div class="popover clients__tag-input-dropdown" role="tooltip">
                        <div class="popover-body clients__tag-input-dropdown-menu"></div>
                    </div>
                `,
      content: `
                <div class="">
                    <div class="clients__tag-input-dropdown-menu-control-wrapper">
                        <input class="form-control clients__tag-input-dropdown-menu-control"
                               data-bind="textInput: dropdownMenuSearchTerm">

                        <!-- ko if: dropdownMenuSearchTerm().length !== 0 -->
                        <button class="btn clients__tag-input-dropdown-menu-control-create-tag-button"
                                data-bind="click: function() {
                                  createTag();
                                 },
                                attr: {
                                  disabled: dropdownMenuControlCreateTagButtonDisabled() && dropdownMenuControlCreateTagError(),
                                  title: dropdownMenuControlCreateTagButtonTitle
                                }"></button>
                        <!-- /ko -->
                    </div>

                    <!-- ko if: dropdownMenuControlCreateTagError -->
                        <span class="form-error" data-bind="text: dropdownMenuControlCreateTagButtonTitle"></span>
                    <!-- /ko -->

                    <!-- ko if: dropdownMenuFilteredList().length > 0 -->
                    <div class="clients__tag-input-dropdown-menu-list" data-bind="fScrollbar">
                        <!-- ko template: {
                            foreach: dropdownMenuFilteredList,
                            afterRender: updatePopover
                        } -->
                        <a class="dropdown-item"
                           data-bind="click: function() { closePopover(); $component.addTag($data); }, text: $data.name"></a>
                        <!-- /ko -->
                    </div>
                    <!-- /ko -->

                    <!-- ko if: dropdownMenuFilteredList().length === 0 -->
                    <span class="clients__tag-input-dropdown-menu-message">Совпадений не найдено</span>
                    <!-- /ko -->
                </div>
                `,
    });

    const bodyClickHandler = function (e) {
      if (e.target === element) return;
      if ($(e.target).parents('.clients__tag-input-dropdown-menu').length)
        return;
      $(element).popover('hide');
    };

    $element.on('inserted.bs.popover', () => {
      const content = $($element.data('bs.popover').tip)
        .find('.popover-body')
        .children()[0];
      const context = bindingContext.extend({
        closePopover: function () {
          $(element).popover('hide');
        },
      });
      ko.applyBindings(context, content);
    });
    $element.on('shown.bs.popover', () => {
      $('body').on('click', bodyClickHandler);
    });

    $element.on('hidden.bs.popover', () => {
      $('body').off('click', bodyClickHandler);
    });

    $('body').on('show.bs.popover', (e) => {
      if (e.target !== element) {
        $(element).popover('hide');
      }
    });
  },
};
