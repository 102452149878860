import { dateToString } from "@/utils/date/date-to-string";
import { today } from "@/utils/date/today";
import { startOfMonth } from "@/utils/date/start-of-month";
import { endOfMonth } from "@/utils/date/end-of-month";
import { startOfWeek } from "@/utils/date/start-of-week";
import { endOfWeek } from "@/utils/date/end-of-week";
import { cloneDate } from "@/utils/date/clone-date";

export const ranges = [
  {
    name: "Сегодня",
    id: "today",
    getRange() {
      let str = dateToString(today);
      return {
        from: str,
        to: str,
      };
    },
  },
  {
    name: "С начала недели",
    id: "week-start",
    getRange() {
      let weekStart = startOfWeek(today);
      return {
        from: dateToString(weekStart),
        to: dateToString(today),
      };
    },
  },
  {
    name: "Прошлая неделя",
    id: "prev-week",
    getRange() {
      let weekStart = startOfWeek(today);

      let prevWeekEnd = cloneDate(weekStart);
      prevWeekEnd.setDate(prevWeekEnd.getDate() - 1);
      let prevWeekStart = startOfWeek(prevWeekEnd);

      return {
        from: dateToString(prevWeekStart),
        to: dateToString(prevWeekEnd),
      };
    },
  },
  {
    name: "Последние 7 дней",
    id: "last-week",
    getRange() {
      let start = cloneDate(today);
      start.setDate(start.getDate() - 6);
      return {
        from: dateToString(start),
        to: dateToString(today),
      };
    },
  },
  {
    name: "Прошлый месяц",
    id: "prev-month",
    getRange() {
      let monthStart = startOfMonth(today);
      let prevMonthEnd = cloneDate(monthStart);
      prevMonthEnd.setDate(prevMonthEnd.getDate() - 1);
      let prevMonthStart = startOfMonth(prevMonthEnd);
      return {
        from: dateToString(prevMonthStart),
        to: dateToString(prevMonthEnd),
      };
    },
  },
  {
    name: "Последний месяц",
    id: "last-month",
    getRange() {
      let start = cloneDate(today);
      start.setMonth(start.getMonth() - 1);
      start.setDate(start.getDate() + 1);
      return {
        from: dateToString(start),
        to: dateToString(today),
      };
    },
  },
  {
    name: "Произвольный",
    id: "free",
    getRange() {
      return {
        from: "",
        to: "",
      };
    },
  },
];
