import { FoquzComponent } from "Models/foquz-component";

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params, element);

    this.type = params.type;
    this.value = params.value;
    this.point = params.point;
    this.comment = params.comment;
    this.clarifyingComments = params.clarifyingComments;
    this.imgIconUrl = params.icon;
    this.skipped = params.skippedж
    this.variantSkipped = params.variantSkipped;

    this.text = ko.computed(() => {
      let value = ko.toJS(this.value) || 0;
      value = parseFloat(value);
      value = Math.floor(value * 10) / 10;
      return value;
    });

    this.hasIcon = ko.computed(() => {
      if (ko.toJS(this.imgIconUrl)) return true;
      let type = ko.toJS(this.type);
      return ["heart", "face", "like", "smile"].includes(type);
    });

    this.imgIcon = ko.computed(() => {
      if (!ko.toJS(this.imgIconUrl)) return false;
      return ko.toJS(this.imgIconUrl);
    });

    this.icon = ko.computed(() => {
      let icon = "rating-" + ko.toJS(this.type) + "-" + ko.toJS(this.point);
      return icon;
    });
  }

  remove() {
    this.emitEvent("remove");
  }
}
