import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-date-text');

    this.from = ko.pureComputed(() => ko.toJS(params.from));
    this.to = ko.pureComputed(() => ko.toJS(params.to));

    this.format = ko.pureComputed(() => {
      if (this.from() && this.to()) return 'period';
      if (this.from()) return 'from';
      if (this.to()) return 'to';
      return null;
    })
  }
}
