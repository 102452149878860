import {
  PollPublishEvent,
  PollSettingsUpdateEvent,
  PollStatsUpdateEvent,
} from "@/utils/events/poll";
import { ApiUrl } from "Utils/url/api-url";

/**
  createdAt: 1621929791
  datetime_end: "Fri May 21 2021 11:11:00 GMT+0300"
  datetime_start: "Wed May 12 2021 22:22:00 GMT+0300"
  folders: "[]"
  id: 1279
  isAuto: 0
  isNew: 1
  isPublished: 0
  name: "Мой тестовый опрос"
  pagesCount: 2
  questionsCount: 1
  timeToPass: null

  для тестового режима:
  passed сколько пройдено
  testAnswersLimit
	publishedAnswersLimit
  testCompleted
	publishedCompleted
*/

const cache = {};

function update(id) {
  return fetch(ApiUrl("poll/view", { id, fields: "countAnswers,limit_count" }))
    .then((res) => res.json())
    .then((json) => json.model);
}

export function PollInfoModel(poll) {
  if (cache[poll.id]) return cache[poll.id];

  const isPublished = ko.observable(poll.isPublished);
  const answersPassed = ko.observable(poll.passed);
  const testAnswersLimit = poll.testAnswersLimit;
  const publishedAnswersLimit = ko.observable(poll.publishedAnswersLimit);
  const hasPublishedAnswersLimit = ko.computed(
    () => publishedAnswersLimit() !== null
  );

  const answersLimit = ko.computed(() => {
    return isPublished() ? publishedAnswersLimit() : testAnswersLimit;
  });

  const answersLeft = ko.computed(() => {
    return Math.max(answersLimit() - answersPassed(), 0);
  });

  let limitText = ko.computed(() => {
    return _t("main", "{num1} из {num2}", {
      num1: answersLeft(),
      num2: answersLimit(),
    });
  });

  const model = {
    id: poll.id,
    createdAt: moment(poll.createdAt * 1000).format("DD.MM.YYYY HH:mm"),
    start: null,
    finish: null,
    questions: poll.questionsCount,
    pages: poll.pagesCount,
    timeToPass: poll.timeToPass,
    isPublished,
    limitText,

    publishedAnswersLimit,
    testAnswersLimit,
    answersPassed,
    answersLeft,
    hasPublishedAnswersLimit,
  };

  if (poll.datetime_start) {
    model.start = moment(poll.datetime_start).format("DD.MM.YYYY HH:mm");
  }
  if (poll.datetime_end) {
    model.finish = moment(poll.datetime_end).format("DD.MM.YYYY HH:mm");
  }

  function updateModel() {
    update(poll.id).then((data) => {
	  answersPassed(data.countAnswers);
      publishedAnswersLimit(data.limit_count);
    });
  }

  if (!poll.isPublished) {
    PollPublishEvent.on((params) => {
      if (params.id == poll.id) {
        isPublished(true);
        updateModel();
      }
    });
  }

  PollStatsUpdateEvent.on((params) => {
    if (params.id == poll.id) {
      updateModel();
    }
  });

  PollSettingsUpdateEvent.on(({ id, settings }) => {
    if (id == poll.id) {
      updateModel();
    }
  });

  cache[poll.id] = model;

  return model;
}
