/**
 * SVG-иконка
 * name - по названию файла в папке ko/icons
 * width, height
 * size: xs (8), sm (12), md (16), lg (20), xl (24) || любое число
 * color: primary, secondary, danger, success, light, black, white || любой цвет
 * button: true|false
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-icon', { model, template });
