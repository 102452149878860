import { detectTouch } from 'Utils/detect/touch';
import { FoquzComponent } from 'Models/foquz-component';
export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-calendar');

    this.input = ko.observable(null);

    this.single = 'period' in params ? !params.period : true;

    this.calendarOptions = {
      single: this.single,
      showDropdowns: params.showDropdowns,
      mobileView: params.mobileView
    };

    this.clearable = params.clearable;
    this.value = params.value;
    this.disabled = params.disabled;
    this.readMode = params.readMode;
    this.invalid = params.invalid;

    this.inline = params.inline;
    if (this.inline) {
      element.classList.add('fc-calendar--inline');
    }

    this.placeholder =
      'placeholder' in params
        ? params.placeholder
        : this.single
        ? '00.00.0000'
        : '00.00.0000 - 00.00.0000';
    this.mask = this.single ? '99.99.9999' : null;

    this.detectTouch = detectTouch();


    ko.applyBindingsToNode(element, {
      css: {
        'fc-calendar--invalid': this.invalid
      }
    })

  }

  reset() {
    this.value('');
    $(this.element).find('.form-control').val('').trigger('change');
    this.resize();
  }

  resize() {
    let input = this.input();
    if (typeof input.resize === 'function') input.resize();
  }

  onElementRender() {
    if (ko.toJS(this.readMode)) return;

    let el = this.input().getInput();

    ko.applyBindingsToNode(el, {
      fbCalendar: this.calendarOptions,
      value: this.value,
      event: {
        'fbcalendar.apply': () => {
          this.resize();
        }
      }
    });
  }
}

/**
 * <div class="input-group date-input-group"
     data-bind="dateInputGroup, let: { input: ko.observable(null) },
     css: {
       'date-input-group--has-value': clearable && value()
     },">

  <input type="text"
         class="form-control"
         data-bind="
               disable: disabled,
               element: input,
               foquzMask,
               maskPattern: mask,
               value: value,
               attr: { placeholder: placeholder },
               fbCalendar: calendarOptions">

  <!-- ko if: clearable && value() -->
  <foquz-icon params="icon: 'times'"
              class="f-icon-sm f-icon-danger date-input-group__clear"
              data-bind="click: function() {reset()}"></foquz-icon>
  <!-- /ko -->


  <i class="date-input-group__icon"
     data-bind="click: function() { input().focus() }"></i>
</div>
 */
