import { FoquzComponent } from "Models/foquz-component";

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.inited = ko.observable(false);

    this.multiple = params.multiple;
    this.dense = params.dense;
    this.value = params.value;

    let value = ko.unwrap(params.value);

    this._value = this.multiple
      ? ko.observableArray(value)
      : ko.observable(value);


    this.blocked = ko.observable(false);

    this.value.subscribe((v) => {
      if (this.blocked()) {
        this.blocked(false);
        return;
      }
      this.blocked(true);
      this._value(v);
      this.blocked(false);
    });

    this._value.subscribe((v) => {
      if (this.blocked()) {
        this.blocked(false);
        return;
      }
      
      this.blocked(true);
      this.value(v);
      this.blocked(false);
    });

    this.except = params.except;
    this.exceptText = params.exceptText || "Все, кроме";
    this.exceptCount = params.exceptCount || 1;
    this.withExcept = params.except && ko.isObservable(params.except);

    this.allowUnset = params.allowUnset;
    this.emptyOption = params.emptyOption;

    this.mode = params.mode;

    this.disabled = params.disabled;
    if (this.mode == "view") {
      this.disabled = ko.observable(true);
    }

    this.selectRef = ko.observable(null);

    this.config = {
      containerCssClass: `${params.dense ? "" : "form-control"}`,
      wrapperCssClass: `select2-container--form-control ${
        this.mode == "view" ? "f-select2--mode_view" : ""
      }`,
      dropdownCssClass: params.dense ? "dense-form-group__dropdown" : "",
      placeholder: params.placeholder || "",
      allowClear: params.allowClear,
      ...(params.selectConfig || {}),
    };

    if ("search" in params) {
      if (params.search !== undefined) {
        let search = parseInt(params.search);
        this.config.minimumResultsForSearch = search || 0;
      }
    }
  }

  get element() {
    let ref = this.selectRef();
    if (ref) return ref;
    return null;
  }

  onRender() {
    this.inited(true);

    // this.value.subscribe(v => {
    //   $(this.selectRef()).val(v).trigger('change.select2');
    // })
  }
}
