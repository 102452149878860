/**
 * label
 * checked
 * disabled
 * onChange
 * hint
 *
 * slots:
 *   - label
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-switch', { model, template });
