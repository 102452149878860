export class ViewModel {
  constructor(params) {
    this.value = ko.observable(ko.utils.unwrapObservable(params.value));
    this.observableValue = params.observableValue;

    this.input = ko.observable(null);
    this.hidden = ko.observable(null);

    this.disabled = params.disabled;

    this.allowEmpty = params.allowEmpty;
    this.placeholder = params.placeholder || '';

    this.onChange = params.onChange;
    this.onCancel = params.onCancel;
    this.onSave = params.onSave;

    this.maxLength = params.maxLength;

    if (typeof this.onChange == 'function') {
      this.value.subscribe((v) => this.onChange(v));
    }

    $('body').on('before.print', () => {
      this.cancel();
    });

    if (ko.isObservable(this.observableValue)) {
      this.observableValue(this.value().trim())
      this.observableValue.subscribe(v => {
        if (!v) this.cancel();
      });
      this.value.subscribe(v => this.observableValue(v.trim()))
    }
  }

  cancel() {
    if (ko.isObservable(this.observableValue)) {
      this.observableValue('')
    }
    if (typeof this.onCancel == 'function') {
      this.onCancel();
    }
  }

  save() {
    const value = this.value().trim();

    if (this.allowEmpty) {
      this.onSave(value);
    } else if (value.length > 0) {
      this.onSave(value);
    } else {
      this.cancel();
    }
  }

  onRender() {
    let input = $(this.input());
    let hiddenInput = $(this.hidden());

    function updateControlWidth() {
      // hiddenInput.show();
      input.width(hiddenInput.outerWidth());
      //hiddenInput.hide();
    }

    input.on('input', () => updateControlWidth());

    updateControlWidth();
  }
}
