

const component = {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      const $element = $(componentInfo.element);
      $element.addClass([
        'modal-dialog',
        'modal-dialog-md',
        'modal-dialog-centered',
        'confirm-modal-dialog',
        'initializing',
      ]);

      const ViewModel = function () {
        this.title = params.data.title;
        this.text = params.data.text;
        this.confirmButtonText = params.data.confirmText;
        this.cancelButtonText = params.data.cancelText || _t('Отменить');
        this.mode = params.data.mode || 'danger';

        this.cancel = function () {
          if ('cancel' in params) {
            params.cancel();
          }
        };

        this.submit = function () {
          if ('submit' in params) {
            params.submit(true);
          }
        };
      };

      const viewModel = new ViewModel();
      viewModel.initializing = ko.observable(true);

      viewModel.onInit = function () {
        $element.removeClass('initializing');
        viewModel.initializing(false);
      };
      return viewModel;
    },
  },
  template: `
      <!-- ko template: { afterRender: onInit } -->
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title"
              data-bind="text: title"></h2>

          <button type="button"
                  class="close"
                  aria-label="Close"
                  data-bind="click: function() { cancel(); }">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body"
            data-bind="html: text">

        </div>

        <div class="modal-footer">
          <div class="modal-actions">
            <button type="button"
                    class="f-btn f-btn-link"
                    data-bind="click: function() { cancel(); }, text: cancelButtonText">

            </button>

            <button type="submit"
                    class="f-btn"
                    data-bind="
                      click: function() { submit(); },
                      text: confirmButtonText,
                      css: 'f-btn-' + mode
                    ">

            </button>
          </div>
        </div>
      </div>
      <!-- /ko -->
    `,
};

ko.components.register('confirm-modal-dialog', component);
