const { observable, observableArray, computed } = ko;

/**
 * @typedef FolderData
 * @property id {String}
 * @property name {String}
 * @property inactive {Boolean}
 * @property children {FolderData[]}
 */

function handleFolder(folder, parent) {
  const { id, name, children, ...folderFields } = folder;

  return {
    id,
    name,
    normalizedName: name.toLowerCase(),
    parent,
    ...folderFields,
    children: children.map((item) => handleFolder(item, id)),
  };
}

function handleTree(tree) {
  const formattedTree = tree.map((item) => handleFolder(item, null));
  return formattedTree;
}

/**
 *
 * @param getFolders {Function.<FolderData[]>}
 * @returns
 */
export function useFoldersList(getFolders) {
  const loading = observable(true);
  const loaded = observable(false);
  const originalTree = observableArray([]);
  const query = observable("");

  const normalizedQuery = computed(() => {
    return query().trim().toLowerCase();
  });

  const filterFolder = (folder, q) => {
    const { normalizedName, children, inactive, ...folderFields } = folder;
    const nameMatches = q ? normalizedName.includes(q) : true;
    const formattedChildren = children.map(item => filterFolder(item, q)).filter(Boolean);
    const activeChildren = formattedChildren.filter(
      (child) => !child.inactive || child.hasActiveChildren
    );

    if (!nameMatches && !formattedChildren.length) return null;
    if (inactive && !activeChildren.length) return null;

    return {
      inactive,
      ...folderFields,
      children: formattedChildren,
      hasActiveChildren: !!activeChildren.length,
    };
  };

  const filteredTree = computed(() => {
    const q = normalizedQuery();

    const filtered = originalTree()
      .map((folder) => filterFolder(folder, q))
      .filter(Boolean);

    console.log({ originalTree: ko.toJS(originalTree), filtered: filtered })

    return filtered;
  });

  const flatFolder = (folder, level = 0) => {
    const { children, ...folderFields } = folder;

    let list = [];

    list.push({
      ...folderFields,
      level,
    });

    children.forEach((child) => {
      const childList = flatFolder(child, level + 1);
      list = [...list, ...childList];
    });

    return list;
  };

  const list = computed(() => {
    let result = [];

    filteredTree().forEach((folder) => {
      result = [...result, ...flatFolder(folder)];
    });

    return result;
  });

  const loadFolders = () => {
    if (typeof getFolders === "function") {
      getFolders().then((tree) => {
        originalTree(handleTree(tree));
        loading(false);
        loaded(true)
      });
    }
  };

  const getSubtree = (folderId) => {
    let result = [folderId];

    const folderChildren = list().filter(
      (folder) => folder.parent === folderId
    );
    folderChildren.forEach((child) => {
      result = [...result, child.id, ...getSubtree(child.id)];
    });

    return result;
  };

  loadFolders();

  return {
    loading,
    loaded,
    query,
    originalTree,
    filteredTree,
    list,
    update() {
      loadFolders();
    },
    
    getSubtree,
  };
}
