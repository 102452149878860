import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-table');

    this.content = ko.observable(null);
    this.scroll = ko.observable(null);
    this.top = ko.observable(null);
    this.bottom = ko.observable(null);
    this.originalTable = null;

    this.fixedHeader = params.fixedHeader;
    if (this.fixedHeader) element.classList.add('fc-table--sticky-header');

    this.loading = params.loading;

    this.observers = [];
    this.isHeaderFixed = ko.observable(false);

    ko.applyBindingsToNode(element, {
      css: {
        'fc-table--fixed': this.isHeaderFixed
      }
    });
  }

  onElementRender() {
    this.originalTable = this.content().querySelector('table');
    let top = this.top();
    let bottom = this.bottom();

    if (this.fixedHeader) {
      let tableHead = this.originalTable.querySelector('thead');


      let isTopFixed = ko.observable(false);
      let isBottomFixed = ko.observable(false);

      this.addSubscription(
        isTopFixed.subscribe((v) => {
          if (v && isBottomFixed()) this.isHeaderFixed(true);
          else this.isHeaderFixed(false);
        })
      );
      this.addSubscription(
        isBottomFixed.subscribe((v) => {
          if (v && isTopFixed()) this.isHeaderFixed(true);
          else this.isHeaderFixed(false);
        })
      );

      let topFixedObserver = new IntersectionObserver(([entry]) => {
        isTopFixed(entry.boundingClientRect.top < 0);
      });
      topFixedObserver.observe(top);

      this.onDispose(() => {
        topFixedObserver.unobserve(top);
      });

      let bottomFixedObserver = new IntersectionObserver(
        ([entry]) => {
          isBottomFixed(entry.boundingClientRect.top >= entry.rootBounds.top);
        },
        {
          rootMargin: -1 * tableHead.offsetHeight + 'px'
        }
      );
      bottomFixedObserver.observe(bottom);

      this.onDispose(() => {
        bottomFixedObserver.unobserve(bottom);
      });
    }

    let bottomObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.emitEvent('reachEnd');
        }
      },
      {
        threshold: [0]
      }
    );
    bottomObserver.observe(bottom);

    this.onDispose(() => {
      bottomObserver.unobserve(bottom);
    });
  }

  dispose() {
    if (this.topObserver) this.topObserver.unobserve(this.top());
    if (this.bottomObserver) this.bottomObserver.unobserve(this.bottom());
  }
}
