export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    let table = params.table;
    let name = params.name;

    this.sortName = params.sortName || name;
    this.searchName = params.searchName || name;

    this.withSearch = "withSearch" in params ? params.withSearch : true;

    this.placeholder = params.placeholder ? params.placeholder : "";

    if (table) {
      this.sort = table.sort;
      this.searchValue = table.search ? table.search[this.searchName] : null;
      this.onSearch = () => {
        table.reset();
      };
    } else {
      this.sort = params.sort;
      this.searchValue = params.searchValue;
      this.onSearch = params.onSearch;
    }
  }

  sortBy() {
    if (typeof this.sort.sortBy == "function") {
      this.sort.sortBy(this.sortName);
    }
  }

  search() {
    if (typeof this.onSearch == "function") {
      this.onSearch();
    }
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
