import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add("fc-number-range");

    this.fromInput = ko.observable(null);
    this.toInput = ko.observable(null);

    const { from, to, disabled, fromInvalid, toInvalid, fromValid, toValid } =
      params;

    this.from = from;
    this.to = to;
    this.fromInvalid = fromInvalid;
    this.toInvalid = toInvalid;
    this.fromValid = fromValid;
    this.toValid = toValid;

    

    this.disabled = ko.computed(() => {
      return !!ko.toJS(disabled);
    });

    ko.applyBindingsToNode(element, {
      css: {
        "fc-number-range--disabled": this.disabled,
      },
    });
  }

  focus() {
    this.fromInput().focus();
  }

  onElementRender() {}
}
