ko.bindingHandlers.log = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {

    let logName = valueAccessor();

    if (logName) {
      console.group(logName);
    }

    console.log('element', element);
    console.log('valueAccessor', valueAccessor);
    console.log('allBindings', allBindings);
    console.log('viewModel', viewModel);
    console.log('bindingContext', bindingContext);

    if (logName) {
      console.groupEnd(logName);
    }
  },
};
