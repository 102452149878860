import { DialogWrapper } from 'Dialogs/wrapper';


export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);

    this.loading = params.loading;
    this.poll = params.poll;
    this.error = params.error;
  }
}
