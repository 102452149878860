/**
 * Селект
 *
 * options: массив или функция
 * force: true|false - обновление списка при каждом открытии и поиске
 * value: массив для multiple или одно значение для single
 * selected: объект (объекты) значения, а не только id
 * multiple
 *
 * disabled
 * disabledLevel {Number} - блокировать уровни ниже указанного
 * disabledOptions {Array}
 * readMode
 * blockSelectedGroup: при выборе группы блокировать все ее вложенные опции
 * 
 * optionsForSearch: количество опций, при котором появляется поле поиска (для single)
 * searchPlaceholder
 * clearable: сброс значения (для single)
 * invalid
 * valid
 * inline: тип отображения
 * placeholder
 * dropdownClass
 * activeCategories
 *
 * слоты:
 *   - option
 *   - result
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-select', {
  model,
  template
});
