/**
 * Мета-информация, статистика
 *
 * fc-stats-container
 *
 * padding
 * adaptive
 *
 * fc-stats-item
 *
 * value
 * total
 * text
 * label
 * color 'primary', 'blue', 'gold', 'violet', 'mint'
 * adaptive: true|false
 * maxWidth
 * minWidth
 * separator
 * padding
 *
 * slots:
 * - text
 */

import { ViewModel as model } from './item/model';
import template from './item/template.html';
import './item/style.less';

import { ViewModel as containerModel } from './container/model';
import containerTemplate from './container/template.html';
import './container/style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-stats-item', { model, template });
registerComponent('fc-stats-container', { model: containerModel, template: containerTemplate });
