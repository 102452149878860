let unique = 1;
let cache = [];

ko.bindingHandlers.stickyBottom = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let params = valueAccessor() || {};

    let observerConfig = {
      threshold: [0.0, 1.0],
      root: /* params.preview ? document.documentElement :  */null,
    };


    let id = unique++;
    element.dataset.stickyBottom = id;

    cache.push(element);

    let observer = new IntersectionObserver(([entry]) => {
      if (params.preview && !entry?.rootBounds?.width) return;
      element.classList.toggle('stucky', entry.isIntersecting && entry.intersectionRatio < 1);     
    }, observerConfig);
    observer.observe(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      observer.unobserve(element);
      let index = cache.findIndex((e) => e === element);
      cache.splice(index, 1);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
