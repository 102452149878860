/**
 * Инпут с динамической шириной
 *
 * value
 * disabled
 * placeholder
 * clearable
 * appendIcon
 * mask
 */

import { binding } from './binding';
import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbAutoWidth', binding);
registerComponent('fc-auto-input', { model, template });
