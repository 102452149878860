let unique = 1;

export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.unique = 'switch-' + unique++;

    this.checked = params.checked;
    this.disabled = params.disabled;

    this.canChange = typeof params.onChange != 'function';
    this.onClick = () => {
      if (ko.toJS(this.disabled)) return false;
      if (this.canChange) return true;
      params.onChange(!this.checked());
      return false;
    };
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
