import { FoquzComponent } from 'Models/foquz-component';

const queries = {
  'mobile': 'screen and (max-width: 767px)',
  'tablet+': 'screen and (min-width: 768px)'
};

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.matches = ko.observable(false);

    const query = queries[params.query] || params.query;
    const mqList = window.matchMedia(query);

    this.check = (mql) => {
      let isMatches = mql.matches;
      element.style.display = isMatches ? '' : 'none';
      this.matches(isMatches);
    };

    this.check(mqList);
    mqList.addListener(this.check);

    this.mqList = mqList;
  }

  dispose() {
    this.mqList.removeListener(this.check);
  }
}
