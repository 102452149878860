import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);
    element.classList.add('fc-label');

    let labelStyle = ko.computed(() => {
      let style = ko.toJS(params.style);
      if (style) return 'fc-label--style_' + style;
    });

    ko.applyBindingsToNode(element, {
      css: labelStyle
    });

    this.text = params.text;
    if (!this.text) {
      element.classList.add('fc-label--ghost');
    }
    this.hint = params.hint;
    this.required = params.required;
    this.optional = params.optional;
    this.forId = params.forId;
  }
}
