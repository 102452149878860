import { ModalWrapper } from "../../models/modal-wrapper";

export class ViewModel extends ModalWrapper {
  constructor(params, element) {
    super(params, element);

    let data = params.data || {};

    this.title = data.title;
    this.text = data.text;
    this.closeText = data.close || _t('Закрыть');
  }
}
