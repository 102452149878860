import { ViewModel } from './model';
import './style';
import html from './template.html';

ko.components.register('poll-name-form', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('poll-name-form');
      return new ViewModel(params, element);
    }
  },
  template: html
});
