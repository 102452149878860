/**
 * Ошибка
 *
 * text
 * show
 */

import { ViewModel as model } from './model';

import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-error', {
  model,
  template: ' '
});
