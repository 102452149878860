let isMobile = null;

function init() {
  let screenWidth = ko.observable($(window).width());
  $(window).resize(() => {
    screenWidth($(window).width());
  });
  return ko.computed(() => {
    return screenWidth() <= 767;
  });
}

export function getMobileWatcher() {
  if (!isMobile) {
    isMobile = init();
  }
  return isMobile;
}
