import { FComponent } from '@/components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-radio-group');

    this.options = params.options;
    this.value = params.value;
    this.disabled = params.disabled;

    console.log('radio group', ko.toJS(params))
  }
}
