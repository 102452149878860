import html from './template.html';
import './style';
import { someToArray } from 'Utils/object/some-to-array';

ko.components.register('file-loader-error', {
  viewModel: function (params) {
    this.error = params.error;
    this.type = ko.pureComputed(() => {
      let error = ko.toJS(this.error);
      return error ? error.type : '';
    });
    this.text = ko.pureComputed(() => {
      let error = ko.toJS(this.error);
      console.log('loader error', error);
      if (!error) return '';
      return someToArray(error.text).join('<br>');
    });

  },
  template: html
});
