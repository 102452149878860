let unique = 1;

export function createComponent(template) {
  let id = 'fcc-' + unique++;
  let el = template.cloneNode(true);
  document.body.append(el);
  el.id = id;
  ko.components.register(id, {
    viewModel: function () {
      this.templateID = id;
    },
    template: `
      <!-- ko let: { templateId: templateID } -->
      <!-- ko using: $parent -->
      <!-- ko template: { name: templateId } -->
      <!-- /ko -->
      <!-- /ko -->
      <!-- /ko -->
    `
  });
  return id;
}
