import { FComponent } from 'Components/f-component';

const modes = ['points', 'gradient'];
const sizes = ['sm']
export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    element.classList.add('fc-spinner');
    this.size = params.size;

    if (this.size) element.classList.add('fc-spinner--' + this.size)

    this.mode = modes.includes(params.mode) ? params.mode : 'points';

    element.classList.add('fc-spinner--' + this.mode)
  }
}
