import './utils/engine/get-component';


import './utils';
import './settings';
import 'Presentation/bindings/';
import 'Presentation/components/';
import 'Presentation/views/';
import './bindings';
import './components';
import './modals';
import './dialogs';

import './less/fixes.less';

import {
  Draggable,
  Sortable
} from '@shopify/draggable/lib/es5/draggable.bundle.legacy';

window.Draggable = Draggable;
window.Draggable.Sortable = Sortable;

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

import 'Legacy/components/modals';

import 'Legacy/modals/confirm-modal/confirm-modal';

import { PageModel } from 'Legacy/utils/page-model';

window.PageModel = PageModel;

window.FormSectionModel = function (ctx) {
  ctx.isSubmitted = ko.observable(false);
  ctx.showSavedMessage = ko.observable(false);

  ctx.formControlErrorStateMatcher = commonFormControlErrorStateMatcher(
    ctx.isSubmitted
  );
  ctx.formControlSuccessStateMatcher = commonFormControlSuccessStateMatcher(
    ctx.isSubmitted
  );

  ctx.onSuccess = () => {
    ctx.showSavedMessage(true);
  };
};

import urlUtils from 'Legacy/utils/url';
import colorUtils from 'Legacy/utils/color';
import * as dateUtils from 'Legacy/utils/date';
import * as stringUtils from 'Legacy/utils/string';
import * as regexUtils from 'Legacy/utils/regex';
import { debounce } from 'Legacy/utils/debounce';
import { delay } from 'Legacy/utils/delay';
import { declOfNum } from 'Utils/string/decl-of-num';

window.Delay = delay;

window.utils = {
  delay: delay,
  string: stringUtils,
  date: dateUtils,
  url: urlUtils,
  color: colorUtils,
  regex: regexUtils,
  declOfNum: declOfNum,
  debounce
};

import 'Legacy/utils/layout-bindings';
import 'Legacy/utils/ui-bindings';

import 'Legacy/components/progress-bar.js';
import 'Legacy/components/number-control.js';

import 'Legacy/components/rating';
import 'Legacy/components/f-select';

import { conditionTypes } from 'Legacy/utils/conditions';
window.conditionTypes = conditionTypes;

let header = document.querySelector('.foquz-app-header');
if (header) ko.applyBindings({}, header);

let sidebar1 = document.querySelector('.app__sidebar--clone');
if (sidebar1) ko.applyBindings({}, sidebar1);

let sidebar2 = document.querySelector('.app .app__sidebar');
if (sidebar2) ko.applyBindings({}, sidebar2);

import 'Utils/quick-help';


// import './f-index';
