/**
 * Кнопка
 * label
 * size: sm(32, 13/500), md + lg(38, 14/500), xl (48), auto
 * color: primary, secondary, danger, success, white, black || любой цвет
 * shape: pill, circle, square
 * inverse: true|false (инверсия фона и цвета)
 * mode: text (inverse, без паддингов)
 * icon: имя иконки или объект с настройками для fc-icon
 * pending
 * disabled
 * click
 * block: true|false
 * linkMode
 * linkAttrs{target, href}
 */


import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from '@/utils/engine/register-component';

registerComponent('fc-button', { model, template });
