import { ApiUrl } from "@/utils/url/api-url";
export const currentUser = window.CURRENT_USER;

export const currentUserId = currentUser?.id;

export function getUserData(userId = currentUserId, params = {}) {
  return fetch(ApiUrl("user/view", { id: userId, ...params })).then(
    (response) => response.json()
  );
}
