import { minSameWidth } from '../modifiers/min-same-width';

export const buttonMode = {
  options: {
    placement: 'bottom-end',
  },
  flipOptions: {
    fallbackPlacements: ['bottom-end', 'top-end']
  },
  modifiers: [minSameWidth]
}
