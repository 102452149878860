export function getHack(hackName) {
  if (window.foquzHacks) return  window.foquzHacks[hackName];
  return undefined;
}

export function setHack(hackName, hackValue) {
  if (!window.foquzHacks) window.foquzHacks = {};
  window.foquzHacks[hackName] = hackValue;
}

window.setHack = setHack;
