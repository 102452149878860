import { ViewModel } from './model';
import html from './template.html';
import './style.less';

ko.components.register('interval-slider', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);

      if (element && element.classList) {
        element.classList.add('foquz-interval-slider');
      }

      return new ViewModel(params, element);
    }
  },
  template: html
});
