import Inputmask from 'inputmask/lib/inputmask';

ko.bindingHandlers.dateInput = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {

    let config = valueAccessor() || {};

    let placeholder = 'placeholder' in config ? config.placeholder : '00.00.0000';

    let im = new Inputmask({ regex: '99.99.9999', showMaskOnHover: true, }).mask(element);
    element.placeholder = placeholder;

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      Inputmask.remove(element);
    });


  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {

  }
}
