export class ViewModel {
  constructor({
    options,
    value,
    variantValue,
    disabled,
    placeholder,
    slot = false,
  }, element) {

    this.options = options;
    this.value = value;
    this.variantValue = variantValue;
    this.disabled = disabled;
    this.placeholder = placeholder;
    this.slot = slot;
  }
}
