export class FoquzModal {
  constructor(params, element) {
    this._$modal = $(element);

    this._$modal
      .addClass(['modal', 'fade', 'foquz-modal'])
      .attr('role', 'dialog')
      .attr('tabindex', -1);

    this._$modal.modal({
      backdrop: false,
      keyboard: false,
      focus: true,
      show: !params.hide
    });

    if (params.replace || params.static) {
      this._$modal.appendTo('body');
    }

    if (!params.hide) $('body').trigger('modal.open');

    this._$modal.on('show.bs.modal', () => {
      $('body').trigger('modal.open');
    });

    this._$modal.on('hide.bs.modal', () => {
      if (typeof params.onClose === 'function') params.onClose();
    });

    this.mask = params.mask;
  }

  get $modal() {
    return this._$modal;
  }

  open() {
    this.$modal.modal('show');
  }

  close() {
    this.$modal.modal('hide');
  }


  dispose() {
    this.$modal.modal('dispose');
    ko.cleanNode(this.$modal.children().first().get(0));
    this.$modal.remove();
  }
}
