import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-input');

    this.input = ko.observable(null);

    const {
      ref,
      value,
      maxlength,
      counter,
      clearable,
      invalid,
      valid,
      icon,
      appendIcon,
      disabled,
      readMode,
      mask,
      $raw,
      ...attrs
    } = params;

    this.value = value;
    this.maxLength = maxlength;
    this.counter = counter;

    this.icon = null;
    if (params.icon) {
      if (typeof params.icon === 'string')
        this.icon = { name: params.icon, size: 18, color: 'secondary' };
      else
        this.icon = {
          size: 18,
          color: 'secondary',
          ...params.icon
        };
    }

    this.appendIcon = null;
    if (params.appendIcon) {
      if (typeof params.appendIcon === 'string')
        this.appendIcon = {
          name: params.appendIcon,
          size: 24,
          color: 'light'
        };
      else
        this.appendIcon = {
          color: 'light',
          ...params.appendIcon,
          size: 24
        };
    }

    this.disabled = ko.computed(() => {
      return !!ko.toJS(disabled);
    });
    this.readMode = readMode;
    this.mask = mask;

    this.clearable = ko.pureComputed(() => {
      return !ko.toJS(this.disabled) && ko.toJS(clearable) && ko.toJS(value);
    });

    ko.applyBindingsToNode(element, {
      css: {
        'fc-input--invalid': invalid,
        'fc-input--valid': valid,
        'fc-input--disabled': this.disabled,
        'fc-input--counter': counter,
        'fc-input--clearable': this.clearable,
        'fc-input--append-icon': appendIcon
      }
    });

    this.inputAttrs = {
      maxlength: maxlength,
      disabled: this.disabled,
      ...attrs
    };

    this.count = ko.pureComputed(() => {
      return ko.toJS(this.maxLength) - (ko.toJS(this.value) || "").length;
    });
  }

  focus() {
    this.input().focus();
  }

  select() {
    this.input().select();
  }

  clear() {
    if (ko.isObservable(this.value)) {
      this.value('');
    } else {
      this.input().value = '';
    }
    this.emitEvent('clear');
  }

  onElementRender() {
    this.element.addEventListener('click', () => this.focus());
  }

  getInput() {
    return this.input();
  }
}
