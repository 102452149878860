export function dispatchEvent(element, eventName, eventData) {
  if (!element) return;
  if (!eventName) return;
  const event = new Event(eventName);
  if (eventData) event.detail = eventData;
  element.dispatchEvent(event);
}

export function elementDispatcher(element) {
  if (!element) return;
  return (eventName, eventData) => dispatchEvent(element, eventName, eventData);
}
