import { ViewModel } from './model';
import html from './template.html';
import './style.less';

ko.components.register('rating-point', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('rating-point');

      return new ViewModel(params, element);
    },
  },
  template: html,
});
