import { ViewModel } from './model';
import html from './template.html';
import textareaTemplate from './template-textarea.html';
import './style.less';

ko.components.register('copy-input', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);

      element.classList.add('copy-input');

      return new ViewModel(params, element);
    }
  },
  template: html
});

ko.components.register('copy-textarea', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);

      element.classList.add('copy-input');

      return new ViewModel(params, element);
    }
  },
  template: textareaTemplate
});
