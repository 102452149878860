import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    this.value = params.value;
    this.options = params.options;

    
  }
}
