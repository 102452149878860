import { CheckInputModel } from "../model";

let unique = 1;

export class ViewModel extends CheckInputModel {
  onLabelClick() {
    if (this.input().checked) return true;

    if (typeof this.onChange === 'function') this.onChange(this.value);
    else return true;
  }
}
