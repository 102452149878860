export function flattenList(items, childrenProp = 'items', options) {
  let result = [];
  const textProp = options.textProp || 'text';

  let flatItem = (item, level, parent) => {
    let _item = {
      ...item,
      level,
      parent: parent,
      text: item[textProp],
    };

    if (item.category) { // ToDo
      Object.assign(_item, item.category);
      _item.text = _item[textProp];
    }

    result.push(_item);

    if (childrenProp in item && Array.isArray(item[childrenProp])) {
      if (options.disableFoldersSelect && item.type === 'category') {
        _item.disabled = true;
      }
      if (childrenProp !== 'items') {
        _item.items = item[childrenProp];
      }
      _item.items.forEach((i) => flatItem(i, level + 1, item.id));
    }
  };

  items.forEach((o) => flatItem(o, 0));

  return result;
}
