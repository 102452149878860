ko.components.register('progress-bar', {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      const $element = $(componentInfo.element);

      $element.addClass(['progress-bar']);

      if ('theme' in params) {
        let themeClass;

        switch (params.theme) {
          case 'success':
            themeClass = 'progress-bar--theme_success';
            break;
        }

        $element.addClass(themeClass);
      }

      return new (function () {
        this.value = params.value;
      })();
    },
  },
  template: `
    <div class="progress-bar__indicator" data-bind="style: { width: ko.utils.unwrapObservable(value) + '%' }">
    </div>
    <span class="progress-bar__value" data-bind="if: ko.utils.unwrapObservable(value) >= 20">
        <!-- ko text: ko.utils.unwrapObservable(value).toFixed(1) -->
        <!-- /ko -->%
    </span>
  `,
});
