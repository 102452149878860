import { imageExtensions, videoExtensions } from 'Legacy/data/media-extensions';

import ee from 'event-emitter';

export class FileLoader {
  constructor(config) {
    ee(this);

    this.config = config;

    this.file = null;
    this.fileType = null;

    this.images = 'images' in config ? config.images : true;
    this.videos = config.videos;

    this.exts = [];
    if (this.images) {
      this.exts = [...this.exts, ...imageExtensions];
    }
    if (this.videos) {
      this.exts = [...this.exts, ...videoExtensions];
    }

    this.imageLimit = config.imageLimit || config.limit || 5 * 1024 * 1024;
    this.videoLimit = config.videoLimit || config.limit || 10 * 1024 * 1024;

    this._onInputChange = () => {
      $(this.input).off('change', this._onInputChange)
      this.onChange();
    }

    this.input = null;
  }

  createInput() {
    let input = document.createElement('input');

    input.type = 'file';
    input.accept = this.exts.join(',');

    input.style.position = 'absolute';
    input.style.visibility = 'hidden';
    input.style.opacity = 0;
    input.style.left = '-9999px';

    $(input).on('change', this._onInputChange);

    document.body.appendChild(input);

    return input;
  }

  open() {
    this.input = this.createInput();
    $(this.input).trigger('click');
  }

  checkFileSize(file, type) {

    if (type == FileLoader.IMAGE_TYPE) {
      if (this.imageLimit && file.size > this.imageLimit) return false;
      else return true;
    } else if (type == FileLoader.VIDEO_TYPE) {
      if (this.videoLimit && file.size > this.videoLimit) return false;
      else return true;
    }

  }

  getFileType(file) {
    if (!file) return;
    let ext = ('.' + file.name.split('.').reverse()[0]).toUpperCase();
    if (imageExtensions.includes(ext)) return FileLoader.IMAGE_TYPE;
    if (videoExtensions.includes(ext)) return FileLoader.VIDEO_TYPE;
  }

  onChange() {
    this.file = this.input.files[0];
    this.fileType = this.getFileType(this.file);

    this.emit(FileLoader.SELECT);

    if (!this.file) {
      this.emit(FileLoader.EMPTY);
      return;
    }

    if (this.checkFileSize(this.file, this.fileType)) {
      this.emit(FileLoader.LOAD);
    } else {
      this.emit(FileLoader.LIMIT_ERROR);
    }
  }
}

FileLoader.IMAGE_TYPE = 'file.loader.type.image';
FileLoader.VIDEO_TYPE = 'file.loader.type.video';

FileLoader.LIMIT_ERROR = 'file.loader.errors.limit';
FileLoader.LOAD = 'file.loader.events.load';
FileLoader.SELECT = 'file.loader.events.select';
FileLoader.EMPTY = 'file.loader.events.empty';
