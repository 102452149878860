import { Translator } from '@/utils/translate'

export class ViewModel {
  constructor(params, element) {
    this.element = element;

    this.translator = Translator('main')

    this.value = params.value;
    this.withoutIcon = params.withoutIcon;
    this.allowClear = 'allowClear' in params ? params.allowClear : true;
    this.placeholder = 'placeholder' in params ? params.placeholder : this.translator.t('Поиск по названию');
    this.onEnter = () => {
      if (typeof params.onEnter == 'function') {
        params.onEnter();
      }
    }
  }

  clear() {
    this.value('');
    this.onEnter();
  }
}
