// ko-компоненты по умолчанию
import './modal';

import './observer-target';
import './table';
import './name-edit-form';

import './input';
import './ui';
import './table';
import './meta-row';
import './media-query';
import './attachments';
import './publish-poll';
import './poll-info-button';
import './rating';
import './lang-toggler';
