import Scrollbar from 'smooth-scrollbar';
import './style.less';

ko.bindingHandlers.fScrollbar = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    var settings = valueAccessor() || {};
    var listen = settings.gradient || settings.listen;

    if (settings.disabled) return;

    let wrapper = document.createElement('div');
    wrapper.classList.add('ps-wrapper');
    $(element).wrap(wrapper);

    wrapper = element.parentElement;

    ko.applyBindingsToDescendants(bindingContext, element);

    if (listen) {
      [
        {
          className: 'ps-y-reach-start',
          setEvent: 'ps-y-reach-start',
          resetEvent: 'ps-scroll-down'
        },
        {
          className: 'ps-y-reach-end',
          setEvent: 'ps-y-reach-end',
          resetEvent: 'ps-scroll-up'
        },
        {
          className: 'ps-x-reach-start',
          setEvent: 'ps-x-reach-start',
          resetEvent: 'ps-scroll-right'
        },
        {
          className: 'ps-x-reach-end',
          setEvent: 'ps-x-reach-end',
          resetEvent: 'ps-scroll-left'
        }
      ].forEach(function (setting) {
        element.addEventListener(setting.setEvent, function () {
          element.classList.add(setting.className);
        });
        element.addEventListener(setting.resetEvent, function () {
          element.classList.remove(setting.className);
        });
      });
    }

    if (settings.gradient) {
      if (!settings.onlyY) {
        var xGradient = document.createElement('div');
        xGradient.classList.add('ps-x-gradient');
        if ('gradientStart' in settings && !settings.gradientStart) {
          xGradient.classList.add('ps-x-gradient--disable-start');
        }
        if ('gradientEnd' in settings && !settings.gradientEnd) {
          xGradient.classList.add('ps-x-gradient--disable-start');
        }
        wrapper.appendChild(xGradient);
      }

      if (!settings.onlyX) {
        var yGradient = document.createElement('div');
        yGradient.classList.add('ps-y-gradient');
        wrapper.appendChild(yGradient);
      }
    }

    var params = {
      handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    };

    if (settings.onlyY) {
      params.suppressScrollX = true;
    }

    if (settings.onlyX) {
      params.suppressScrollY = true;
    }

    var ps = new PerfectScrollbar(element, params);
    const update = () => {
      ps.update();
    };

    element.ps = ps;
    element.updateScroll = function () {
      update();
    };

    update();

    $(window).resize(function () {
      update();
    });

    $(element).on('resize', function () {
      update();
    });

    const config = {
      childList: true,
      subtree: true
    };
    const callback = function (mutationsList, observer) {
      update();
    };
    const observer = new MutationObserver(callback);
    observer.observe(element, config);

    return { controlsDescendantBindings: true };
  }
};

ko.bindingHandlers.fScrollbar2 = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    var settings = valueAccessor() || {};
    var listen = settings.gradient || settings.listen;

    if (settings.disabled) return;

    let wrapper = document.createElement('div');
    wrapper.classList.add('ps-wrapper');
    $(element).wrap(wrapper);

    wrapper = element.parentElement;

    ko.applyBindingsToDescendants(bindingContext, element);

    if (listen) {
      [
        {
          className: 'ps-y-reach-start',
          setEvent: 'ps-y-reach-start',
          resetEvent: 'ps-scroll-down'
        },
        {
          className: 'ps-y-reach-end',
          setEvent: 'ps-y-reach-end',
          resetEvent: 'ps-scroll-up'
        },
        {
          className: 'ps-x-reach-start',
          setEvent: 'ps-x-reach-start',
          resetEvent: 'ps-scroll-right'
        },
        {
          className: 'ps-x-reach-end',
          setEvent: 'ps-x-reach-end',
          resetEvent: 'ps-scroll-left'
        }
      ].forEach(function (setting) {
        element.addEventListener(setting.setEvent, function () {
          element.classList.add(setting.className);
        });
        element.addEventListener(setting.resetEvent, function () {
          element.classList.remove(setting.className);
        });
      });
    }

    if (settings.gradient) {
      if (!settings.onlyY) {
        var xGradient = document.createElement('div');
        xGradient.classList.add('ps-x-gradient');
        element.classList.add('ps--active-x');
        if ('gradientStart' in settings && !settings.gradientStart) {
          xGradient.classList.add('ps-x-gradient--disable-start');
        }
        if ('gradientEnd' in settings && !settings.gradientEnd) {
          xGradient.classList.add('ps-x-gradient--disable-start');
        }
        wrapper.appendChild(xGradient);
      }

      if (!settings.onlyX) {
        var yGradient = document.createElement('div');
        yGradient.classList.add('ps-y-gradient');
        element.classList.add('ps--active-y');
        wrapper.appendChild(yGradient);
      }
    }

    var params = {
      handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    };

    if (settings.onlyY) {
      params.suppressScrollX = true;
    }

    if (settings.onlyX) {
      params.suppressScrollY = true;
    }

    element.classList.add('ps');

    const ps = Scrollbar.init(element, {});
    ps.addListener((status) => {
      element.classList.toggle(
        'ps-y-reach-end',
        status.offset.y == status.limit.y
      );
      element.classList.toggle('ps-y-reach-start', status.offset.y == 0);
    });

    const update = () => {
      ps.update();
    };

    element.ps = ps;
    element.updateScroll = function () {
      update();
    };

    return { controlsDescendantBindings: true };
  }
};

// TODO
// определиться со скроллбаром
