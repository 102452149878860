/**
 * Список (опции для селектов/модалок)
 *
 * options[]
 *   - text
 *   - id
 *   - level
 *   - disabled
 *   - parentDisabled
 *   - category
 *   - selected
 *   - parentSelected
 * activeCategories
 * disableGroup - если элемент заблокирован, блокировать его дочерние элементы
 * disabledLevel - заблокировать уровни ниже указанного
 * value[] - id выбранных опций
 * remove: true|false - разрешен клик по выбранной опции для ее удаления
 * view: checkbox
 *
 * слоты:
 *   - item - шаблон вывода опции
 *   - empty - шаблон пустого списка
 *
 * события:
 *   - select
 *   - remove
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-list', {
  model,
  template
});
