import { FComponent } from 'Components/f-component';

const sizes = ['sm', 'lg'];

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-media-wrapper');

    let { remove, removeOnHover, loading, label, ...mediaParams } = params;

    this.width = null;
    this.height = null;

    if ('width' in mediaParams || 'height' in mediaParams) {
      this.width = mediaParams.width + 'px';
      this.height = mediaParams.height + 'px';
    } else if ('size' in mediaParams) {
      if (sizes.includes(mediaParams.size)) {
        element.classList.add('fc-media-wrapper--' + mediaParams.size);
      } else {
        this.width = mediaParams.size + 'px';
        this.height = mediaParams.size + 'px';
      }
    } else {
      element.classList.add('fc-media-wrapper--sm');
    }

    this.remove = remove;
    this.removeOnHover = 'removeOnHover' in params ? params.removeOnHover : true;

    if (this.removeOnHover) {
      element.classList.add('fc-media-wrapper--remove-on-hover')
    }

    this.loading = loading;
    this.mediaParams = mediaParams;
    this.label = label;
  }

}
