/**
 * type: checkbox|radio
 * label
 * hint
 * checked
 * value
 * disabled
 * onChange
 * size: 'sm'
 *
 * ...attrs - любые атрибуты для input
 *
 * slots:
 *   - label
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-check', { model, template });
