
import { currentUser, getUserData } from ".";

export const isEditor = currentUser?.editor;

export function getEditorFolders(userId) {
  return getUserData(userId, { expand: "editorFolders" }).then((data) => {
    return data.editorFolders;
  });
}

export const IS_EDITOR_WITHOUT_FOLDERS = ko.observable(false);
export const CHECKING_EDITOR_FOLDERS = ko.observable(isEditor)
if (isEditor) {
  getEditorFolders().then((list) => {
    if (!list.length) IS_EDITOR_WITHOUT_FOLDERS(true);
    CHECKING_EDITOR_FOLDERS(false)
  });
}
