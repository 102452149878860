const { toJS } = ko;

export function ViewModel(params, element) {
  element.classList.add("fc-folders-tree");

  const { list, current, disabled, value } = params;

  return {
    list,

    value,
    current,

    isDisabled(id) {
      const list = toJS(disabled);
      if (Array.isArray(list)) {
        return list.includes(id);
      }
    },
  };
}
