/**
 * Спиннер
 *
 * size: sm, md
 * mode: points, gradient
 */


import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-spinner', {
  model,
  template
});
