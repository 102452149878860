import { dragDrop } from './drag-drop';

ko.bindingHandlers.dnd = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    const $element = $(element);
    $element.addClass('foquz-dnd');

    const onDrop = valueAccessor();
    let isDisabled = !!ko.toJS(allBindings.get('dndDisabled'));

    $element.toggleClass('disable', isDisabled);
    element.isDndDisabled = isDisabled;

    const dnd = dragDrop(element, {
      onDrop: (files) => {
        if (element.isDndDisabled) return;
        $element.trigger('drop', files);
        if (typeof onDrop == 'function') onDrop(files);
      },
      onDragEnter: () => {
        if (element.isDndDisabled) return;
        $element.trigger('dnd.dragenter');
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      dnd();
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let isDisabled = !!ko.toJS(allBindings.get('dndDisabled'));
    $(element).toggleClass('disable', !!isDisabled);
    element.isDndDisabled = isDisabled;
  }
};
