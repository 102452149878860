export class ViewModel {
  constructor(params) {
    this.modal = params.modal;
  }

  close() {
    if (this.modal) {
      let modal = this.modal;
      if (ko.isObservable(this.modal)) {
        modal = this.modal();
      }
      if (typeof modal.close == 'function') modal.close();
    }

  }
}
