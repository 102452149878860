export function popperWidthModifier(width) {
  return {
    name: "popperWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      if (width === "equal")
        state.styles.popper.width = `${state.rects.reference.width}px`;
      else if (width === "double")
        state.styles.popper.width = `${state.rects.reference.width * 2}px`;
      else {
        state.styles.popper.width = `${width}px`;
      }
    },
    effect: ({ state }) => {
      if (width === "equal")
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
      else state.elements.popper.style.width = `${width}px`;
    },
  };
}
