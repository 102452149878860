import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add("fc-name-form");

    this.name = params.name;
    this.tmpName = ko.observable(ko.toJS(this.name));
    this.editTooltip = params.tooltip || "Редактировать название";

    this.disabled = params.disabled;

    this.isEditing = ko.observable(false);
  }

  save() {
    let name = this.tmpName().trim();
    if (!name) {
      this.cancel();
      return;
    }

    this.name(name);
    this.emitEvent("name.change", name);
    this.cancel();
  }

  cancel() {
    this.isEditing(false);
    this.tmpName(ko.toJS(this.name));
  }
}
