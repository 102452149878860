import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    const $content = $('.b-quick-help');

    const enabled = $('.b-quick-help').length > 0;

    this.texts = {
      polls: (() => {
        let text = _t(
          'main',
          `В разделе {section} можно просмотреть все опросы вашей компании. Для удобства просмотра они разделены на категории:`,
          { section: `<strong class='strong'>«${_t('Опросы')}»</strong>` }
        );
        return `<p>${text}</p>
        <ul>
          <li>${_t('опросы с достигнутыми целями')};</li>
          <li>${_t('последние опросы')};</li>
          <li>${_t('папки')};</li>
          <li>${_t('все опросы')};</li>
          <li>${_t('архив')};</li>
        </ul>`;
      })(),
      answers: (() => {
        let text = _t(
          'main',
          `В разделе {section} можно просмотреть все анкеты по всем опросам вашей компании. Для удобства просмотра добавлены различные фильтры, группировка по опросам, отображение анкет списком и канбан-доской. В разделе также есть возможность обработать анкеты с плохими отзывами.`,
          { section: `<strong class='strong'>«${_t('Ответы')}»</strong>` }
        );
        return `<p>${text}</p>`;
      })(),
      reports: (() => {
        let text = _t(
          'main',
          `В разделе {section} показана динамика полученных ответов для опросов.`,
          { section: `<strong class='strong'>«${_t('Отчёты')}»</strong>` }
        );
        return `<p>${text}
        <br>
        ${_t(
          'main',
          'Отчёты строятся по оценкам и рейтингам в разрезах филиалов, времени, выборке источников (опросов, отзывов и форм обратной связи).'
        )}
        <br>
        ${_t(
          'main',
          'Каждый настроенный отчёт можно сохранить в этом разделе, а также отправить по электронной почте или распечатать.'
        )}</p>`;
      })(),
      clients: (() => {
        let text = _t(
          'main',
          `В разделе {section} отображается вся база контактов вашей компании. Добавлена возможность импортировать и экспортировать базу контактов в формате CSV.`,
          { section: `<strong class='strong'>«${_t('Контакты')}»</strong>` }
        );
        return `<p>${text}</p>`;
      })(),
      points: (() => {
        let text = _t(
          'main',
          `В разделе {section} можно создавать шаблоны вопросов. С помощью сохранённых точек контакта можно быстро создавать опросы.`,
          {
            section: `<strong class='strong'>«${_t('Точки контакта')}»</strong>`
          }
        );
        return `<p>${text}</p>`;
      })(),
      settings: (() => {
        let text = _t('main', `В разделе {section} можно:`, {
          section: `<strong class='strong'>«${_t('Настройки')}»</strong>`
        });
        return `<p>${text}</p>
          <ul>
          <li>
          ${_t('Просмотреть список всех пользователей и добавить новых.')}
        </li>
        <li>${_t(
          'Подключить каналы связи: Email, SMS, Telegram, Viber, Push-уведомления.'
        )}</li>
        <li>
          ${_t(
            'Подключить сторонние сервисы для интеграции. Если не нашли свой сервис, обратитесь в службу поддержки.'
          )}
        </li>
        <li>${_t(
          'Настроить сценарии уведомлений для быстрой обработки анкет с плохими отзывами.'
        )}</li>
          </ul>`;
      })(),
      mailings: (() => {
        let text = _t(
          'main',
          `В разделе {section} можно просмотреть все рассылки вашей компании. Для удобства просмотра они разделены на категории:`,
          { section: `<strong class='strong'>«${_t('Рассылки')}»</strong>` }
        );
        return `<p>${text}</p>
        <ul>
          <li>${_t('избранные рассылки')};</li>
          <li>${_t('рассылки с достигнутыми целями')};</li>
          <li>${_t('последние рассылки')};</li>
          <li>${_t('папки')};</li>
          <li>${_t('все рассылки')};</li>
          <li>${_t('архив')};</li>
        </ul>`;
      })(),
      payment: (() => {
        let text = _t(
          'main',
          `В разделе {section} можно внести реквизиты вашей компании, а также распечатать и сохранить счета за все расчётные периоды по платным тарифам.`,
          { section: `<strong class='strong'>«${_t('Оплата')}»</strong>` }
        );
        return `<p>${text}</p>`;
      })(),
      help: (() => {
        let text = _t(
          'main',
          `В разделе {section} вы найдете информацию по любому функционалу системы опросов Foquz.`,
          { section: `<strong class='strong'>«${_t('Помощь')}»</strong>` }
        );
        return `<p>${text}</p>
          <p>${_t(
            'Для удобства использования статьи сгруппированы по темам, а также предусмотрен общий поиск по всем разделам помощи.'
          )}</p>
          <p>${_t(
            'Если нужная вам информация не нашлась, задайте вопрос в чате оператору.'
          )}</p>`;
      })(),
      create: (() => {
        let text = _t(
          'main',
          `Для создания нового опроса нажмите кнопку {button} и выберите тип опроса: ручной или автоматический.`,
          { button: `<strong class='strong'>«${_t('Создать опрос')}»</strong>` }
        );
        return `<p>${text}</p>
        <p>${_t(
          'Для ручных опросов можно сформировать рассылку по базе клиентов.'
        )}</p>

        <p>${_t(
          'Автоматический опрос будет отправляться клиенту, если выполнен определённый триггер.'
        )}</p>`;
      })(),
      widget: `<p>${_t(
        'Виджет — специальная кнопка для размещения на сайте. Виджет вызывает всплывающее окно с анкетой опроса. В разделе <a href="https://foquz.ru/foquz/user-wiki/razdel-vidzhet" target="_blank">Помощь</a> можно получить подробную информацию.'
      )}</p>`,
      requests: _t(
        'main',
        'Раздел {section} — это отдельный сервис, в котором можно создавать свои проекты, настраивать проекты исходя из потребностей компании. А также внутри проекта можно обрабатывать заявки, которые к нему относятся.',
        {
          section: `<strong class='strong'>«${_t('Обработка заявок')}»</strong>`
        }
      )
    };

    const defaultOrder = [
      {
        elem: '.sidebar__item-link--surveys',
        id: 'polls'
      },
      {
        elem: '.sidebar__item-link--mailings',
        id: 'mailings'
      },
      {
        elem: '.sidebar__item-link--answers',
        id: 'answers'
      },
      {
        elem: '.sidebar__item-link--reports',
        id: 'reports'
      },
      {
        elem: '.sidebar__item-link--clients',
        id: 'clients'
      },
      {
        elem: '.sidebar__item-link--contact-points',
        id: 'points'
      },
      {
        elem: '.sidebar__item-link--settings',
        id: 'settings'
      },
      {
        elem: '.sidebar__item-link--widget',
        id: 'widget'
      },
      {
        elem: '.sidebar__item-link--requests',
        id: 'requests'
      },
      {
        elem: '.sidebar__item-link--payment',
        id: 'payment'
      },
      {
        elem: '.sidebar__item-link--help',
        id: 'help'
      },
      {
        elem: '.sidebar__create-survays',
        id: 'create'
      }
    ];

    this.activeStep = ko.observable(0);

    this.isVisible = ko.observable(false);

    this.steps = defaultOrder.filter((step) => {
      let el = document.querySelector(step.elem);
      return !!el;
    });


    this.activeStepId = ko.pureComputed(() => {
      const step = this.steps[this.activeStep()];
      if (!step) return null;
      return this.steps[this.activeStep()].id;
    });

    this.isLastStep = ko.pureComputed(() => {
      return this.activeStep() === this.steps.length - 1;
    });

    this.changeMenuPosition = () => {
      $('html, body').scrollTop(0);
      $('html, body').scrollLeft(0);
      let activeNavItem = document.querySelector(
        this.steps[this.activeStep()].elem
      );
      if (!activeNavItem) return;

      let $activeNavItem = $(activeNavItem);

      const qhBody = $('.b-quick-help__body');
      const qhArrow = $('.b-quick-help__arrow');

      if (!qhBody.length) return;

      qhBody.css('margin-top', '');
      qhArrow.css('margin-top', '');

      $('.js-i-preview').remove();

      const left =
        $activeNavItem.offset().left +
        $activeNavItem.width() +
        ($activeNavItem.closest('.app__sidebar--small').length ? 15 : 35);
      const top =
        $activeNavItem.offset().top -
        18 +
        (activeNavItem.classList.contains('sidebar__create-survays') ? 10 : 0);

      qhBody.css({
        left: left,
        top: top
      });

      $activeNavItem
        .clone()
        .addClass(
          $activeNavItem.closest('.app__sidebar--small').length
            ? 'js-i-preview small'
            : 'js-i-preview'
        )
        .css({
          width: $activeNavItem.width(),
          left: $activeNavItem.offset().left,
          top: $activeNavItem.offset().top - 40,
          'font-size': $activeNavItem.closest('.app__sidebar--small').length
            ? '0'
            : activeNavItem.style.fontSize
        })
        .prependTo($(document.body));

      setTimeout(() => {
        $('.js-i-preview').css('top', $activeNavItem.offset().top);

        const height = qhBody.outerHeight();

        const viewportHeight = $(window).height();
        const offset = viewportHeight - (top + height);

        if (offset < 20) {
          qhBody.css('margin-top', -1 * (20 - offset));
          qhArrow.css('margin-top', 20 - offset);
        }
      }, 20);
    };

    this.delayedAnimation = function ($element, isHide = false) {
      if (!$element) return;
      if (!isHide) {
        $element.style.display = 'block';
        setTimeout(() => {
          requestAnimationFrame(() => {
            $element.classList.add('b-quick-help_visible');
          });
        }, 50);
      } else {
        requestAnimationFrame(() => {
          $element.classList.remove('b-quick-help_visible');
        });
        setTimeout(() => {
          $element.style.display = 'none';
        }, 450);
      }
    };

    this.onShow = () => {
      console.log('show');
      document.body.style.paddingRight =
        window.innerWidth - document.documentElement.clientWidth + 'px';
      document.body.style.overflow = 'hidden';
      this.activeStep(0);
      document.addEventListener('keydown', (event) => {
        event = event || window.event;
        var isEscape = false;
        if ('key' in event) {
          isEscape = event.key === 'Escape' || event.key === 'Esc';
        } else {
          isEscape = event.keyCode === 27;
        }
        if (isEscape) {
          this.onHide();
        }
      });
      this.delayedAnimation($content.get(0), !this.isVisible(true));
      setTimeout(() => {
        this.changeMenuPosition();
      }, 100);
    };

    this.onHide = () => {
      $('.js-i-preview').fadeOut(150, function () {
        $(this).remove();
      });
      this.isVisible(false);
      document.body.style.paddingRight = 0;
      document.body.style.overflow = 'auto';
      this.isVisible(false);
      this.delayedAnimation($content.get(0), true);
      // localStorage.setItem('quickHelpShowed', true);
      $.post('/foquz/ajax/quick-help-showed', {
        showed: true
      });
    };

    this.changeStep = (index) => {
      console.log('change step', index);
      this.activeStep(index);
      this.changeMenuPosition();
    };

    this.nextStep = () => {
      let index = this.activeStep() + 1;
      console.log('next step', index);
      this.changeStep(index);
    };

    $('.b-quick-help').on('click', (event) => {
      if (!$(event.target).closest('.b-quick-help__body').length) {
        this.onHide();
      }
    });

    $(document.body).on('click', '.js-get-quick-help', (event) => {
      event.preventDefault();
      this.onShow();
    });

    if (
      enabled &&
      window.location.href.indexOf('foquz') !== -1 &&
      window.location.href.indexOf('foquz/reports/view') === -1 &&
      $('.b-landing').length === 0
    ) {
      fetch('/foquz/ajax/quick-help')
        .then((response) => response.json())
        .then((data) => {
          if (!data.data.showed) {
            let mq = window.matchMedia('(max-width: 767px)');
            if (!mq.matches) {
              this.onShow();
            }
          }
        });
    }
  }
}
