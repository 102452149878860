ko.components.register('header-name-form', {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      const viewModel = new (function () {
        this.value = ko.observable(ko.utils.unwrapObservable(params.value));

        if ('onChange' in params) {
          this.value.subscribe((v) => params.onChange(v));
        }

        this.cancel = function () {
          if ('cancel' in params) {
            params.cancel();
          }
        };

        this.save = function () {
          const value = this.value().trim();

          if (value.length > 0) {
            if ('save' in params) {
              params.save(value);
            }
          } else {
            if ('cancel' in params) {
              params.cancel();
            }
          }
        };
      })();

      viewModel.init = function () {
        const $element = $(componentInfo.element);
        const $control = $element.find('.header-name-form__control');
        const $hiddenControl = $element.find(
          '.header-name-form__hidden-control',
        );

        $element.addClass('header-name-form');

        function updateControlWidth() {
          $hiddenControl.show();
          $control.width($hiddenControl.outerWidth());
          $hiddenControl.hide();
        }

        $control.on('input', () => updateControlWidth());

        updateControlWidth();
      };

      return viewModel;
    },
  },
  template: `
        <!-- ko template: { afterRender: init } -->
            <input class="header-name-form__control" data-bind="textInput: value, onEnter: function () { save(); }">

            <button class="btn btn-icon btn-danger header-name-form__cancel-button" type="button" title="Отменить"
                data-bind="click: function () { cancel(); }">
            </button>

            <button class="btn btn-icon btn-success header-name-form__save-button" type="button" title="Сохранить"
                data-bind="click: function () { save(); }">
            </button>

            <span class="header-name-form__hidden-control" data-bind="text: value"></span>
        <!-- /ko -->
    `,
});
