import { TABLET_MAX } from '../media/points';
import { mediaWatcher } from '../media/watcher';

$(document).ready(() => {
  let $sidebar = $('.app .app__sidebar');

  if (!$sidebar.length) return;

  var isTablet = function isTablet() {
    var windowWidth = window.innerWidth;
    return windowWidth <= TABLET_MAX;
  };

  let getSidebarState = () => {
    let state = localStorage.getItem('isSidebarOpen');
    return !state || state == '1';
  };

  let saveSidebarState = (state) => {
    localStorage.setItem('isSidebarOpen', state ? '1' : '0');
  };

  let isSidebarOpen = getSidebarState();

  if (isTablet()) {
    isSidebarOpen = false;
  }

  function setSidebarState(isOpen) {
    if (isOpen) {
      $('.btn-menu').removeClass('btn-menu--open').addClass('btn-menu--close');
      $sidebar.removeClass('app__sidebar--small');
      $('body').addClass('sidebar-open');
      $('.sidebar-backdrop').show();
    } else {
      $('.btn-menu').addClass('btn-menu--open').removeClass('btn-menu--close');
      $sidebar.addClass('app__sidebar--small');
      $('body').removeClass('sidebar-open');
      $('.sidebar-backdrop').hide();
    }
    saveSidebarState(isOpen)
  }

  $sidebar.addClass('notransition');
  setSidebarState(isSidebarOpen);
  setTimeout(function () {
    $sidebar.removeClass('notransition');
  }, 600);

  // раскрываем левое меню
  $('.btn-menu').on('click', function () {

    var isSidebarOpen = getSidebarState();
    setSidebarState(!isSidebarOpen);
  });

  //закрываем левое меню при клике на заглушку
  $('.sidebar-backdrop').on('click', function () {
    setSidebarState(false);
  });

  mediaWatcher((state) => {
    if (state !== 'desktop') {
      setSidebarState(false);
    }
  });
});
