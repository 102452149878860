import { FoquzComponent } from 'Models/foquz-component';


export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.isVideo = params.isVideo;
    this.poster = params.poster;
    this.gallery = params.gallery;
    this.index = params.index;
    this.description = params.description;

  }
}
