import { NPSGradient } from "@/utils/questions/nps";
export function ViewModel(props, element) {
    var design = props.design, startColor = props.startColor, endColor = props.endColor, value = props.value;
    element.classList.add("fc-nps-item");
    element.setAttribute("data-nps-design", design.toString());
    var gradient = NPSGradient(startColor, endColor);
    var point = Math.round(value);
    element.style.setProperty("--nps-color", gradient[point]);
    return {
        value: value,
    };
}
