import { Translator } from '@/utils/translate';

const MainTranslator = Translator('main');
export class ViewModel {
  constructor(params, element) {
    this.show = params.show;
    const delay = params.delay || 1300;
    this.text = params.text || MainTranslator.t('Сохранено успешно');
    this.showCross = params.showCross;

    this.show.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          this.show(false);
        }, delay);
      }
    });
  }
}
