export class ViewModel {
  constructor(params, element) {
    this.mode = params.mode || 'single';
    this.type = params.type;

    element.classList.add('foquz-dnd-cover--' + this.type);

    this.text = this.mode == 'multiple' ? 'Перетащите файлы сюда, чтобы загрузить их' : 'Перетащите файл сюда, чтобы загрузить его';

    if ('dense' in params) {
      if (!ko.isObservable(params.dense)) {
        if (params.dense) element.classList.add('dense');
      } else {
        if (params.dense()) element.classList.add('dense');
        params.dense.subscribe(v => {
          if (v) element.classList.add('dense');
          else element.classList.remove('dense');
        })
      }

    }
  }
}
