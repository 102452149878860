import { ViewModel } from './model';
import checkHtml from './checkbox/template.html';
import radioHtml from './radio/template.html';
import './style.less';

ko.components.register('foquz-checkbox', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('foquz-checkbox');

      return new ViewModel(params, element);
    }
  },
  template: checkHtml
});

ko.components.register('foquz-radio', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('foquz-radio');

      return new ViewModel(params, element);
    }
  },
  template: radioHtml
});
