import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.userpic = params.userpic;
    this.name = params.name;
    this.link = params.link;
  }
}
