let unique = 1;
export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.id = 'radio-group-' + unique++;

    this.options = params.options;
    this.value = params.value;
    this.disabled = params.disabled;
    this.withoutIcon = params.withoutIcon;
  }

  get optionUnique() {
    return 'radio-group-option-' + unique++;
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
