/**
 * Контейнер с прокруткой
 *
 * maxHeight
 * maxWidth
 * indicator: true|false
 */

import { binding } from './binding';
import { binding as syncBinding } from './sync';
import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbScroll', binding);
registerBinding('fbSyncScroll', syncBinding);
registerComponent('fc-scroll', { model, template });
