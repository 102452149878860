import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    this.id = params.id;

    element.classList.add("fc-select-test");

    this.select = ko.observable(null);

    this.multiple = params.multiple;
    this.invalid = params.invalid;
    this.value = params.value;
    this.selectConfig = {
      ...params.selectConfig,
      dropdownWrapperCssClass: "select2--test-select select2--" + this.id,
    };
    this.options = params.options;
  }

  onElementRender() {
    let select = this.select();
    let select2 = $(select).data("select2");

    // let btn = $('<button>hello</button>')
    // select2.$dropdown.append(btn)
  }
}
