import ee from 'event-emitter';

import { dateValidator } from '@/utils/validation/date';
import { dateToString } from '@/utils/date/date-to-string';
import { dateFromString } from '@/utils/date/date-from-string';
import { today } from '@/utils/date/today';

/**
 * Часть периода (from или to)
 * События:
 * - date.change
 * - value.change
 */
export class PeriodSection {
  constructor() {
    ee(this);

    // refs
    this.calendar = ko.observable(null);
    this.field = ko.observable(null);

    // отображаемый месяц
    this.date = ko.observable(today);
    this.date.subscribe((v) => this.emit('date.change', v));

    let validator = dateValidator({ format: 'DD.MM.YYYY' });

    // текущее незафиксированное значение (в поле ввода)
    this.currentValue = ko.observable('').extend({
      validation: {
        validator: (v) => {
          if (!v) return true;
          v = v.replace(/_/g, '');
          return validator(v);
        },
        message: 'Некорректный формат'
      }
    });
    // зафиксированное валидное значение
    this.correctValue = ko.observable(null);

    this.currentValue.subscribe((newValue) => {
      if (newValue === this.correctValue()) return;
      if (this.currentValue.isValid()) {
        this.correctValue(newValue);
      }
      this.emit('temp.value.change')
    });

    this.correctValue.subscribe((newValue) => {
      this.emit('value.change', newValue);
    });
  }

  get value() {
    return this.correctValue();
  }

  /**
   * Устанавливает выбранную дату
   * @param date {Date|string}
   */
  setValue(date) {
    if (!date) {
      this.currentValue('');
      return;
    }

    if (date instanceof Date) {
      let value = dateToString(date, 'DD.MM.YYYY');
      this.currentValue(value);
    } else {
      this.currentValue(date);
    }
  }

  /**
   * Устанавливает отображаемый месяц
   * @param date {Date}
   */
  setDate(date) {
    this.date(date);
  }

  /**
   * Синхронизирует отображаемый месяц с выбранной датой
   */
  sync() {
    if (this.value) {
      this.setDate(dateFromString(this.value));
    }
  }
}
