import { getRanges } from 'Models/period-picker/ranges';
import { detectTouch } from 'Utils/detect/touch';
import { FoquzComponent } from 'Models/foquz-component';
export class ViewModel extends FoquzComponent {
  constructor(params, additionalParams, element) {
    super(params, element);

    this.pluginOptions = {
      ...(params.config || {}),
      ...additionalParams,
      arrowPosition: {
        anchor: 'right',
        offset: -28
      },
      opens: 'left'
    };

    if (params.ranges) {
      this.pluginOptions.ranges = getRanges();
    }

    this.allowClear = params.allowClear;

    this.value = params.value;
    this.autosize = params.autosize;
    this.autosizeInput = ko.observable(null);

    this.mode = ko.computed(() => {
      return ko.toJS(params.mode);
    });

    this.placeholder =
      'placeholder' in params
        ? params.placeholder
        : this.pluginOptions.single
        ? '00.00.0000'
        : '00.00.0000-00.00.0000';
    this.mask = this.pluginOptions.single
      ? '00.00.0000'
      : '00.00.0000-00.00.0000';

    this.detectTouch = detectTouch();

    this.disabled = params.disabled;
  }

  reset() {
    this.value('');
    $(this.element).find('.form-control').val('').trigger('change');
  }

  resize() {
    this.autosizeInput().update();
  }
}
