import { FoquzComponent } from "Models/foquz-component";
import ee from "event-emitter";
import { HidePoppersEvent } from "@/utils/events/modal";
// Все события модального окна foquz-dialog передаются вверх

export class DialogWrapper extends FoquzComponent {
  constructor(params, element) {
    super(params, element);
    ee(this);

    this.element = element;
    this.$element = $(element);

    // ссылка на оборачиваемый компонент
    this.modal = ko.observable(null);

    // ссылка на обертку диалога
    this.dialogWrapper = params.dialogWrapper;
    // ссылка на контейнер диалогов
    this.dialogRoot = params.dialogRoot;

    // переместить в конец body
    if (!this.dialogWrapper && !this.dialogRoot) {
      document.body.appendChild(element);
    }

    ko.applyBindingsToNode(element, {
      descendantsComplete: () => {
        this.onModalInit(params);
      },
    });
  }

  onModalInit(params) {
    let shown = false;
    const handleKeydownCb = (e) => {
      if (e.key === "Enter") {
        if (e.target.nodeName === "TEXTAREA") return;
        if (e.target.ckeditorInstance) return;

        e.preventDefault();
        e.stopPropagation();
        this.onEnter();
      }
    };

    this.modal().events.forEach((e) => {
      this.modal().on(e, (...eventParams) => {
        this.emit(e, ...eventParams);

        if (e === "shown") {
          shown = true;
          document.addEventListener("keydown", handleKeydownCb);
          HidePoppersEvent.emit();
        } else if (e === "hide") {
          shown = false;
          document.removeEventListener("keydown", handleKeydownCb);
        } else if (e === "hidden") {
          const el = this.dialogWrapper?.element || this.element;
          if (el) {
            ko.cleanNode(el);
            //el.remove();
          }
        }
      });
    });
    if (params.openImmediately) this.modal().show();
  }

  onEnter() {}

  get events() {
    return this.modal().events;
  }

  emitEvent(eventName, ...params) {
    this.$element.trigger(eventName, ...params);
    this.emit(eventName, ...params);
  }

  show() {
    this.modal().show();
  }

  hide(...params) {
    this.modal().hide(...params);
  }
}
