import { FComponent } from "@/components/f-component";

let nextId = 0;

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    const {
      max,
      color,
      thin,
      size,
      isCanChange,
      showLabels,
      showNumbers,
      labels,
      value,
      onChange,
    } = params;



    element.classList.add("fc-star-rating");
    element.setAttribute("data-size", size);
    if (showLabels) element.classList.add("fc-star-rating--labels");

    this.id = nextId++;
    this.max = max || 5;
    this.stars = Array(this.max)
      .fill()
      .map((s, i) => {
        return {
          index: i + 1,
          label: ko.pureComputed(() => {
            const labelsList = ko.toJS(labels);
            return labelsList && labelsList[i]
          }),
        };
      });
    this.color = color || "#F8CD1C";
    this.size = size;
    this.thin = thin;
    this.showLabels = showLabels;
    this.showNumbers = showNumbers;
    this.isCanChange = isCanChange;
    this.value = ko.isObservable(value) ? value : ko.observable(value);
    this.currentValue = ko.computed(() => {
      return this.value() || 0;
    });
    this.onChange = onChange;

    element.setAttribute("data-count", this.max);
    element.style.setProperty("--fc-star-rating-stars-count", this.max);

    if (typeof this.onChange == "function" && ko.isObservable(this.value)) {
      this.value.subscribe((v) => {
        this.onChange(v);
      });
    }
  }
}
