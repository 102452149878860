import { FComponent } from 'Components/f-component';
import '../../fc-select-result-item';
export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-select__wrapper');

    this.inline = params.inline;

    this.itemComponent = params.itemComponent || 'fc-select-result-item';

    this.disabled = params.disabled;
    this.readMode = params.readMode;
    this.loading = params.loading;
    this.placeholder = params.placeholder;
    this.clearable = params.clearable;
    this.selected = params.selected;
    this.active = params.active;


    this.showPlaceholder = ko.pureComputed(() => {
      if (this.selected().length) return false;
      if (!this.inline) return true;
      return true;
    });
  }

  removeOption(option) {
    this.emitEvent('removeOption', option);
  }

  removeAll() {
    this.emitEvent('removeAll');
  }

  activate(event) {
    if (ko.toJS(this.disabled)) return;

    if (event.target.closest('.fc-select-result__remove')) return;
    if (event.target.closest('.fc-select-field__remove')) return;

    setTimeout(() => {
      this.emitEvent('activate');
    });
  }
}
