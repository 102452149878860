ko.components.register('saved-message', {
  viewModel: {
    createViewModel: function createViewModel(params) {
      var show = params.show;
      var delay = params.delay || 4000;
      var text = params.text || 'Сохранено успешно';
      show.subscribe(function (v) {
        if (v) {
          setTimeout(function () {
            show(false);
          }, delay);
        }
      });

      return {
        show: show,
        text: text,
      };
    },
  },
  template:
    '\n  <!-- ko template: {\n    foreach: templateIf(show(), $data),\n    afterAdd: fadeAfterAddFactory(200),\n    beforeRemove: fadeBeforeRemoveFactory(200)\n  } -->\n  <div class="saved-message" data-bind="text: text"></div>\n  <!-- /ko -->',
});
