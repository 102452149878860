const { toJS, pureComputed } = ko;
export function ViewModel(params, element, nodes) {
  element.classList.add("fc-img-preview");

  const {
    url,
    preview,
    canRemove,
    onRemove,
    loading,
    gallery,
    index,
    disabled,
    invalid,
  } = params;

  this.loading = params.loading;
  const imgGallery = pureComputed(() => {
    if (params.gallery) return ko.toJS(gallery);
    return [{ src: toJS(url) }];
  });

  const backgroundImage = ko.pureComputed(() => {
    if (toJS(preview)) return `url(${toJS(preview)})`;
    if (toJS(url)) return `url(${toJS(url)})`;
    return "none";
  });

  return {
    url,
    image: backgroundImage,
    canRemove,
    loading,
    gallery: imgGallery,
    index,
    disabled,
    remove: () => {
      if (typeof onRemove === "function") onRemove();
    },
    nodes,
  };
}
