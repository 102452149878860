import { DialogsModule } from 'Utils/dialogs-module';
import 'Dialogs/mobile-info-dialog';

export class MobileInfo {
  constructor() {
    DialogsModule(this);

    this.openDialog({
      name: 'mobile-info-dialog'
    });

    this.setState();
  }

  setState() {
    $.ajax({
      url: '/foquz/ajax/mobile-quick-help-showed',
      method: 'POST',
      data: {
        showed: true
      }
    });
  }
}

let element = document.getElementById('mobile-info');

if (element) {
  let mq = window.matchMedia('(max-width: 767px)');
  if (mq.matches) {
    $.ajax({
      url: '/foquz/ajax/mobile-quick-help',
      success: (response) => {
        if (!response.data.showed) {
          ko.applyBindings(new MobileInfo(), element);
        }
      }
    });
  }
}
