import { ViewModel } from '../model';
import html from './template.html';
import './style.less';

ko.components.register('sidesheet', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('foquz-dialog');
      element.classList.add('sidesheet');

      element.setAttribute('data-tooltip-container', true);

      return new ViewModel(params, element);
    },
  },
  template: html,
});
