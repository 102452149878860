import { ModalWrapper } from 'Models/modal-wrapper';

export class ViewModel extends ModalWrapper {
  constructor(params, element) {
    super(params, element);

    let data = params.data || {};

    this.title = data.title;
    this.text = data.text;
    this.confirmText = data.confirm || _t('Подтвердить');
    this.cancelText = data.cancel || _t('Отменить');
    this.mode = data.mode || 'success';
    this.closeOnConfirm = 'closeOnConfirm' in data ? data.closeOnConfirm : true;

    this._onConfirm = data.onConfirm;
  }

  confirm() {
    $(this.wrapper).trigger('confirm');

    if (typeof this._onConfirm === 'function')
      this._onConfirm(() => this.close());

    if (this.closeOnConfirm) this.close();
  }
}
