ko.bindingHandlers.intervalInput = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let { min, max } = valueAccessor();

    $(element).inputFilter(function (value) {
      if (!value) return true;
      if (!/^\d*$/.test(value)) return false;
      let minLimit = ko.toJS(min);
      let maxLimit = ko.toJS(max);
      if (!isNaN(minLimit)) {
        if (value < minLimit) return false;
      }
      if (!isNaN(maxLimit)) {
        if (value > maxLimit) return false;
      }
      return true;
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).inputFilter('dispose');
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
