import { FComponent } from '@/components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-button-group');
    if (params.block) element.classList.add("fc-button-group--block");

    this.options = params.options;
    this.value = params.value;
    this.size = params.size;

    this.disabled = ko.isObservable(params.disabled)
      ? params.disabled
      : ko.observable(params.disabled);


    ko.applyBindingsToNode(element, {
      attr: {
        'data-size': this.size
      }
    })
  }
}
