/**
 * Выбор значения в интервале
 *
 * - value {Number|Array}
 * - min {Number}
 * - max {Number}
 * - step {Number}
 * - tooltips - отображение текущих значений над ручками слайдера
 * - labels {Boolean} - отображать min/max значения
 * - disabled {Boolean}
 */

import { binding } from './binding';
import { ViewModel as model } from './model';

import template from './template.html';
import './style';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbRange', binding);
registerComponent('fc-range', { model, template });
