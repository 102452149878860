import "./style";
import OverlayScrollbars from "overlayscrollbars";
import "overlayscrollbars/css/OverlayScrollbars.min.css";

const CLASSES = {
  element: "native-scrollbar",
  hasXScroll: "native-scrollbar--has-x",
  hasYScroll: "native-scrollbar--has-y",
  xStart: "native-scrollbar--x-start",
  xEnd: "native-scrollbar--x-end",
  yStart: "native-scrollbar--y-start",
  yEnd: "native-scrollbar--y-end",

  hasIndicators: "native-scrollbar--indicator",
};

function disposeNativeScrollbar(element) {
  element.classList.remove(CLASSES.element);

  if (typeof element._onDisposeCb === "function") element._onDisposeCb();

  let instance = OverlayScrollbars(element);
  if (instance) {
    instance.destroy();
  }
}

function initNativeScrollbar(
  element,
  valueAccessor,
  allBindings,
  viewModel,
  bindingContext
) {
  let ref = allBindings.get("ref");

  let disabled = false;
  if (allBindings.has("nativeScrollbarDisabled")) {
    disabled = ko.unwrap(allBindings.get("nativeScrollbarDisabled"));
  }

  if (disabled) {
    disposeNativeScrollbar(element);
    if (ko.isObservable(ref)) {
      ref(null);
    }
    return;
  }
  
  let instance = OverlayScrollbars(element);
  if (instance) {
    if (ko.isObservable(ref)) {
      ref({
        instance: instance,
      });
    }
    return instance;
  }

  // создать экземпляр
  let config = {
    sizeAutoCapable: true,
    ...(valueAccessor() || {}),
    callbacks: {
      onInitialized: () => setPositions(element),
      onScroll: (event) => {
        if (allBindings().onScroll && typeof allBindings().onScroll === 'function') {
          allBindings().onScroll(event)
        }
        setPositions(element, event.target);
      },
    },
    scrollbars: {
      dragScrolling: true,
      clickScrolling: true,
      touchSupport: true,
    },
  };

  let scrollbar = OverlayScrollbars(element, config);

  
  element.classList.add(CLASSES.element);

  let hasIndicators = allBindings.has("indicators")
    ? allBindings.get("indicators")
    : true;
  if (hasIndicators) {
    element.classList.add(CLASSES.hasIndicators);
  }

  let throttledSetPositions = _.throttle(() => {
    setPositions(element);
  }, 4000);

  $(window).resize(throttledSetPositions).scroll(throttledSetPositions);

  const observer = new MutationObserver(throttledSetPositions);
  observer.observe(element, {
    childList: true,
    subtree: true,
  });

  element._onDisposeCb = () => {
    $(window)
      .off("resize", throttledSetPositions)
      .off("scroll", throttledSetPositions);
    observer.disconnect();
  };

  if (ko.isObservable(ref)) {
    ref({
      instance: scrollbar,
    });
  }

  return scrollbar;
}

function setPositions(element, track) {
  if (!track) track = element.querySelector(".os-viewport");

  if (!track) return;

  let width = track.offsetWidth;
  let scrollWidth = track.scrollWidth;
  let hasXScroll = scrollWidth > width;

  let height = track.offsetHeight;
  let scrollHeight = track.scrollHeight;
  let hasYScroll = scrollHeight > height;

  element.classList.toggle(CLASSES.hasXScroll, hasXScroll);
  element.classList.toggle(CLASSES.hasYScroll, hasYScroll);

  if (hasXScroll) {
    let xOffset = track.scrollLeft;
    element.classList.toggle(CLASSES.xStart, xOffset === 0);
    element.classList.toggle(CLASSES.xEnd, xOffset + width === scrollWidth);
  }

  if (hasYScroll) {
    let yOffset = track.scrollTop;
    element.classList.toggle(CLASSES.yStart, yOffset === 0);
    element.classList.toggle(CLASSES.yEnd, yOffset + height + 1 >= scrollHeight);
  }
}

ko.bindingHandlers.nativeScrollbar = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    ko.applyBindingsToDescendants(bindingContext, element);

    initNativeScrollbar(
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    );

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      disposeNativeScrollbar(element);
    });

    return { controlsDescendantBindings: true };
  },
  update(element, valueAccessor, allBindings, viewModel, bindingContext) {
    initNativeScrollbar(
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    );
  },
};

ko.bindingHandlers.utilNativeScrollbar = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    initNativeScrollbar(
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    );

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      disposeNativeScrollbar(element);
    });
  },
  update(element, valueAccessor, allBindings, viewModel, bindingContext) {
    initNativeScrollbar(
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    );
  },
};
