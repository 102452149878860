import { getScrollbarSize, hasScrollbar } from 'Utils/window/scroll';

window.__dialogsCollection = window.__dialogsCollection || {
  collection: [],

  currentScroll: 0,

  fix() {
    let body = document.body;

    body.classList.add('no-scroll');
    body.classList.add('has-modals');

    this.currentScroll = window.pageYOffset;
    body.style.position = 'fixed';

    if (hasScrollbar()) {
      body.style.width = `calc(100% - ${getScrollbarSize()}px)`;
    } else {
      body.style.width = '100%';
    }

    body.style.top = `-${this.currentScroll}px`;
  },

  unfix() {
    let body = document.body;

    body.classList.remove('no-scroll');
    body.classList.remove('has-modals');

    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.top = '';

    window.scrollTo(0, this.currentScroll);
  },

  add(dialog) {
    if (!this.collection.length) {
      this.fix();
    }
    dialog.element.setAttribute('data-dialog-index', this.collection.length);
    this.collection.push(dialog);
  },

  remove(dialog) {
    dialog.element.removeAttribute('data-dialog-index');
    let index = this.collection.findIndex((d) => d == dialog);
    if (index > -1) {
      this.collection.splice(index, 1);
      if (!this.collection.length) {
        this.unfix();
      }
    }
  }
};

export default window.__dialogsCollection;
