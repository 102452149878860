import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.dialogs = ko.observableArray([]);
    document.body.appendChild(element);
  }

  add(component) {
    // порядковый индекс диалога в контейнере
    let _dialogIndex = ko.observable(this.dialogs().lenght);

    let ref = ko.observable(null);
    let params = component.params || {};
    params.ref = ref;
    params.openImmediately = true;
    params.dialogRoot = this;
    params._dialogIndex = _dialogIndex;

    let dialog = {
      name: component.name,
      params
    };

    dialog.subscription = this.dialogs.subscribe((v) => {
      _dialogIndex(v.indexOf(dialog));
    });

    dialog.init = (el) => {
      $(el).on('hidden', () => {
        this.onDialogHide(dialog);
      });

      let events = component.events || {};
      Object.keys(events).forEach((eventName) => {
        if (typeof events[eventName] == 'function') {
          $(el).on(eventName, (event, eventData) => {
            events[eventName](eventData, ref());
          });
        }
      });
    };

    this.dialogs.push(dialog);

    return { ref, dialog };
  }

  remove(dialog) {
    dialog.subscription.dispose();
    this.dialogs.remove(dialog);
  }

  removeAll() {
    let dialogs = this.dialogs();
    for (let i = dialogs.length - 1; i >= 0; i--) {
      this.remove(dialogs[i]);
    }
  }

  onDialogHide(dialog) {
    this.remove(dialog);
  }
}
