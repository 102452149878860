import { FoquzComponent } from "Models/foquz-component";
import DialogCollection from "../collection";
import ee from "event-emitter";

import "Dialogs/foquz-dialog-header";

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    ee(this);

    this.element = element;
    this.$element = $(element);

    this.beforeHide = params.beforeHide;

    // ссылка на обертку диалога
    this.dialogWrapper = params.dialogWrapper;
    // ссылка на контейнер диалогов
    this.dialogRoot = params.dialogRoot;

    // переместить в конец body
    if (!this.dialogWrapper && !this.dialogRoot) {
      document.body.appendChild(element);
    }

    ko.applyBindingsToNode(element, {
      descendantsComplete: () => {
        this.emitEvent("ready");
        if (params.openImmediately) this.show();
      },
    });
  }

  get events() {
    return ["show", "shown", "hide", "hidden", "ready"];
  }

  onMaskClick() {
    this.hide("mask");
  }

  emitEvent(eventName, ...params) {
    this.$element.trigger(eventName, ...params);
    this.emit(eventName, ...params);
  }

  onAnimationEnd() {
    return new Promise((res) => {
      let cb = (event) => {
        if (event.target == this.element) {
          this.element.removeEventListener("animationend", cb);
          res();
        }
      };
      this.element.addEventListener("animationend", cb);
    });
  }

  show() {
    this.emitEvent("show");
    this.emitEvent("dialog.show");

    this.onAnimationEnd().then(() => {
      this.element.classList.add("shown");
      this.element.classList.remove("showing");
      DialogCollection.add(this);
      this.emitEvent("shown");
      this.emitEvent("dialog.shown");
    });

    this.element.classList.remove("hidden");
    this.element.classList.add("showing");
  }

  async hide(source) {
    if (typeof this.beforeHide === 'function') {
      await this.beforeHide();
    }
    return new Promise((res) => {
      this.emitEvent("hide", { source });
      this.emitEvent("dialog.hide", { source });

      this.onAnimationEnd().then(() => {
        this.element.classList.add("hidden");
        this.element.classList.remove("hiding");
        this.emitEvent("hidden");
        this.emitEvent("dialog.hidden", { source });
        DialogCollection.remove(this);
        res();
      });

      this.element.classList.remove("shown");
      this.element.classList.add("hiding");
    });
  }

  dispose() {
    DialogCollection.remove(this);
    super.dispose();
  }
}
