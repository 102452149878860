import { copyToClipboard } from '@/utils/copy-to-clipboard';

import { Translator } from '@/utils/translate'
const MainTranslator = Translator('main')

export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.value = params.value;
    this.tooltip = params.tooltip || MainTranslator.t('Скопировать в буфер');
    this.element = element;
    this.mode = 'copy';
    this.disabled = params.disabled;
    //if (params.mode == 'link') this.mode = 'link'
  }

  copy() {
    copyToClipboard(ko.toJS(this.value), this.element);
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
