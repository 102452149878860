
/**
 * Кнопка с выпадающим меню
 *
 * - list [ name, click ]
 *
 * slots
 *   - dropdown
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-dropdown-button', { model, template });
