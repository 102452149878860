/**
 * Лейбл поля ввода
 *
 * text - текст метки
 * hint - кнопка-вопрос с подсказкой
 * required - красная звездочка после лейбла
 * optional - текст Необязательное
 * style - sm (14), xs (12)
 * forId
 *
 * slots
 *   - text
 *   - hint
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-label', {
  model,
  template
});
