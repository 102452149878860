export class ViewModel {
  constructor(params, element) {
    this.visible = params.show;

    let $element = $(element);

    this.text = ko.pureComputed(() => {
      return ko.toJS(params.text);
    });

    this.html = ko.pureComputed(() => {
      return ko.toJS(params.html);
    });

    if (!ko.isObservable(this.visible)) {
      if (!this.visible) $element.fadeOut(0);
      return;
    }

    if (!this.visible()) $element.fadeOut(0);

    this.visible.subscribe((v) => {
      $element.toggleClass('error-visible', v)
      if (v) $element.fadeIn(200);
      else $element.fadeOut(200);
    });
  }
}
