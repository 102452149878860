Highcharts.setOptions({
  lang: {
    viewFullscreen: 'Посмотреть в полноэкранном режиме',
    printChart: 'Распечатать',
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG'
  }
});
