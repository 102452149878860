import { DialogWrapper } from "Dialogs/wrapper";
import { useFoldersList } from "@/presentation/hooks/useFoldersList";
import { getPollFolders } from "@/api/poll/get-poll-folders";

import * as FoldersList from "../components/folders-list";
import { registerComponent } from "@/utils/engine/register-component";
import { Translator } from "@/utils/translate";

registerComponent("move-to-folder-dialog-list", FoldersList);
const pollsTranslator = Translator("polls");

export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);

    element.classList.add("select-poll-folder-dialog");

    const {
      current = "0",
      disabled = [],
      title,
      confirm,
      mode = "default",
      onSelect,
    } = params;

    this.pending = ko.observable(false);
    this.title = title || pollsTranslator.t("Выберите папку");
    this.onSelect = onSelect;
    this.confirm = confirm || pollsTranslator.t("Выбрать");
    this.mode = mode;

    const { loading, query, list, getSubtree, loaded } = useFoldersList(getPollFolders);

    this.loading = loading;
    this.searchString = query;
    this.flattenedFolders = list;

    this.current = current;
    this.disabled = ko.computed(() => {
      let disabledIds = [];
      disabled.forEach((id) => {
        disabledIds = [...disabledIds, ...getSubtree(id)];
      });
      return disabledIds;
    });

    this.value = ko.observable(current);

    loaded.subscribe(v => {
      if (v) {
        let items = ko.toJS(list);
        let currentItem = items.find(item => item.id === current);
        if (currentItem && !currentItem.inactive) this.value(current);
        else {
          let item = items.find(i => !i.inactive);
          this.value(item.id);
        }
      }
    })
  }

  onEnter() {
    this.submit();
  }

  submit() {
    this.pending(true);
    this.onSelect(this.value()).then(() => {
      this.pending(false);
      this.hide();
    });
  }
}
