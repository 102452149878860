export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.table = params.table;
    this.hideBeforeInit = params.hideBeforeInit;
    this.notFoundText = params.notFoundText || _t('Ничего не найдено');
    this.emptyText = params.emptyText || _t('Ничего не найдено');

    this.scroll = params.scroll;
    this.fixedHeader = params.fixedHeader;

    this.empty = ko.computed(() => {
      return this.hideBeforeInit && !this.table.initing() && this.table.empty();
    });

    this.pagination = 'pagination' in params ? params.pagination : true;

    this.horizontal = params.horizontal;
    if (this.horizontal) {
      element.classList.add('interactive-table--horizontal');
    }

    this.observerConfig = params.horizontal ? {

    } : {};
  }

  onIntersection() {
    this.table.nextPage();
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
