import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.max = params.max;
    this.value = params.value;

    this.status = ko.pureComputed(() => {
      let max = ko.toJS(this.max);
      let value = ko.toJS(this.value);

      if (max == 0) return -1;
      if (value == 0) return -1;
      if (value == max) return 1;

      return 0;
    })
  }
}
