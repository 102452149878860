import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.value = ko.pureComputed(() => {
      let value = parseFloat(ko.unwrap(params.value)) || 0;
      value = Math.floor(value * 10) / 10;
      return value;
    });

    this.label = '';

    if (params.label) {
      if (params.label === true) {
        this.label = this.value;
      } else {
        this.label = params.label;
      }
    }

    this.count = params.count || 5;
    this.color = params.color || '#F8CD1C';

    this.stars = Array(this.count)
      .fill(null)
      .map((_, i) => {
        return ko.pureComputed(() => {
          let value = this.value();
          if (value >= i + 1) return 'fill';
          if (value <= i) return 'empty';
          return 'half';
        });
      });
  }
}
