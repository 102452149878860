export function formatDate(date, fromFormat = 'DD.MM.YYYY', toFormat) {
  toFormat = toFormat || fromFormat;
  let d = moment(date, fromFormat);
  if (!d.isValid()) return '';
  return d.format(toFormat);
}

export function findRange(from, to, ranges, format = 'DD.MM.YYYY') {
  if (!ranges) return '';

  let rangeKey = Object.keys(ranges).find((rangeName) => {
    let rangeFrom = ranges[rangeName][0];
    let rangeTo = ranges[rangeName][1];

    if (rangeFrom.format(format) !== from) return false;
    if (rangeTo.format(format) !== to) return false;

    return true;
  });

  if (rangeKey) return rangeKey;

  return '';
}

export function formatServerDate(date) {
  let d = moment(date, 'YYYY-MM-DD');
  if (!d.isValid()) return '';
  return d.format('DD.MM.YYYY');
}

export function formatServerPeriod(from, to, ranges) {
  let f = formatServerDate(from);
  if (!f) return '';
  let t = formatServerDate(to);
  if (!t) return '';

  if (ranges) {
    let rangeKey = Object.keys(ranges).find((rangeName) => {
      let rangeFrom = ranges[rangeName][0];
      let rangeTo = ranges[rangeName][1];

      if (rangeFrom.format('DD.MM.YYYY') !== f) return false;
      if (rangeTo.format('DD.MM.YYYY') !== t) return false;

      return true;
    });

    if (rangeKey) return rangeKey;
  }

  return `${f} - ${t}`;
}

export function formatClientPeriod(period, format = 'YYYY-MM-DD') {
  let value = ['', ''];
  if (!period) return value;

  let range = period.split('-');
  let from = range[0];
  if (!from) return value;

  from = moment(from.trim(), 'DD.MM.YYYY');
  if (!from.isValid()) return value;
  from = from.format(format);

  let to = range[1];
  if (to) to = moment(to.trim(), 'DD.MM.YYYY');
  if (to && to.isValid()) to = to.format(format);
  else to = from;

  return [from, to];
}

export function getPeriod(from, to, format = 'DD.MM.YYYY', ranges) {
  let f = formatDate(from, format);
  if (!f) return '';
  let t = formatDate(to, format);
  if (!t) t = f;

  let rangeKey = findRange(f, t, ranges, format);
  if (rangeKey) return rangeKey;

  return `${f} - ${t}`;
}
