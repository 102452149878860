import { ViewModel } from './model';
import html from './template.html';
import './style.less';

ko.components.register('password-field', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);
      $element.addClass(['f-input-group', 'foquz-password-field'])

      return new ViewModel(params, element);
    },
  },
  template: html,
});
