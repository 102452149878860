import html from './template.html';
import './style.less';

ko.components.register('foquz-icon', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;

      element.classList.add('f-icon');

      let icon = params.icon;
      let prefix = params.prefix;

      if (prefix) {
        element.classList.add(`f-icon-${prefix}`);
        element.classList.add(`f-icon-${prefix}--${icon}`);
      } else {
        element.classList.add(`f-icon--${icon}`);
      }

      let svgName = prefix ? `${prefix}-icon-${icon}` : `${icon}-icon`;

      return {
        icon: svgName,
      };
    },
  },
  template: html,
});
