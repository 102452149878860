import { FComponent } from '@/components/f-component';
import { Ref } from '@/utils/engine/ref';
export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-range');

    this.isSingleValue = Array.isArray(ko.toJS(params.value));

    if (params.tooltips) {
      element.classList.add('fc-range--tooltips');
    }

    this.labels = params.labels;
    this.min = params.min || 0;
    this.max = params.max || 0;

    this.config = {
      value: params.value,
      min: this.min,
      max: this.max,
      step: params.step || 1,
      disabled: params.disabled,
    };
    this.disabled = params.disabled;
  }
}
