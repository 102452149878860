import './style.less';

ko.bindingHandlers.contentEditable = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let value = valueAccessor();

    element.classList.add('contenteditable');

    function update() {
      if (ko.isWriteableObservable(value)) {
        value(this.textContent);
      }
    }

    element.innerHTML = value();
    element.contentEditable = true;
    element.addEventListener('input', update);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      element.removeEventListener('input', update);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
