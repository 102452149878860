import { DialogWrapper } from 'Dialogs/wrapper';
import { PollInfoModel } from 'Models/poll-info-model';
import { copyToClipboard } from "../../utils/copy-to-clipboard";
export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);

    this.model = new PollInfoModel(params.poll);
    this.copied = ko.observable(false)

    this.noAnswersLeft = ko.computed(() => {
      return this.model.isPublished() && this.model.hasPublishedAnswersLimit() && !this.model.answersLeft();
    });
  }

  copyId() {
    copyToClipboard(this.model.id).then(() => {
      this.copied(true);
      setTimeout(() => {
        this.copied(false);
      }, 3000);
    });
  }
}
