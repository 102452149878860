export function arrowPositionModifier(position) {
  return {
    name: 'customArrow',
    enabled: !!position,
    phase: 'write',
    fn({ state }) {
      let arrow = state.elements.arrow;
      if (!arrow) return;

      let popperOffsets = state.modifiersData.popperOffsets;
      let arrowData = state.modifiersData.arrow;

      if (position.placement === 'end') {
        let xOffset = state.rects.popper.x - state.rects.reference.x;
        let arrowOffset =
          arrowData.x * 2 + (xOffset + popperOffsets.x) - position.offset;
        arrow.style.transform = `translateX(${arrowOffset}px) translateX(50%)`;
      } else if (position.placement === 'start') {
        arrow.style.transform = `translateX(${position.offset}px) translateX(-50%)`;
      } else if (position.placement === 'center') {
        let xOffset = state.rects.popper.x - state.rects.reference.x;
        let arrowOffset =
          arrowData.x * 2 + (xOffset + popperOffsets.x) - position.offset;
        arrow.style.transform = `translateX(${position.offset / 2}px) translateX(-50%)`;
      }
    },
    requires: ['arrow']
  };
}
