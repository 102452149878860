let unique = 1;

export class ViewModel {
  constructor({
    value,
    placeholder,
    help = false,
    isValid = ko.observable(false),
    showErrorMsg = ko.observable(false),
  }, element) {
    this.value = value;
    this.placeholder = placeholder || '';


    let attributes = [...element.attributes].filter(attr => {
      return ['class', 'data-bind', 'id'].indexOf(attr.name) == -1
    });

    this.id = 'password-input-' + unique++;

    this.visible = ko.observable(false);
    this.isValid = isValid;
    this.showErrorMsg = showErrorMsg;
    this.isSelected = ko.observable(false);
    this.help = help;

    this.tooltipText = ko.computed(() => {
      return this.visible()
        ? 'Скрыть пароль'
        : 'Показать пароль';
    });

    this.attrs = {
      type: ko.computed(() => this.visible() ? 'text' : 'password'),
      placeholder: placeholder || ''
    };
    attributes.forEach(attr => {
      this.attrs[attr.name] = attr.value;
    })

    
    this.hasMinLength = ko.computed(() => {
      const value = this.value();
      if (typeof value !== 'string') return false;
      return value.length >= 8;
    });
    
    this.hasUppeLowerrCase = ko.computed(() => {
      const value = this.value();
      const regexp = /[A-Za-z]/;
      if (typeof value !== 'string') return false;
      return regexp.test(value)
        && (value.toLowerCase() !== value)
        && (value.toUpperCase() !== value);
    });
    
    this.hasSimbol = ko.computed(() => {
      const value = this.value();
      const regexp = /[!@#$%^&*]/;
      if (typeof value !== 'string') return false;
      return regexp.test(value);
    });
    
    this.hasNum = ko.computed(() => {
      const value = this.value();
      const regexp = /[0-9]/;
      if (typeof value !== 'string') return false;
      return regexp.test(value);
    });
    
    this.hasSpace = ko.computed(() => {
      const value = this.value();
      if (typeof value !== 'string') return false;
      return value.replace(' ', '') !== value;
    });

    this._isValid = ko.computed(() => {
      const isValid = this.hasMinLength()
        && this.hasUppeLowerrCase()
        && this.hasSimbol()
        && this.hasNum()
        && !this.hasSpace();
      return isValid;
    });

    this._isValid.subscribe((value) => {
      this.isValid(value);
    });
  }
}
