/** Поле для ввода только цифр */

ko.bindingHandlers.onlyNumbers = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {
    let params = valueAccessor() || {};
    let reg = '\\d*';
    if (params.sign) reg = '-?\\d*';
    let im = new Inputmask({ regex: reg, showMaskOnHover: false, }).mask(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Inputmask.remove(element);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {},
};
