import { FComponent } from 'Components/f-component';


export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-question');

    this.text = params.text;
  }
}
