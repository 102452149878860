/**
 * Bootstrap не открывает больше одного модального окна
 */
$(document).on('show.bs.modal', function (event) {

});
$(document).on('hidden.bs.modal', function (event) {
  let modal = $(event.target).data("bs.modal");
  var scrollbarWidth = modal ? modal._scrollbarWidth : 17;
  if ($('.modal:visible').length) {
      $('body').addClass('modal-open');
      $('body').css('padding-right', scrollbarWidth);
  }
});
