/**
 * https://www.daterangepicker.com/
 *
 * startDate
 * endDate
 *
 * parentEl
 * showDropdowns
 * opens: left|right|center
 * drops: down|up|auto
 * singleDatePicker
 * autoApply
 * autoUpdateInput
 *
 * ranges
 * showCustomRangeLabel
 * alwaysShowCalendars
 *
 * buttonClasses
 * applyButtonClasses
 * cancelButtonClasses
 *
 * locale
 *
 * minDate
 * maxDate
 * maxSpan
 * minYear
 * maxYear
 *
 * showWeekNumbers
 * showISOWeekNumbers
 *
 * timePicker
 * timePickerIncrement
 * timePicker24Hour
 * timePickerSeconds
 *
 * linkedCalendars
 * isInvalidDate
 * isCustomDate
 */

import ee from 'event-emitter';

export class DateRangePicker extends window.daterangepicker {
  constructor(element, options, onSelectDate) {
    super(element, options);
    ee(this);

    if (this.singleDatePicker) {
      this.container.find('.drp-buttons').remove();
    }

    this.root = $('<div class="drp-root"></div>');
    this.wrapper = $('<div class="drp-wrapper"></div>');
    this.parentEl.append(this.root);
    this.root.append(this.wrapper);
    this.wrapper.append(this.container);
  }

  hide(...params) {
    super.hide(...params);
    this.root.attr('data-shown', false);
  }

  show(...params) {
    if (this.isShowing) return;
    this.root.attr('data-shown', true);
    super.show(...params);
  }

  renderCalendar(...params) {
    super.renderCalendar(...params);

    if (this.showDropdowns) {
      let $container = this.container;
      let selects = $container.find('select');
      [...selects].forEach((el) => {
        ko.applyBindingsToNode(el, {
          select2: {
            dropdownParent: $container,
          }
        });
      });
      $container.find('th.prev').remove();
      $container.find('th.next').remove();
      $container.find('th.month').attr('colspan', '7');
    }
  }

  clickDate(...params) {
    super.clickDate(...params);

    this.updateFormInputs('daterangepicker.select', {
      start: this.startDate,
      end: this.endDate
    });

    this.emit('select.daterangepicker', {
      start: this.startDate,
      end: this.endDate
    });
  }

  updateFormInputs(...params) {
    super.updateFormInputs(...params);

    this.container.find('button.applyBtn').prop('disabled', false);
  }

  clickApply(...params) {
    if (!this.endDate) this.endDate = this.startDate;
    super.clickApply(...params);
  }
}
