// TODO переделать на getList (один вариант получения данных)

export function useList(params = {}) {
  const { items, handleItems } = params;

  let cbs = [];

  let _items = ko.observableArray([]);
  let getItems = null;
  let loading = ko.observable(false);

  function updateItems(data) {
    if (!data) {
      _items([]);
      return;
    }
    if (typeof handleItems === "function") {
      _items(handleItems(data));
    } else {
      _items(data);
    }
  }

  function loadItems() {
    if (typeof getItems === "function") {
      loading(true);
      getItems(
        (data) => {
          updateItems(data);
          loading(false);
        },
        // TODO remove 2nd param
        (data) => {
          updateItems(data);
          loading(false);
        }
      );
    }
  }

  if (
    ko.isObservable(items) ||
    ko.isObservableArray(items) ||
    ko.isComputed(items)
  ) {
    updateItems(ko.toJS(items));
    let cb = items.subscribe((v) => {
      updateItems(v);
    });
    cbs.push(() => cb.dispose());
  } else if (typeof items === "function") {
    getItems = items;
    loadItems();
  } else if (Array.isArray(items)) {
    updateItems(items);
  }

  return {
    loading,
    items: _items,
    update: loadItems,
    dispose: () => cbs.forEach((cb) => cb()),
  };
}
