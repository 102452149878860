var AutosizeInputOptions = (function () {
  function AutosizeInputOptions(space) {
    if (typeof space === "undefined") {
      space = 30;
    }
    this.space = space;
  }
  return AutosizeInputOptions;
})();

var AutosizeInput = (function () {
  function AutosizeInput(input, options) {
    var _this = this;

    this._input = $(input);
    this._options = $.extend({}, AutosizeInput.getDefaultOptions(), options);
    // Init mirror
    this._mirror = $(
      '<span style="position:absolute; top:-999px; left:0; white-space:pre;"/>'
    );
    let mirror = this._mirror.get(0);

    let inputStyle = getComputedStyle(input);

    // Copy to mirror
    $.each(
      [
        "fontFamily",
        "fontSize",
        "fontWeight",
        "fontStyle",
        "letterSpacing",
        "textTransform",
        "wordSpacing",
        "textIndent",
      ],
      function (i, val) {
        //_this._mirror[0].style[val] = _this._input.css(val);
        mirror.style[val] = inputStyle[val];
      }
    );

    $("body").append(this._mirror);

    // Bind events - change update paste click mousedown mouseup focus blur
    // IE 9 need keydown to keep updating while deleting (keeping backspace in - else it will first update when backspace is released)
    // IE 9 need keyup incase text is selected and backspace/deleted is hit - keydown is to early
    // How to fix problem with hitting the delete "X" in the box - but not updating!? mouseup is apparently to early
    // Could bind separatly and set timer
    // Add so it automatically updates if value of input is changed http://stackoverflow.com/a/1848414/58524
    this.updateHandler = (e) => this.update();
    this._input.on(
      "keydown keyup input propertychange change",
      this.updateHandler
    );

    // Update
    (function () {
      _this.update();
    })();
  }
  AutosizeInput.prototype.getOptions = function () {
    return this._options;
  };
  AutosizeInput.prototype.update = function () {
    var value = this._input.val() || this._input.attr("placeholder") || "";
    // Update mirror
    this._mirror.text(value);
    // Calculate the width
    var newWidth = this._mirror.width() + this._options.space;
    // Update the width
    this._input.width(newWidth);
  };

  AutosizeInput.prototype.dispose = function () {
    this._input.off(
      "keydown keyup input propertychange change",
      this.updateHandler
    );
  };

  AutosizeInput.getDefaultOptions = function () {
    return this._defaultOptions;
  };
  AutosizeInput.getInstanceKey = function () {
    // Use camelcase because .data()['autosize-input-instance'] will not work
    return "autosizeInputInstance";
  };
  AutosizeInput._defaultOptions = new AutosizeInputOptions();

  return AutosizeInput;
})();

// jQuery Plugin
(function ($) {
  var pluginDataAttributeName = "autosize-input";
  var validTypes = [
    "text",
    "password",
    "search",
    "url",
    "tel",
    "email",
    "number",
  ];
  // jQuery Plugin
  $.fn.autosizeInput = function (options) {
    return this.each(function () {
      // Make sure it is only applied to input elements of valid type
      // Or let it be the responsibility of the programmer to only select and apply to valid elements?
      if (!(this.tagName == "INPUT" && $.inArray(this.type, validTypes) > -1)) {
        // Skip - if not input and of valid type
        return;
      }
      var $this = $(this);
      if (!$this.data(AutosizeInput.getInstanceKey())) {
        // If instance not already created and attached
        if (options == undefined) {
          // Try get options from attribute
          options = $this.data(pluginDataAttributeName);
        }
        // Create and attach instance
        $this.data(
          AutosizeInput.getInstanceKey(),
          new AutosizeInput(this, options)
        );
      }

      // Dispose logic
      if (options == "dispose") {
        let instance = $this.data(AutosizeInput.getInstanceKey());
        if (instance) instance.dispose();
      }
    });
  };
  // On Document Ready
  $(function () {
    // Instantiate for all with data-provide=autosize-input attribute
    $("input[data-" + pluginDataAttributeName + "]").autosizeInput();
  });
  // Alternative to use On Document Ready and creating the instance immediately
  //$(document).on('focus.autosize-input', 'input[data-autosize-input]', function (e)
  //{
  //  $(this).autosizeInput();
  //});
})(jQuery);
