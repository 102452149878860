export class ViewModel {
  constructor(params, element) {
    this.element = element;

    this.max = params.max;
    this.value = params.value;

    this.count = ko.pureComputed(() => {
      let value = String(this.value()) || '';
      return this.max - value.length;
    })
  }

  onRender() {
    let field = $(this.element).find('input,textarea').get(0);
    if (field) {
      field.maxLength = this.max;
    }
  }
}
