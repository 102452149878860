export const binding = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let scroll = valueAccessor();

    scroll.xScroll.subscribe(v => {
      element.scrollLeft = v ;
    })
  }
}
