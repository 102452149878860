export class ViewModel {
  constructor(params, element) {
    this.name = params.name;
    this.sort = params.sort;
    this.show = ko.pureComputed(() => {
      return this.name == this.sort();
    });
    this.asc = params.asc;
  }
}
