import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    let empty = ko.toJS(params.empty);
    if (empty) element.classList.add('foquz-dialog-header--empty');

    if (ko.isObservable(params.empty)) {
      this.subscriptions.push(params.empty.subscribe(v => {
        element.classList.toggle('foquz-dialog-header--empty', v);
      }))
    }
  }
}
