import { DialogWrapper } from 'Dialogs/wrapper';





export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);
  }

}
