import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-auto-input');

    this.input = ko.observable(null);

    this.config = {

    }

    if ('space' in params) {
      this.config.space = params.space;
    }

    this.value = params.value;
    this.disabled = ko.computed(() => {
      return !!ko.toJS(params.disabled);
    });
    this.placeholder = params.placeholder;
    this.clearable = params.clearable;
    this.mask = params.mask;

    if (ko.isObservable(this.placeholder)) {
      let cb = this.placeholder.subscribe((v) => {
        setTimeout(() => this.resize());
      });
      this.onDispose(() => cb.dispose());
    }

    if (ko.isObservable(this.value)) {
      let cb = this.value.subscribe((v) => {
        setTimeout(() => this.resize());
      });
      this.onDispose(() => cb.dispose());
    }

    this.canClear = ko.computed(() => {
      return (
        !ko.toJS(this.disabled) &&
        ko.toJS(this.clearable) &&
        ko.toJS(this.value)
      );
    });

    this.appendIcon = null;
    if ('appendIcon' in params) {
      if (typeof params.appendIcon === 'string') {
        this.appendIcon = {
          name: params.appendIcon,
          color: 'light',
          size: 24
        };
      } else {
        this.appendIcon = params.appendIcon;
      }
    }

    ko.applyBindingsToNode(element, {
      css: {
        'fc-auto-input--clearable': this.canClear,
        'fc-auto-input--append-icon': this.appendIcon
      }
    });

    
  }

  focus() {
    this.input() && this.input().focus();
  }

  clear() {
    if (ko.isObservable(this.value)) {
      this.value('');
    } else {
      this.input().value = '';
    }
    $(this.input()).trigger('change');
  }

  getInput() {
    return this.input();
  }

  resize() {
    $(this.input()).trigger('change');
  }
}
