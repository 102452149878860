const POPPER_MAX_HEIGHT = 264;

export const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    let { width } = state.rects.reference;
    if (document.body.offsetWidth >= 768 && state.elements.reference.dataset.reference) {
      width = document.querySelector(state.elements.reference.dataset.reference).offsetWidth;
      state.styles.popper.left = '50%';
      if ($('body').prop('id') === 'advantage' && $(state.elements.reference).closest('.survey-questions__question-card').hasClass('question-card--type_21')) {
        width = state.rects.reference.width + 40
        state.styles.popper.left = state.rects.reference.x - 20 + width/2 + 'px';
      }
      
      const selectRect = state.elements.reference.getBoundingClientRect();
      state.styles.popper.position = 'fixed';
      
      if (POPPER_MAX_HEIGHT + selectRect.bottom + 3 > document.body.offsetHeight - 10) {
        if (POPPER_MAX_HEIGHT > selectRect.top - 3) {
          
          state.styles.popper.top = '50%';
          state.styles.popper.transform = 'translate(-50%, -50%)';
        } else {
          state.styles.popper.top = 'unset';
          state.styles.popper.bottom = `${document.body.offsetHeight - selectRect.top + 3}px`;
          state.styles.popper.transform = 'translate(-50%, 0%)';
        }
      } else {
        state.styles.popper.top = `${selectRect.bottom + 3}px`;
        state.styles.popper.bottom = 'unset';
        state.styles.popper.transform = 'translate(-50%, 0%)';
      }
    }
    state.styles.popper.width = `${width}px`;
  },
  effect: ({ state }) => {
    let { width } = state.elements.reference.offsetWidth;
    if (document.body.offsetWidth >= 768 && state.elements.reference.dataset.reference) {
      width = document.querySelector(state.elements.reference.dataset.reference).offsetWidth;
      state.elements.popper.style.left = '50%';
      state.elements.popper.style.transform = 'translate(-50%, 0)';
    }
    state.elements.popper.style.width = `${width}px`;
  }
}
