import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.buttons = ko.unwrap(params.buttons).map((btn) => {
      return {
        ...btn,
        disabled: ko.computed(() => {
          return ko.toJS(params.disabled) || ko.toJS(btn.disabled);
        }),
        onDisabledClick: () => {
          if (typeof btn.onDisabledClick === 'function') btn.onDisabledClick();
        }
      };
    });
    this.value = params.value;
  }
}
