import { FComponent } from '@/components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-intersection-watcher');

    this.state = ko.observable(null);
  }

  updateState(newState) {
    let state = this.state();

    if (!state) {
      this.emitEvent('intersect', newState);
      this.emitEvent('first.intersect', newState);
      this.state(newState);
      return;
    }

    this.emitEvent('intersect', newState);

    if (!state.isIntersecting && newState.isIntersecting) {
      this.emitEvent('show.intersect');

      if (state.boundingClientRect.top > state.rootBounds.bottom) {
        this.emitEvent('bottom.show.intersect');
      } else if (state.boundingClientRect.bottom < state.rootBounds.top) {
        this.emitEvent('top.show.intersect');
      }
    }

    if (state.isIntersecting && !newState.isIntersecting) {
      this.emitEvent('hide.intersect');

      if (newState.boundingClientRect.top > newState.rootBounds.bottom) {
        this.emitEvent('bottom.hide.intersect');
      } else if (newState.boundingClientRect.bottom < newState.rootBounds.top) {
        this.emitEvent('top.hide.intersect');
      }
    }

    this.state(newState);
  }

  onElementRender() {
    this.observer = new IntersectionObserver(([entry]) => {
      this.updateState(entry);
    });
    this.observer.observe(this.element);

    this.onDispose(() => {
      this.observer.unobserve(this.element);
    });
  }
}
