import ee from 'event-emitter';

let unique = 1;
export class FComponent {
  constructor(params = {}, element, nodes) {
    ee(this);

    this._id = unique++;

    if ('ref' in params) {
      this._ref = params.ref;
      if (ko.isObservable(this._ref)) {
        this._ref(this);
      } else {
        this._ref = this;
      }
    }

    this.disposeCallbacks = [];
    this.subscriptions = [];

    if (element) {
      this.element = element;
  
      ko.applyBindingsToNode(element, {
        descendantsComplete: () => {
          if (typeof this.onElementRender === 'function')
            this.onElementRender();
          this.emitEvent('rendered');
        }
      });
    }

    this.slots = {};
    if (nodes && Array.isArray(nodes)) {
      nodes.forEach((node) => {
        if (node.dataset && node.dataset.slot) {
          this.slots[node.dataset.slot] = node;
        }
      });
    }
  }

  getTemplate(slotName, id) {
    let slot = this.slots[slotName];
    if (!slot) {
      slot = document.createElement('template');
    }
    slot.id = id;
    return slot;
  }

  emitEvent(eventName, eventData) {
    this.emit(eventName, eventData);
    if (this.element) {
      $(this.element).trigger(eventName, eventData);
    }
  }

  addSubscription(s) {
    this.subscriptions.push(s);
  }

  get ref() {
    return this._ref;
  }

  getSlot(nodes, slotName, templateId) {
    let tmp = nodes.find(
      (node) => node.dataset && node.dataset.slot == slotName
    );
    if (tmp) {
      if (typeof templateId === 'function') templateId(tmp);
      else tmp.id = templateId;
    }
    return tmp;
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.disposeCallbacks.forEach((fn) => fn());
    if (this.ref) {
      if (ko.isObservable(this.ref)) this.ref(null);
    }
  }

  onDispose(cb) {
    this.disposeCallbacks.push(cb);
  }
}
