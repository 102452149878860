import { Event } from '.';
const FoquzEvent = Event();

const PollActivateEvent = FoquzEvent.get('poll.activate');
const PollPublishEvent = FoquzEvent.get('poll.publish');
const PollStatsUpdateEvent = FoquzEvent.get('poll.stats.update');
const PollCreateEvent = FoquzEvent.get('poll.create');
const PollSettingsUpdateEvent = FoquzEvent.get('poll.settings.update');

export { PollActivateEvent, PollPublishEvent, PollStatsUpdateEvent, PollCreateEvent, PollSettingsUpdateEvent }
