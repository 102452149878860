import { ViewModel } from './model';
import html from './template.html';
import './style.less';

ko.components.register('foquz-confirm-modal', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;

      let model = new ViewModel(params, element);
      return model;
    },
  },
  template: html,
});
