ko.bindingHandlers.stopClick = {
  init: function (element) {
    const stopFoo = function (e) {
      e.stopPropagation();
    };
    element.addEventListener('click', stopFoo);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      element.removeEventListener('click', stopFoo);
    });
  }
};
