import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element);
    element.classList.add("fc-list");

    console.log('list', params)

    this.fields = {
      value: "id",
      text: "text",
    };

    if ("fields" in params) {
      Object.keys(this.fields).forEach((key) => {
        if (key in params.fields) this.fields[key] = params.fields[key];
      });
    }

    this.canRemove = params.remove;
    this.disableGroup = params.disableGroup;
    this.options = params.options;
    this.disabledOptions = params.disabledOptions;

    this.visibleOptions = ko.observableArray([]);
    let timer = null;
    const setOptions = (options) => {
      clearTimeout(timer);
      this.visibleOptions([]);
      let currentIndex = 0;
      const step = 25;

      const tick = () => {
        if (options.length - 1 >= currentIndex) {
          let slice = options.slice(currentIndex, currentIndex + step);
          this.visibleOptions([...this.visibleOptions(), ...slice]);
          currentIndex = currentIndex + step;
        }
        timer = setTimeout(tick);
      };

      tick();
    };
    setOptions(ko.toJS(this.options));
    this.options.subscribe((v) => {
      setOptions(v);
    });

    this.disabledLevel = params.disabledLevel;

    this.view = "view" in params ? params.view : null;
    if (!ko.isObservable(this.view)) this.view = ko.observable(this.view);

    this.value = params.value;

    this.activeCategories = params.activeCategories;

    element.classList.add('fc-list--active-categories');

    this.optionTemplateId = "fc-list-item-" + this._id;
    this.optionTemplate = this.getSlot(nodes, "item", this.optionTemplateId);

    this.emptyTemplateId = "fc-list-empty-" + this._id;
    this.emptyTemplate = this.getSlot(nodes, "empty", this.emptyTemplateId);
  }

  get events() {
    return ["select", "remove"];
  }

  select(option) {
    this.emitEvent("select", option);
  }

  remove(option) {
    this.emitEvent("remove", option);
  }

  onCheckboxChange(option, newValue) {
    if (newValue) this.select(option);
    else this.remove(option);
  }

  isOptionSelected(option) {
    let value = ko.toJS(this.value);
    if (Array.isArray(value)) return value.includes(option[this.fields.value]);
    return value == option[this.fields.value];
  }

  isOptionDisabled(option) {
    if ((this.disabledLevel || this.disabledLevel === 0) && option.level >= this.disabledLevel) return true;
    if (option.disabled && ko.toJS(option.disabled)) return true;

    if (this.disabledOptions && ko.isObservable(this.disabledOptions) && this.disabledOptions().includes(option.id)) return true;

    if (this.disableGroup && ko.toJS(option.parentDisabled)) return true;
    if (this.disableGroup && option.parentSelected) return true;

    if (this.canRemove) return false;
    if (option.selected) return true;

    return this.isOptionSelected(option);
  }
}
