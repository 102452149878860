const observable = ko.observable;
const applyBindingsToNode = ko.applyBindingsToNode;

export default function Ref(config = {}) {
  let element = observable(null);
  let _cbs = [];

  element.subscribe((v) => {
    if (v) {
      _cbs.forEach((cb) => cb(v));
      _cbs.length = 0;
    }
  });

  const addCb = (cb) => {
    if (element()) {
      cb(element());
    } else {
      _cbs.push(cb);
    }
  };

  const get = () => {
    return new Promise((res) => {
      addCb((el) => res(el));
    });
  };

  const on = (eName, cb) => {
    addCb((el) => {
      el.addEventListener(eName, cb);
    });
  };

  const off = (eName, cb) => {
    addCb((el) => {
      el.removeEventListener(eName, cb);
    });
  };

  const attr = (attrName, attrValue) => {
    addCb((el) => {
      el.setAttribute(attrName, attrValue);
    });
  };

  const removeAttr = (attrName) => {
    addCb((el) => {
      el.removeAttribute(attrName);
    });
  };

  const focus = () => {
    addCb((el) => el.focus());
  };

  const applyBindings = (bindings, viewModel) => {
    addCb((el) => {
      applyBindingsToNode(el, bindings, viewModel);
    });
  };

  const act = (cb) => {
    addCb((el) => cb(el));
  };

  const css = (cssObject) => {
    addCb((el) => {
      Object.values(cssObject).forEach(([key, value]) => {
        el.style[key] = value;
      });
    });
  };

  const scroll = (position) => {
    addCb(el => {
      if ('x' in position) {
        el.scollLeft = position.x;
      }
      if ('y' in position) {
        el.scollTop = position.y;
      }
    })

  }

  return {
    element,
    get,
    on,
    off,
    attr,
    removeAttr,
    focus,
    applyBindings,
    act,
    css,
    scroll,
  };
}
