/**
 * Иконка с состоянием открыто/закрыто
 *
 * open: true|false
 */

 import { ViewModel as model } from './model';
 import template from './template.html';
 import './style.less';

 import { registerComponent } from 'Utils/engine/register-component';

 registerComponent('fc-opener', { model, template });
