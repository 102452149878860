/**
 * Превью медиа-файла
 *
 * ...все параметры fc-media
 * remove: true|false
 * removeOnHover: true|false
 * loading: true|false
 * label
 */


import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-media-wrapper', { model, template });
