export function PageModel (ctx) {
  const modals = ko.observableArray([]);
  ctx.modalOpens = modals;

  window.modalOpens = modals;
  window.page = ctx;

  

  ctx.openModal = function (modalData) {
    modals.push(modalData);
  };

  ctx.confirm = function (data) {
    return new Promise((resolve) => {
      ctx.openModal({
        dialogTemplateName: 'confirm-modal-template',
        data: data,
        close: (response) => {
          if (response) resolve();
        },
      });
    });
  };

  ctx.getTab = function(defaultTab) {
    let sp = new URLSearchParams(window.location.search);
    return sp.get('tab' || defaultTab);
  }

  ctx.setTab = function(tabName) {
    let sp = new URLSearchParams(window.location.search);
    sp.set('tab', tabName);
    history.pushState(null, null, '?' + sp.toString());
  }
};
