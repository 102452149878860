/**
 * Img rating
 */

import { ViewModel as model } from "./model";
import template from "./template.html";
import "./style.less";

import { ViewModel as starModel } from "./star";
import starTemplate from "./star.template.html";

import { registerComponent } from "@/utils/engine/register-component";

registerComponent("fc-star-rating", { model, template });
registerComponent("fc-star-rating-item", {
  model: starModel,
  template: starTemplate,
});

import "./style.less";
