export function ViewModel(params, element, nodes) {
  element.classList.add("fc-load-button");

  const { onLoad, disabled, accept } = params;

  return {
    onLoad,
    disabled,
    accept,
    selectFiles: () => {
      const input = element.querySelector("input");
      if (input) input.click();
    },
    nodes
  };
}
