import { FComponent } from 'Components/f-component';


export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-success');

    this.show = params.show;
    const delay = params.delay || 4000;
    this.text = params.text || 'Сохранено успешно';

    this.show.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          if (this.show()) {
            this.show(false);
          }
        }, delay);
      }
    });

    let display = ko.computed(() => {
      return this.show() ? '' : 'none';
    });

    ko.applyBindingsToNode(element, {
      style: {
        display
      }
    })
  }
}
