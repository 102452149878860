export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.value = params.value;
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
