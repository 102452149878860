export class ViewModel {
  constructor(params, element) {
    this.value = params.value;

    this.isDisabled = params.isDisabled || ko.observable(false);

    this.min = 'min' in params ? params.min : -Infinity;
    this.max = 'max' in params ? params.max : Infinity;

    this.hasValue = ko.pureComputed(() => {
      return this.value() !== '';
    });

    this.numberValue = ko.pureComputed(() => {
      return parseInt(this.value()) || 0;
    });

    this.disableIncrement = ko.pureComputed(() => {
      return this.numberValue() >= this.max;
    });

    this.disableDecrement = ko.pureComputed(() => {
      return this.numberValue() <= this.min;
    });

    this.input = ko.observable(null);

    this.value.subscribe((_) => {
      console.log('update value');
      this.input() && $(this.input()).trigger('input');
    });
  }

  incrementValue() {
    if (this.isDisabled()) return false;
    if (this.disableIncrement()) return false;
    this.value(this.numberValue() + 1);
    this.input().update();
  }

  decrementValue() {
    if (this.isDisabled()) return false;
    if (this.disableDecrement()) return false;
    this.value(this.numberValue() - 1);
    this.input().update();
  }

  onRender() {
    if (this.input()) {
      this.input().update();
    } else {
      let s = this.input.subscribe((v) => {
        v.update();
        s.dispose();
      });
    }
  }
}
