export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.min = params.min;
    this.max = params.max;
    this.value = params.value;
    this.withValue = params.withValue;
    this.step = params.step;
    this.withIndicator = params.withIndicator;
    this.withHandle = params.withHandle === undefined ? true : params.withHandle;
    this.valueSuffix = params.valueSuffix || '';
    this.mode = params.mode;

    this.disabled = params.disabled;

  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
