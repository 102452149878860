let unique = 1;

export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.id = 'foquz-check-control-' + unique++;

    this.checked = params.checked;
    this.value = params.value;
    this.disabled = params.disabled;
    this.name = params.name;
    this.event = params.event;
    this.partially = params.partially;

    this.mode = params.mode;
    element.classList.add('foquz-check-control');
    if (this.mode) {
      element.classList.add('foquz-check-control--' + this.mode);
    }
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
