import { FComponent } from "Components/f-component";

const types = ["radio", "checkbox"];

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add("fc-check");

    let {
      type,
      checked,
      value,
      hint,
      label,
      disabled,
      onChange,
      partial,
      size,
      $raw,
      ...inputAttrs
    } = params;

    // console.log('skip params, element, nodes', params, element, nodes)
    this.type = types.includes(type) ? type : "checkbox";
    element.classList.add("fc-check--" + this.type);

    this.checked = checked;
    this.value = value;
    // console.log('skip this.checked, this.value', this.checked(), this.value)

    this.label = label;
    this.hint = hint;
    this.disabled = disabled;
    this.onChange = onChange;
    this.partial = partial;

    if (params.size) element.classList.add("fc-check--" + params.size);

    this.inputAttrs = {
      ...inputAttrs,
      type: this.type,
      checked: this.checked,
    };

    this.input = ko.observable(null);

    ko.applyBindingsToNode(element, {
      css: {
        "fc-check--checked": this.checked,
        "fc-check--partial": ko.computed(() => {
          return !this.checked() && ko.toJS(this.partial);
        }),
        "fc-check--disabled": this.disabled,
      },
    });
  }

  focus() {
    this.input().focus();
  }

  onInputChange() {
    if (ko.toJS(this.disabled)) return false;

    if (typeof this.onChange === "function") {
      this.onChange(!this.checked());
    } else {
      if (this.type === "radio") {
        this.checked(ko.toJS(this.value));
      } else {
        this.checked(!this.checked());
      }

      return true;
    }
  }
}
