function hexToRgbArray(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? parseInt(result[1], 16) +
        "," +
        parseInt(result[2], 16) +
        "," +
        parseInt(result[3], 16)
    : null;
}

function rgbStringToHex(rgbString) {
  console.log("rgbString", rgbString);

  if (/^#[0-9A-F]{6}$/i.test(rgbString)) {
    return rgbString;
  }
  const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (!match) {
    throw new Error("Неверный формат строки RGB.");
  }
  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);

  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function getGradient(config) {
  const start = hexToRgbArray(rgbStringToHex(config.startColor));
  const finish = hexToRgbArray(rgbStringToHex(config.endColor));
  console.log("getGradient config", config);
  let count = config.count - 1;

  const getPoint = (point) => {
    let x = start[0],
      y = start[1],
      z = start[2];

    if (point === count) {
      x = finish[0];
      y = finish[1];
      z = finish[2];
    } else if (point > 0) {
      const k = point / count / ((count - point) / point);
      x = (start[0] + k * finish[0]) / (1 + k);
      y = (start[1] + k * finish[1]) / (1 + k);
      z = (start[2] + k * finish[2]) / (1 + k);
    }

    return [x, y, z];
  };

  let gradient = [];
  for (let i = 0; i < config.count; i++) {
    gradient.push(getPoint(i));
  }

  return gradient;
}

export default {
  hexToRgb,
  getGradient,
};
