import { PollActivateEvent } from "Utils/events/poll";

ko.bindingHandlers.pollMenuItem = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {

    let cb = () => {
      if (element.hasAttribute('disabled')) {
        let link = element.querySelector('a');
        link.href = link.dataset.href;
        link.removeAttribute('data-href');
        element.removeAttribute('disabled');
      }
      PollActivateEvent.off(cb);
    };

    PollActivateEvent.on(cb);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      PollActivateEvent.off(cb);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
