export const icons = {};

function importAll(r) {
	r.keys().forEach((key) => {
		let spriteSymbol = r(key).default;
		let id = spriteSymbol.id;
		spriteSymbol._name = id.slice(11);
		icons[id] = spriteSymbol;
	});
}
importAll(require.context('../../../icons/', true, /\.svg$/));
