/**
 * Выбранные элементы селекта
 */

import { registerComponent } from "Utils/engine/register-component";
registerComponent("fc-select-result-item", {
  model: function (params = {}) {
    const { item } = params;
    this.text = item.resultText || item.text || "";
    this.id = item.id;

  },
  template: `<span class="fc-select-result-item" data-bind="html: text, attr: { 'data-id': id }"></span>`,
});
