import { FoquzModal } from "../foquz-modal/model";

export class FoquzModalPage extends FoquzModal {
  constructor(params, element) {
    super(params, element)

    this.$modal
      .addClass(['foquz-modal-page']);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        console.log('remove page')
      });
  }

}
