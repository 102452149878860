const MIN_COLUMN_HEIGHT = 34;

ko.bindingHandlers.matrix3DTable = {
  init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
    const resizeObserver = new ResizeObserver(() => {
      const tHeader = element.querySelector('.matrix-3d-table__desktop-header');
      const tRows = element.querySelectorAll('.matrix-3d-table__row');
      const tFooter = element.querySelector('.matrix-3d-table__row-footer');
      const tDesktopColumnHeaders = element.querySelectorAll('.matrix-3d-table__row-header');
      Array.from(tDesktopColumnHeaders).forEach((el, index) => {
        const referenceEl = index !== 0 ? tRows[index - 1] : tHeader;
        let columnHeight = referenceEl.offsetHeight ;
        if (columnHeight < MIN_COLUMN_HEIGHT) {
          referenceEl.style['min-height'] = `${MIN_COLUMN_HEIGHT}px`;
          columnHeight = `${MIN_COLUMN_HEIGHT}px`;
        }
        if (el.offsetHeight > referenceEl.offsetHeight) {
          if (tFooter?.offsetHeight > 0) {
            if (!referenceEl.querySelector('.matrix-3d__skip-check')) {
              referenceEl.style['min-height'] = `${el.offsetHeight - tFooter.offsetHeight}px`;
            } else {
              referenceEl.style['min-height'] = `${el.offsetHeight}px`;
            }
            
          } else {
            referenceEl.style['min-height'] = `${el.offsetHeight}px`;
          }
          
        } else {
          if (el.classList.contains('row-name')) {
            el.style['min-height'] = `${columnHeight + tFooter.offsetHeight}px`;
          } else if (el.classList.contains('skip-col')) {
            
            const skipHeight = el.offsetHeight;
            const skipElHeight = el.querySelector('.my-auto')?.offsetHeight
            /* headers.forEach(i => {
              i.style['min-height'] = `${columnHeight}px`;
            }) */
            if (document.body?.id == 'advantage') {
              el.style['min-height'] = `${columnHeight +1}px`;
            } else {
              el.style['min-height'] = `${columnHeight +1}px`;
            }
            
          } else {
            el.style['min-height'] = `${columnHeight}px`;
          }
          
        }
      });      
      const divElem = element.querySelector('.matrix-3d-table__wrapper');
      const wrapperWidth = divElem.offsetWidth;
      const availableCols = Math.floor(wrapperWidth / 140);
      const colsLength = divElem.querySelectorAll('.matrix-3d-table__desktop-header > .matrix-3d-table__header-item').length;
      Array.from(divElem.querySelectorAll('.matrix-3d-table__row-item > *')).forEach(el => {
        el.style.width = `${colsLength <= availableCols ? wrapperWidth / colsLength : 140}px`;
      })
      Array.from(divElem.querySelectorAll('.matrix-3d-table__desktop-header > *')).forEach(el => {
        el.style.width = `${colsLength <= availableCols ? wrapperWidth / colsLength : 140}px`;
      })
    });

    const bindingEventSub = ko.bindingEvent.subscribe(element, 'descendantsComplete', function () {
      const divElem = element.querySelector('.matrix-3d-table__wrapper');
      resizeObserver.observe(divElem);
    });
    bindingEventSub.disposeWhenNodeIsRemoved(element);
    // startPossiblyAsyncContentBinding is necessary for descendant bindings to notify us of their completion
    var innerBindingContext = ko.bindingEvent.startPossiblyAsyncContentBinding(element, bindingContext);

    ko.applyBindingsToDescendants(innerBindingContext, element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      resizeObserver.unobserve(
        element.querySelector('.matrix-3d-table__wrapper'),
      )
    });

    return { controlsDescendantBindings: true };
  },
};
