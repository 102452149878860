/**
 * Date picker
 *
 * value
 * single
 * clearable
 * disabled
 * readMode
 * invalid
 * showDropdowns
 * mobileView: mobile|null
 * inline: true|false
 */

 import { binding } from './binding';
import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbCalendar', binding);
registerComponent('fc-calendar', { model, template });
