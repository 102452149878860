import { sameWidth } from '../modifiers/same-width';

export const selectMode = {
  options: {
    placement: 'bottom-start',
    arrow: false,
    offset: [0, 0],
    maxWidth: 'none'
  },
  flipOptions: {
    fallbackPlacements: ['bottom-start', 'top-start']
  },
  modifiers: [sameWidth]
}
