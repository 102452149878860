ko.bindingHandlers.swiper = {
  init: function init(
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let config = valueAccessor() || {};

    ko.applyBindingsToNode(element, {
      descendantsComplete: () => {
        setTimeout(() => {
          const auto = !!config.slidesPerGroupAuto
          if (auto) {
            delete config.slidesPerGroupAuto;
          }
          let instance = new Swiper(element, config);
          let updateSlidesPerGroup = () => {
            const slideSize = instance.slidesGrid[1] || 0;
            const size = instance.size
            const slidesPerGroup = Math.floor((size / slideSize) || 0);
            instance.params.slidesPerGroup = slidesPerGroup;
            instance.update()
          }
          updateSlidesPerGroup = _.throttle(updateSlidesPerGroup, 200);
          if (auto) {
            updateSlidesPerGroup();
            const onResize = () => {
              if (element.isConnected) {
                updateSlidesPerGroup()
              } else {
                window.removeEventListener('resize', onResize)
              }
            }
            window.addEventListener('resize', onResize)
          }
          let ref = allBindings.get('ref');
          if (ko.isObservable(ref)) {
            ref(instance);
          }
        }, 200);
      }
    });
    ko.applyBindingsToDescendants(bindingContext, element);

    return { controlsDescendantBindings: true };
  }
};
