

import { FoquzComponent } from 'Models/foquz-component';
import { getHack } from 'Utils/hacks';
import { getToken } from "@/utils/api/config";

const token = getToken();

const langs = fetch('/lang', { mode: 'no-cors' })
  .then((response) => {
    if (!response.body) return [];
    return response.json();
  })
  .catch((e) => {
    console.error('fetch error', e);
  });

const langCache = {
  user: {},
  company: {}
};

window.foquzTest = window.foquzTest || {};
if (!('setLang' in window.foquzTest)) {
  window.foquzTest.setLang = (langSign) => {
    langs.then((langsList) => {
      let lang = langsList.find((l) => l.sign == langSign);
      if (!lang) {
        console.error(
          'Нет такого языка. Доступно: ',
          langsList.map((l) => l.sign)
        );
      } else if (token) {
        fetch(`/lang/user/${lang.id}/${token}`, { method: 'PUT' })
          .then(() => {
            location.reload();
          })
          .catch((e) => {
            console.error('fetch error', e);
          });
      }
    });
  };
}

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.loading = ko.observable(true);
    this.langs = ko.observableArray([]);

    this.mode = params.mode;
    this.id = params.id;
    this.view = params.view;
    this.activeLang = ko.observable(null);

    if (params.view === 'modal') {
      element.classList.add('lang-toggler--modal');
    }

    this.dropdown = ko.observable(null);

    let lang = this.getLang();

    langs.then((langsList) => {
      // langsList = langsList.filter((l) => l.sign == 'ru-RU');

      this.langs(langsList);
      lang.then((langSign) => {
        let currentLang = this.langs().find((l) => l.sign === langSign);
        this.activeLang(currentLang);
        this.loading(false);
      });
    });
  }

  getLang() {
    if (this.id in langCache[this.mode]) return langCache[this.mode][this.id];
    let langPromise = fetch(`/lang/${this.mode}/${this.id}`).then((response) =>
      response.json()
    );
    langCache[this.mode][this.id] = langPromise;
    return langPromise;
  }

  close() {
    $(this.dropdown())('toggle');
  }

  setLang(lang) {
    this.activeLang(lang);
    fetch(`/lang/${this.mode}/${lang.id}/${token}`, { method: 'PUT' }).then(
      () => {
        if (getHack('stopLangReload')) return;
        location.reload();
      }
    );
  }
}
