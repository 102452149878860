export const autoHeightBinding = {
  init: function (element, valueAccessor, allBindings) {
    element.classList.add('fb-auto-height');

    let defaultHeight = allBindings.get('height');

    if (defaultHeight === 'input') defaultHeight = 48;
    defaultHeight = defaultHeight || 72;

    if (defaultHeight <= 50) {
      element.classList.add('fb-auto-height--input');
    }

    let maxHeight = allBindings.get('maxHeight');
    if (maxHeight) element.style.maxHeight = maxHeight + 'px';
    else maxHeight = Infinity;

    element.style.minHeight = defaultHeight + 'px';

    let inputHandler = function () {

      element.style.height = defaultHeight + 'px';
      let height = element.scrollHeight;

      element.style.height = height + 'px';

      if (height <= 50) {
        element.classList.add('fb-auto-height--input');
      } else {
        element.classList.remove('fb-auto-height--input');
      }

      if (height > maxHeight) {
        element.style.overflowY = 'auto';
      } else {
        element.style.overflowY = 'hidden';
      }
    };

    element.style.overflowY = 'hidden';
    element.style.height = element.scrollHeight + 'px;';

    $(element).on('input change', inputHandler);

    // let ref = allBindings.get('ref');
    // if (ko.isObservable(ref)) {
    //   ref({
    //     update: () => $(element).trigger('input')
    //   });
    // }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('input', inputHandler);
    });

    setTimeout(() => {
      $(element).trigger('input');
    }, 4);
  }
};
