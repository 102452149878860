import { closeFancybox, openFancybox } from "../utils/fancybox";
import {openUnsupportedFormatModal} from "./fancyboxSupportedTemplate";

$.fancybox.defaults.btnTpl.rotate =
  '\n    <button data-fancybox-rotate class="fancybox-button fancybox-button--rotate" title="{{ROTATE}}">\n        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">\n            <path d="M16 2.03125V6.03125H12.0938"/>\n            <path d="M14.6002 10.3318C14.0946 11.7629 13.1376 12.9908 11.8734 13.8307C10.6092 14.6705 9.10633 15.0767 7.59119 14.988C6.07605 14.8994 4.63076 14.3207 3.47312 13.3392C2.31548 12.3577 1.5082 11.0265 1.17293 9.5463C0.837663 8.06607 0.992573 6.51697 1.61432 5.13243C2.23606 3.74789 3.29095 2.60294 4.62003 1.8701C5.94911 1.13726 7.48036 0.856237 8.98305 1.06938C10.4857 1.28253 11.8784 1.97829 12.9513 3.05182L14.875 5.375"/>\n        </svg>\n    </button>';

$.fancybox.defaults.lang = 'ru';

$.fancybox.defaults.i18n = {
  ru: {
    CLOSE: 'Закрыть',
    NEXT: 'Следующий',
    PREV: 'Предыдуший',
    ERROR:
      'Не удалось загрузить запрошенный контент. <br/> Пожалуйста, попробуйте повторить позднее.',
    PLAY_START: 'Запустить слайд-шоу',
    PLAY_STOP: 'Остановить слайд-шоу',
    FULL_SCREEN: 'На весь экран',
    THUMBS: 'Миниатюры',
    DOWNLOAD: 'Загрузить',
    SHARE: 'Опубликовать',
    ZOOM: 'Увеличить',
    ROTATE: 'Повернуть',
  },
};

$(document).on('click', '[data-fancybox-rotate]', function () {
  var slide = $.fancybox.getInstance().current;

  if (!slide.$image.data('isRotating')) {
    var currentRotateAngle = slide.$image.data('rotateAngle');
    var nextRotateAngle = currentRotateAngle + 90;

    $({ deg: currentRotateAngle }).animate(
      { deg: nextRotateAngle },
      {
        duration: 300,
        easing: 'swing',
        step: function step(nowRotateAngle) {
          slide.$image.css('transform', 'rotate(' + nowRotateAngle + 'deg)');
        },
        complete: function complete() {
          slide.$image.data('rotateAngle', nextRotateAngle);
          slide.$image.data('isRotating', false);
        }
      }
    );

    slide.$image.data('isRotating', true);
  }
});

var unsupportedFormats = ['heic', 'wmv', 'avi', 'flv', '3gp','amr'];

$.fancybox.defaults.onError = function(instance, slide) {
    if (slide.type === 'html' && slide.customErrorHandled) {
        return false;
    }
};

$(document).on('beforeShow.fb', function (e, instance, slide) {
  instance.$refs.bg.css('background', 'none');
  instance.$refs.container.css('background', 'none');

  var fileExtension = slide.src.split('.').pop().toLowerCase();

  if (slide.customErrorHandled) {
    instance.$refs.bg.css('background', '');
    instance.$refs.container.css('background', '');
    return false;
  }

  if (unsupportedFormats.includes(fileExtension)) {
    instance.close();
    openUnsupportedFormatModal(slide.src);
    return false;
  } else {
    instance.$refs.bg.css('background', '');
    instance.$refs.container.css('background', '');
    openFancybox();
    setTimeout(function () {
      if (slide.$image !== undefined) {
        slide.$image.css('transform', 'rotate(' + 0 + 'deg)');
        slide.$image.data('rotateAngle', 0);
        slide.$image.data('isRotating', false);
      }
    });
  }
});

$(document).on('beforeClose.fb', function (e, instance, slide) {
  closeFancybox()
});
