import { ViewModel } from './model';
import radioHtml from './template.html';
import './style.less';

ko.components.register('input-radio', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('input-radio');

      return new ViewModel(params, element);
    }
  },
  template: radioHtml
});
