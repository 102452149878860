export const token = window.APIConfig?.apiKey;
export const apiUrl = window.APIConfig?.baseApiUrlPath;

export function ApiUrl(url, params = {}) {
  params["access-token"] = window.APIConfig?.apiKey;
  return `${APIConfig.baseApiUrlPath}${url}?${$.param(params)}`;
}

export function getToken() {
  //return 'JgxxPo5TZdAHdDF';
  return window.APIConfig?.apiKey;
}

window.ApiUrl = ApiUrl;
