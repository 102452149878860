export function dateFromString(str, format = 'DD.MM.YYYY') {
  if (str instanceof Date) return str;

  if (format = 'DD.MM.YYYY') {
    let [d, m, y] = str.split('.');
    let date = new Date(`${y}-${m}-${d}`);
    date.setHours(0, 0, 0, 0);
    return date;
  }
}
