$.fn.inputFilter = function (inputFilter) {
  if (inputFilter == "dispose") {
    this.off(
      "input keydown keyup mousedown mouseup select contextmenu drop",
      this._filterFunction
    );
    this._filterFunction = null;
    return;
  }

  if (this._filterFunction) {
    this.off(
      "input keydown keyup mousedown mouseup select contextmenu drop",
      this._filterFunction
    );
    this._filterFunction = null;
  }

  let filterFunction = function () {
    if (inputFilter(this.value)) {
      this.oldValue = this.value;
      this.oldSelectionStart = this.selectionStart;
      this.oldSelectionEnd = this.selectionEnd;
    } else if (this.hasOwnProperty("oldValue")) {
      this.value = this.oldValue;
      this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      $(this).trigger("change");
    }
  };

  this._filterFunction = filterFunction;

  return this.on(
    "input keydown keyup mousedown mouseup select contextmenu drop",
    this._filterFunction
  );
};
