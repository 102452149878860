import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-progress');

    this.percent = ko.computed(() => {
      let value = ko.toJS(params.value);
      let total = ko.toJS(params.total);
      return Math.round(value / total * 100);
    });
  }
}
