import { FoquzComponent } from 'Models/foquz-component';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.status = params.status;
    if (!ko.isObservable(this.status)) this.status = ko.observable(this.status);
  }
}
