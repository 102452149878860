import { FComponent } from "@/components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    const { empty, thin, color } = params;

    this.empty = empty;
    this.thin = thin;
    this.color = color;
  }
}
