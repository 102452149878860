import { ViewModel, template } from './model';
import './mailing-name.less';
import {
  MailingActivateEvent,
  MailingToggleTypeEvent
} from 'Utils/events/mailing';

/** Редактирование названия рассылки */
class MailingNameViewModel extends ViewModel {
  constructor(params) {
    let folders = params.folders.map((f) => {
      return {
        ...f,
        link: '/foquz/mailings?id=' + f.id
      };
    });

    super(params.name, folders, (newValue) => {
      $.ajax({
        method: 'PUT',
        url:
          '/foquz/api/mailings/update?id=' +
          params.id +
          '&access-token=' +
          window.APIConfig.apiKey,
        data: {
          title: newValue
        },
        success: function () {
          MailingActivateEvent.emit({ id: params.id });
          if (typeof params.onEdit === 'function') params.onEdit(newValue);
        }
      });
    });

    this.mailing = params.mailing;
    this.mailingIsStopped = ko.observable(this.mailing.stopped);
    this.isNew = ko.observable(this.mailing.is_tmp);

    this.disabled(this.mailing.is_auto && this.mailing.is_tmp);

    if (this.isNew()) {
      MailingToggleTypeEvent.on((event) => {
        console.log('toggle type', event);
        if (event.id == this.mailing.id) {
          if (this.isNew()) {
            if (event.isAuto) this.disabled(true);
            else this.disabled(false);
          }
        }
      });
      MailingActivateEvent.on((event) => {
        if (event.id == this.mailing.id) {
          this.isNew(false);
          this.disabled(false);
        }
      });
    }
  }

  _request(stopped) {
    $.ajax({
      url: `${APIConfig.baseApiUrlPath}mailings/set-stopped?id=${this.mailing.id}&access-token=${APIConfig.apiKey}`,
      method: 'PUT',
      data: { stopped },
      success: (data) => {
        this.mailingIsStopped(data.item.stopped == 1);
      }
    });
  }

  stopMailing() {
    window.page
      .confirm({
        title: 'Пауза по рассылке',
        text: 'Рассылка будет остановлена',
        confirmText: 'Остановить',
        mode: 'success'
      })
      .then(() => {
        this._request(1);
      });
  }

  restartMailing() {
    window.page
      .confirm({
        title: 'Запуск рассылки',
        text: 'Рассылка будет запущена',
        confirmText: 'Запустить',
        mode: 'success'
      })
      .then(() => {
        this._request(0);
      });
  }
}

ko.components.register('mailing-name', {
  viewModel: MailingNameViewModel,
  template: `<div class="mailing-name"><table style="width: 100%; table-layout: fixed">
  <tr>
  <td>
    <div class="page-header">
    ${template}
    </div>

  </td>
  <!-- ko if: mailing.is_auto && !mailing.is_tmp -->
  <td align="right" width="200">
    <!-- ko if: mailingIsStopped -->
    <button class="f-btn f-btn--base f-btn-success" type="button"
      data-bind="click: restartMailing">
      <span class="f-btn-prepend">
        <span class="f-icon f-icon--start">
          <svg>
            <use href="#start-icon"></use>
          </svg>
        </span>
      </span>
      Возобновить рассылку
    </button>
    <!-- /ko -->

    <!-- ko ifnot: mailingIsStopped -->
    <button class="f-btn f-btn--base f-btn-danger" type="button"
      data-bind="click: stopMailing">
      <span class="f-btn-prepend">
        <span class="f-icon f-icon--stop">
          <svg>
            <use href="#stop-icon"></use>
          </svg>
        </span>
      </span>
      Остановить рассылку
    </button>
    <!-- /ko -->
  </td>
  <!-- /ko -->
  </tr>






  </table></div>`
});
