export function startOfWeek(date) {
  let startOfWeek = new Date(+date);
  let startDayOfWeek = startOfWeek.getDay();

  // понедельник
  if (startDayOfWeek === 1) return startOfWeek;

  let currentDate = startOfWeek.getDate()

  // воскресенье
  if (startDayOfWeek === 0) {
    startOfWeek.setDate(currentDate - 6);
    return startOfWeek;
  }

  // вторник-суббота
  startOfWeek.setDate(currentDate - startDayOfWeek + 1);
  return startOfWeek;
}
