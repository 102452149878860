export function isValidDate(value) {
  if (!value) return true;

  let format = 'DD.MM.YYYY';

  if (value.length !== format.length) return false;

  let date = moment(value, format);

  return date.isValid();
}
