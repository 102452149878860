import { ApiUrl } from "Utils/url/api-url";
import { createFileInput } from "Utils/file-loader/create-file-input";
import { DialogsModule } from "Utils/dialogs-module";
import "Dialogs/import-poll-dialog";
import { PollCreateEvent } from "../events/poll";
import "Dialogs/poll-folder/select-poll-folder-dialog";
import { Translator } from "@/utils/translate";
const pollsTranslator = Translator("polls");

function importPoll() {
  let control = {};
  DialogsModule(control);

  let poll = ko.observable(null);
  let loading = ko.observable(true);
  let error = ko.observable(null);

  const selectFile = (folderId) => {
    createFileInput(
      ({ file }) => {
        let fd = new FormData();
        fd.append("file", file);
        if (folderId) fd.append("folder_id", folderId);

        const url = folderId
          ? ApiUrl("poll/create-from-json", { folder_id: folderId })
          : ApiUrl("poll/create-from-json");

        control.openDialog({
          name: "import-poll-dialog",
          params: {
            loading,
            poll,
            error,
          },
        });

        $.ajax({
          url,
          method: "POST",
          processData: false,
          contentType: false,
          data: fd,
          success: (response) => {
            if (response.success) {
              poll({
                name: response.name,
                link: response.url,
              });
              PollCreateEvent.emit();
            } else {
              error(response.message || "При импорте опроса произошла ошибка, проверьте файл");
            }
            loading(false);
          },
          error: (response) => {
            console.error(response.responseJSON);
            error(response.responseJSON.message || "При импорте опроса произошла ошибка, проверьте файл");
            loading(false);
          },
        });
      },
      { open: true, accept: "application/JSON" }
    );
  };

  // test

  if (CURRENT_USER.editor) {
    control.openDialog({
      name: "select-poll-folder-dialog",
      params: {
        title: pollsTranslator.t("Выберите папку для импорта опроса"),
        confirm: pollsTranslator.t("Импортировать опрос"),
        mode: "success",
        onSelect: (folderId) => {
          selectFile(folderId);
          return Promise.resolve();
        },
      },
    });
  } else {
    selectFile();
  }
}

$(() => {
  let triggers = $("[data-import-poll]");
  triggers.each((i, el) => {
    $(el).click(importPoll);
  });
});
