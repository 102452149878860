import { DialogWrapper } from "Dialogs/wrapper";

const defaultTexts = {
  close: "Закрыть",
};

export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);

    this.texts = {
      title: ko.observable(""),
      text: ko.observable(""),
      close: ko.observable(""),
    };

    this.mode = ko.observable("default");
    this.view = ko.observable("");

    this.subscriptions.push(
      this.view.subscribe((v) => {
        element.setAttribute("data-view", v);
      })
    );

    this.update(params);
  }


  update(params = {}) {
    Object.keys(this.texts).forEach((key) => {
      let value = ko.toJS(params[key]);
      this.texts[key](value || _t(defaultTexts[key]) || "");
    });
    this.mode(params.mode || "default");
    this.view(params.view || "");
  }

  open(params) {
    return new Promise((res) => {
      this.update(params);
      this.show();

      this.modal().once("hide", () => {
        res();
      });
    });
  }
}
