/**
 * Двойной календарь для выбора периода
 * Все даты в формате 'DD.MM.YYYY'
 *
 * Параметры:
 *  - value - значение в формате { from: 'DD.MM.YYYY', to: 'DD.MM.YYYY' }
 *  - date {string} - отображаемый месяц (DD.MM.YYYY), число (DD) не важно
 *
 * События:
 *  - calendar-range-closed - диапазон закрыт (выбрана вторая дата)
 *  - calendar-range-changed - диапазон изменился
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from '@/utils/engine/register-component';

registerComponent('fc-period-calendar', { model, template });
