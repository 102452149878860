import { FComponent } from '@/components/f-component';

const colors = ['primary', 'secondary', 'danger', 'success', 'white', 'black'];
const sizes = ['sm', 'md', 'lg', 'xl', 'auto', 'full'];
const shapes = ['pill', 'circle', 'square'];

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-btn');

    this.linkMode = params.linkMode;
    this.linkAttrs = params.linkAttrs || {};
    this.stopPropagation = params.stopPropagation;

    this.label = params.label;

    this.icon = null;
    if (params.icon) {
      if (typeof params.icon === 'string')
        this.icon = { name: params.icon, size: 'md' };
      else this.icon = params.icon;
    }

    this.color = ko.computed(() => {
      let color = ko.toJS(params.color);
      if (colors.includes(color)) {
        return 'fc-btn-b--' + color;
      }
      return 'fc-btn-b--secondary';
    });

    this.size = ko.computed(() => {
      let size = ko.toJS(params.size);
      // TODO
      if (size === 'lg') size = 'md';
      if (sizes.includes(size)) {
        return 'fc-btn-b--' + size;
      }
      return 'fc-btn-b--md';
    });

    this.shape = ko.computed(() => {
      let shape = ko.toJS(params.shape);
      if (shapes.includes(shape)) {
        return 'fc-btn-b--' + shape;
      }
      return '';
    });

    this.inverse = ko.computed(() => {
      if (!params.inverse) return '';
      return 'fc-btn-b--inverse';
    });

    this.mode = ko.computed(() => {
      if (!params.mode) return '';
      return 'fc-btn-b--mode_' + params.mode;
    });

    this.css = ko.computed(() => {
      return `${this.size()} ${this.color()} ${this.shape()} ${this.mode()} ${this.inverse()}`;
    });

    this.pending = params.pending;
    this.disabled = params.disabled;
    this.inactive = params.inactive;

    this.hasDisabledClass = ko.computed(() => {
      return ko.toJS(this.disabled) || ko.toJS(this.inactive)
    })

    this.onClick = (_, e) => {
      if (ko.toJS(this.disabled)) return false;
      if (typeof params.click === 'function') params.click(e);
      if (this.linkMode && this.stopPropagation) {
        e.stopPropagation();
        window.location.href = this.linkAttrs().href;
        return false;
      }
      else return true;
    };

    ko.applyBindingsToNode(element, {
      css: {
        'fc-btn--block': params.block
      }
    });
  }
}
