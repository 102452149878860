/**
 * Группа радио-кнопок (горизонтальный блок)
 *
 * - value {string}
 * - options {Array<{ id: string|number|boolean, text: string }>}
 */

import { ViewModel as model } from "./model";
import template from "./template.html";
import "./style";

import { registerComponent } from "Utils/engine/register-component";

registerComponent("fc-radio-group", { model, template });
