/**
 * https://popper.js.org/docs/v2/
 */

import { createPopper } from "@popperjs/core";
import ee from "event-emitter";
import { getUnique } from "@/utils/number/unique";
import { HidePoppersEvent } from "@/utils/events/modal";

const collection = (() => {
  let poppers = [];

  let add = (p) => {
    poppers.push(p);
    if (poppers.length) document.body.setAttribute("data-popper-open", true);
  };

  let remove = (p) => {
    poppers = poppers.filter((popper) => popper !== p);
    if (!poppers.length) document.body.removeAttribute("data-popper-open");
  };

  return {
    add,
    remove,
  };
})();

export function bindPopper(element, popper, popperOptions = {}) {
  const popperInstance = createPopper(element, popper, popperOptions);

  let emitter = ee({
    unique: getUnique(),
  });

  let opened = ko.observable(false);
  let fixed = ko.observable(false);

  opened.subscribe((v) => {
    if (v) {
      collection.add(emitter);
    } else {
      collection.remove(emitter);
    }

    if (v) emitter.emit("show");
    else emitter.emit("hide");
  });

  function show(tmp) {
    popper.setAttribute("data-show", "");
    popperInstance.update();
    opened(true);
    setTimeout(() => {
      popperInstance.update();
    });
  }

  function hide(tmp) {
    fixed(false);
    popper.removeAttribute("data-show");
    opened(false);
  }

  function toggle() {
    if (fixed()) hide();
    else show();
  }

  emitter.element = element;
  emitter.popper = popperInstance;
  emitter.opened = opened;
  emitter.show = show;
  emitter.hide = hide;
  emitter.toggle = toggle;
  emitter.init = function (config = {}) {
    let cb = (e) => {
      if (popper.contains(e.target)) {
        if (!config.interactive) hide();
      } else if (element && element.contains(e.target)) {
      } else {
        hide();
      }
    };
    document.addEventListener("click", cb);
    if (!("control" in config) || !config.control) {
      // Открывать и закрывать по клику на элемент
      if (!("click" in config) || config.click) {
        let cb = (e) => {
          if (element && element.contains(e.target)) {
            toggle();
          }
        };
        document.addEventListener("click", cb);
      }

      // Открывать при наведении
      if (config.hover) {
        const showEvents = ["mouseenter", "focus"];
        const hideEvents = ["mouseleave", "blur"];

        showEvents.forEach((event) => {
          element.addEventListener(event, () => show("tmp"));
        });

        hideEvents.forEach((event) => {
          element.addEventListener(event, () => hide("tmp"));
        });
      }
    }

    HidePoppersEvent.on(() => {
      hide();
    });

    return this;
  };

  return emitter;
}
