import { ViewModel } from './model';
import './style';
import html from './template.html';

ko.components.register('file-loader-preview', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);

      $element.addClass('file-loader-preview');

      return new ViewModel(params, element);
    },
  },
  template: html,
});
