export function ViewModel(props, element) {
    var groupped = props.groupped, columns = props.columns, search = props.search, sortBy = props.sortBy, asc = props.asc, onSort = props.onSort, onSearch = props.onSearch, cellComponentName = props.cellComponentName, items = props.items;
    console.log({ props: props });
    return {
        groupped: groupped,
        columns: columns,
        onSort: onSort,
        onSearch: onSearch,
        cellComponentName: cellComponentName,
        items: items,
    };
}
