import { FoquzComponent } from "Models/foquz-component";

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.collection = params.collection;

    console.log("collection select", { params: ko.toJS(params) });

    this.value = params.value;
    this.except = params.except;
    this.exceptText = params.exceptText;
    this.exceptCount = params.exceptCount;
    this.allowUnset = "allowUnset" in params ? params.allowUnset : true;
    this.allowClear = params.allowClear;
    this.multiple = params.multiple;
    this.dense = params.dense;
    this.placeholder = params.placeholder;
    this.search = params.search;
    this.emptyOption = params.emptyOption;
    this.disabled = params.disabled;

    this.mode = params.mode;

    this.selectRef = ko.observable(null);

    this.config = {
      ajax: {
        transport: (params, success) => {
          let term = (params.data.term || "").toLowerCase();
          this.getFilteredOptions(term).then((data) => {
            success(data);
          });
        },
        processResults: function (data, params) {
          return {
            results: data,
          };
        },
      },
      templateResult: this.templater,
      templateSelection: this.templater,
      ...(params.selectConfig || {}),
    };

    if (!params.preventLoad) {
      this.collection.load();
    }
  }

  templater(state) {
    let span = $(`<span>${state.text}</span>`);
    if (state.className) {
      span.addClass(state.className);
    }
    if (state.divider) {
      span.addClass("divider");
    }
    return span;
  }

  filterItem(item, term) {
    return item.text.toLowerCase().includes(term);
  }

  getFilteredGroup(group, term) {
    return {
      ...group,
      children: group.children.filter((i) => this.filterItem(i, term)),
    };
  }

  getFilteredOptions(term) {
    let getDataPromise;
    if (!this.collection.loading()) {
      getDataPromise = Promise.resolve();
    } else {
      getDataPromise = new Promise((res) => {
        this.collection.once("load", () => {
          res();
        });
      });
    }

    return getDataPromise.then(() => {
      return this.collection.data
        .map((item) => {
          if ("children" in item) return this.getFilteredGroup(item, term);
          return item;
        })
        .filter((item) => {
          if ("children" in item) return item.children.length;
          return this.filterItem(item, term);
        });
    });
  }

  setDefaultData() {
    let $el = $(this.selectRef().element);

    const addOption = (id) => {
      if (!id) return;
      let element = this.collection.getById(id);
      if (!element) return;
      let newOption = new Option(element.text, element.id, false, true);
      $el.append(newOption);
    };

    if (this.multiple) {
      let values = this.value();
      values.forEach(addOption);
    } else {
      addOption(this.value());
    }

    $el.trigger("change");
    this.value(this.value());
  }

  onRender() {
    if (this.collection.loaded()) this.setDefaultData();
    else
      this.collection.once("load", () => {
        this.setDefaultData();
      });
  }
}
