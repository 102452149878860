import { FComponent } from "@/components/f-component";
import { bindPopper } from "./bindPopper";
import { arrowPositionModifier } from "./modifiers/arrow-position";
import { popperWidthModifier } from "./modifiers/popper-width";
import { getMobileWatcher } from "@/utils/window/mobile-watcher";
import { maxWidthModifier } from "./modifiers/max-width";
import { minWidthModifier } from "./modifiers/min-width";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add("fc-popper");

    let targetId = ko.toJS(params.target);
    let target = document.getElementById(targetId);

    let customOptions = params.options || {};

    this.hasArrow = true;
    if ("arrow" in customOptions && !customOptions.arrow) this.hasArrow = false;

    let popperOptions = (() => {
      let offset = "offset" in customOptions ? customOptions.offset : 10;
      let modifiers = [
        {
          name: "offset",
          options: { offset: [0, offset] },
        },
      ];

      if (!("flip" in customOptions) || customOptions.flip) {
        let options = {};
        if (customOptions.fallbackPlacements) {
          options.fallbackPlacements = customOptions.fallbackPlacements;
        }
        modifiers.push({
          name: "flip",
          options,
        });
      } else {
        modifiers.push({
          name: "flip",
          enabled: false,
        });
      }

      if ("width" in customOptions && customOptions.width) {
        modifiers.push(popperWidthModifier(customOptions.width));
      }

      if ("maxWidth" in customOptions && customOptions.maxWidth) {
        modifiers.push(maxWidthModifier(customOptions.maxWidth));
      }

      if ("minWidth" in customOptions && customOptions.minWidth) {
        modifiers.push(minWidthModifier(customOptions.minWidth));
      }

      if (this.hasArrow) {
        modifiers.push({
          name: "arrow",
          options: {
            element: ".fc-popper__arrow",
          },
        });

        if ("arrowPosition" in customOptions) {
          modifiers.push(arrowPositionModifier(customOptions.arrowPosition));
        }
      }

      return {
        placement:
          "placement" in customOptions ? customOptions.placement : "top",
        modifiers,
        
      };
    })();

    this.popper = bindPopper(target, this.createTooltip(element), popperOptions).init(
      params.behavior
    );

    this.isOpen = this.popper.opened;

    this.view = ko.observable();

    if (params.mobileView) {
      let isMobile = getMobileWatcher();
      if (isMobile()) this.view(params.mobileView);
      isMobile.subscribe((v) => {
        if (v) this.view(params.mobileView);
        else this.view(null);

        if (this.isOpen()) {
          this.hide();
        }
      });
      ko.applyBindingsToNode(element, {
        attr: {
          "data-view": this.view,
        },
      });
    }

    this.popper.on("show", () => {
      this.emitEvent("show");
      if (this.view() === "modal") this.blockScroll();
    });
    this.popper.on("hide", () => {
      this.emitEvent("hide");
      this.unblockScroll();
      if (ko.isObservable(params.show) && params.show()) {
        params.show(false);
      }
    });

    if (ko.isObservable(params.show)) {
      if (ko.toJS(params.show)) this.show();
      params.show.subscribe((v) => {
        if (v) this.show();
        else this.hide();
      });
    }
  }

  createTooltip(element) {
    let tooltip = document.createElement("div");

    tooltip.append(element);
    document.body.append(tooltip);
  
    return tooltip.firstElementChild;
  }

  blockScroll() {
    document.body.style.overflow = "hidden";
  }
  createTooltip(element) {
    let tooltip = document.createElement("div");

    tooltip.append(element);
    document.body.append(tooltip);
  
    return tooltip.firstElementChild;
  }

  unblockScroll() {
    document.body.style.overflow = "";
  }

  show() {
    this.popper.show();
  }

  hide() {
    this.popper.hide();
  }

  toggle() {
    this.popper.toggle();
  }

  fix() {
    this.popper.fix();
  }

  dispose() {
    super.dispose();
  }

  destroy() {}
}
