
export function createFileInput(cb, config = {}) {
  let input = document.createElement('input');

  input.type = 'file';
  input.accept = config.accept;

  input.multiple = config.multiple;

  input.style.position = 'absolute';
  input.style.visibility = 'hidden';
  input.style.opacity = 0;
  input.style.left = '-9999px';

  let onChange = () => {
    $(input).off('change', onChange);
    
    let file = input.multiple ? input.files : input.files[0];
    if (typeof cb == 'function') cb({ file: file, files: input.files })
    input.remove();
  };

  $(input).on('change', onChange);

  document.body.appendChild(input);

  if (config.open) {
    $(input).trigger('click');
  }

  return input;
}
