export class NameForm {
  constructor(params) {
    this.isEditing = ko.observable(false);

    this.name = ko.observable(ko.unwrap(params.name));
    this.folders = params.folders;
  }

  cancel() {
    this.isEditing(false);
  }

  save(newValue) {
    this.name(newValue);
    this.isEditing(false);
    this.afterSave(newValue);
  }
}
