import { ViewModel } from './model';
import html from './template.html';
import './style.less';

ko.components.register('date-picker', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);

      let additionalParams = {
        singleDatePicker: true,
        single: true,
        autoApply: true
      };

      return new ViewModel(params, additionalParams, element);
    }
  },
  template: html
});

ko.components.register('period-picker', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let $element = $(element);
      
      let additionalParams = {
        singleDatePicker: false
      };

      return new ViewModel(params, additionalParams, element);
    }
  },
  template: html
});
