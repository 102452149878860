import { PollActivateEvent, PollPublishEvent } from 'Utils/events/poll';
import { TEST_MODE_HINT } from '../../../constants/test-mode';
import { NameForm } from '../form';
import { Translator } from '@/utils/translate';

export class ViewModel extends NameForm {
  constructor(params) {
    let poll = params.poll;

    super({
      name: poll.name,
      folders: JSON.parse(poll.folders)
    });

    this.translator = Translator('poll');
    this.testModeText = TEST_MODE_HINT

    this.pollId = poll.id;
    this.isAuto = poll.isAuto;
    this.isNew = ko.observable(poll.isNew);
    this.isPublished = ko.observable(poll.isPublished);

    let now = moment();

    this.durationStartDate = null;
    this.durationFinishDate = null;

    this.isDurationAvailable = ko.observable(true);
    this.durationText = '';

    if (poll.datetime_start) {
      this.durationStartDate = moment(poll.datetime_start);

      if (this.durationStartDate.isAfter(now)) {
        let diff = this.durationStartDate - now;
        setTimeout(() => this.checkDuration(), diff);
      }
    }

    if (poll.datetime_end) {
      this.durationFinishDate = moment(poll.datetime_end);

      if (this.durationFinishDate.isAfter(now)) {
        let diff = this.durationFinishDate - now;
        setTimeout(() => this.checkDuration(), diff);
      }
    }

    let from, to;

    if (this.durationStartDate) {
      let date = this.durationStartDate.format('DD.MM.YYYY HH:mm');
      from = date;
    }
    if (this.durationFinishDate) {
      let date = this.durationFinishDate.format('DD.MM.YYYY HH:mm');
      to = date;
    }

    this.durationText = '';
    if (from && to) {
      this.durationText = this.translator.t(
        'Опрос доступен для прохождения с {from} до {to}',
        { from, to }
      );
    } else if (from) {
      this.durationText = this.translator.t(
        'Опрос доступен для прохождения с {from}',
        { from }
      );
    } else if (to) {
      this.durationText = this.translator.t(
        'Опрос доступен для прохождения до {to}',
        { to }
      );
    } else {
      this.durationText = this.translator.t('Опрос доступен для прохождения');
    }

    if (this.isAuto && this.isNew()) {
      PollActivateEvent.on((params) => {
        if (params.id == this.pollId) {
          this.isNew(false);
        }
      });
    }

    if (!this.isPublished()) {
      PollPublishEvent.on((params) => {
        if (params.id == this.pollId) {
          this.checkDuration();
          this.isPublished(true);
        }
      });
    }

    this.disabled = ko.computed(() => {
      return this.isAuto && this.isNew();
    });

    this.onEdit = params.onEdit;

    this.checkDuration();
  }

  checkDuration() {
    let now = moment();

    if (this.durationFinishDate && now.isAfter(this.durationFinishDate)) {
      // опрос кончился
      this.isDurationAvailable(false);
      return;
    }

    if (this.durationStartDate && now.isBefore(this.durationStartDate)) {
      // опрос еще не начался
      this.isDurationAvailable(false);
      return;
    }

    this.isDurationAvailable(true);
  }

  afterSave(newValue) {
    $.ajax({
      method: 'POST',
      url: `/foquz/foquz-poll/update?id=${this.pollId}`,
      data: {
        FoquzPoll: {
          name: newValue
        }
      },
      success: (response) => {
        PollActivateEvent.emit({ id: this.pollId });
        if (typeof this.onEdit == 'function') this.onEdit(newValue);
      }
    });
  }
}
