export function ViewModel(props) {
    var column = props.column, searchValue = props.searchValue, sortBy = props.sortBy, asc = props.asc, onSort = props.onSort, onSearch = props.onSearch;
    return {
        column: column,
        searchValue: searchValue,
        sortBy: sortBy,
        asc: asc,
        onClick: function () {
            if (column.sortable && typeof onSort === "function") {
                onSort();
            }
        },
        onSearch: function () {
            if (column.searchable && typeof onSearch === "function") {
                onSearch();
            }
        },
    };
}
