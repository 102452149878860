/**
 * https://www.daterangepicker.com/
 * // import 'daterangepicker/daterangepicker.css';
 *
 * Привязка календаря
 * обеспечивает связь между observable-значением и плагином календаря
 * устанавливает настройки календаря
 * регулирует переключение вида (выпадашка/модальное окно)
 *
 * Настройка календаря:
 *
 * showDropdowns
 * ranges true|false
 * opens: left|right|center
 * drops: up|down|auto
 * dropdownClass
 * single
 * parentEl
 * arrowPosition left|right|center|{ anchor, offset }
 * mobileView: modal|null
 *
 * Доп. биндинги
 *
 * value
 * ref
 *
 * События:
 *
 * fbcalendar.apply(value)
 */

import { getMobileWatcher } from 'Utils/window/mobile-watcher';
import { Calendar } from '../plugin';
import { getCalendarRanges } from './ranges';
import './style';

function getCalendarOptions(element, config) {
  let options = {
    opens: 'left',
    drop: 'auto',
    single: true,
    ...(config || {})
  };

  if (!options.single) {
    options.ranges = getCalendarRanges();
    options.alwaysShowCalendars = true;
  }

  options.autoApply = !!options.single;

  if (!('parentEl' in options)) {
    let parent = element.closest('[data-tooltip-container]');
    if (parent) {
      options.parentEl = parent;
    } else {
      options.parentEl = 'body';
    }
  }

  if ('arrowPosition' in options) {
    if (options.arrowPosition === 'left') {
      options.arrowPosition = {
        anchor: 'left',
        offset: -10
      };
    } else if (options.arrowPosition === 'right') {
      options.arrowPosition = {
        anchor: 'right',
        offset: -10
      };
    } else if (options.arrowPosition === 'center') {
      options.arrowPosition = {
        anchor: 'center',
        offset: 0
      };
    }
  } else {
    if (options.opens === 'left') {
      options.arrowPosition = {
        anchor: 'right',
        offset: -10
      };
    } else if (options.opens === 'right') {
      options.arrowPosition = {
        anchor: 'left',
        offset: -10
      };
    }
  }

  /** Классы выпадающего календаря */
  options.dropdownClass = ['fb-calendar', options.dropdownClass]
    .filter(Boolean)
    .join(' ');

  return options;
}

export const binding = {
  init: function init(element, valueAccessor, allBindings) {
    let $element = $(element);

    let $ref = allBindings.get('ref');
    let value = allBindings.get('value') || allBindings.get('textInput');

    let { mobileView, ...calendarOptions } = valueAccessor() || {};

    let options = getCalendarOptions(element, calendarOptions);

    const periodPicker = new Calendar(element, options);

    if (ko.isObservable($ref)) {
      $ref(periodPicker);
    }

    if (value !== undefined) {
      periodPicker.value(value());

      value.subscribe(function (v) {
        if (periodPicker.value() !== v) {
          $element.trigger(Calendar.events.forceUpdate);
        }
      });
    }

    $element.on(Calendar.events.apply, function () {
      var v = periodPicker.value();

      if (value !== undefined) {
        value(v);
      }

      $element.trigger('fbcalendar.apply', v);
    });

    if (mobileView) {
      let isMobile = getMobileWatcher();
      if (isMobile()) {
        periodPicker.setView(mobileView);
      }
      isMobile.subscribe((v) => {
        if (v) periodPicker.setView(mobileView);
        else periodPicker.setView(null);
      });
    }
  }
};

// TODO dispose
