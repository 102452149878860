function fallback(text, element) {
  return new Promise((res, rej) => {
    var input = document.createElement("input");
    input.value = text;
    input.style.position = "absolute";
    input.style.top = "0";
    input.style.left = "0";

    let parent = element ? element.parentElement : document.body;

    parent.appendChild(input);
    input.focus();
    input.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
      rej("Unable to copy text to clipboard", err);
    }

    input.remove();

    res();
  });
}

export function copyToClipboard(text, element) {
  return new Promise((res) => {
    if (!navigator.clipboard) {
      res(fallback(text, element));
      return;
    }

    navigator.clipboard.writeText(text).then(
      function () {
        res();
      },
      function (err) {
        res(fallback(text, element));
      }
    );
  });
}
