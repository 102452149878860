import tippy, { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import { modes } from './modes';
import './style.less';

let tmpUnique = 1;

ko.bindingHandlers.dropdown = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    const target = element.querySelector('[data-dropdown-target]') || element;

    let templateId = valueAccessor();

    if (!templateId) {
      let contentTemplate = element.querySelector('template');
      if (contentTemplate) {
        templateId = 'foquz-dropdown-template-' + tmpUnique++;
        contentTemplate.id = templateId;
      }
    }

    if (!templateId) return;

    let contentElement = document.createElement('div');
    contentElement.classList.add('tippy-wrapper');
    ko.renderTemplate(templateId, bindingContext, {}, contentElement);

    contentElement._context = bindingContext;

    const tippyOptions = allBindings.get('dropdownOptions') || {};

    const state = allBindings.get('dropdownState');

    const dropdownMode = allBindings.get('dropdownMode');
    const preset = modes[dropdownMode] || {};

    let placement = 'bottom';
    let fallbackPlacements = ['bottom', 'top'];

    let modifiers = [
      {
        name: 'flip',
        options: {
          fallbackPlacements,
          ...(preset.flipOptions || {})
        }
      },
      ...(preset.modifiers || [])
    ];

    let container = element.closest('[data-tooltip-container]');

    const options = {
      allowHTML: true,
      animation: 'scale',
      arrow: roundArrow,
      appendTo: container || document.body,
      content: contentElement,
      interactive: true,
      interactiveBorder: 0,
      interactiveDebounce: 30,
      popperOptions: {
        modifiers
      },
      onShow(ins) {
        ko.renderTemplate(templateId, bindingContext, {}, contentElement);
        ins.setContent(contentElement);
        if (ko.isObservable(state)) {
          state('opened');
        }
      },
      onHide() {
        if (ko.isObservable(state)) {
          state('closed');
        }
      },
      placement,
      trigger: 'click',
      zIndex: 999,
      ...(preset.options || {}),
      ...tippyOptions
    };

    const instance = tippy(target, options);

    let ref = allBindings.get('ref');
    if (ko.isObservable(ref)) {
      ref({
        update: () => {
          instance.popperInstance.update();
        },
        close: () => {
          instance.hide();
        }
      });
    }

    if (dropdownMode) {
      instance.popper.classList.add('tippy-mode--' + dropdownMode);
    }

    let dropdownClass = allBindings.get('dropdownClass');
    if (dropdownClass) {
      instance.popper.classList.add(dropdownClass);
    }

    $(instance.popper).on('click', '[data-dropdown-close]', hide);
    $(instance.popper).on('close', hide);

    ko.applyBindingsToNode({ tippy: instance }, contentElement);

    // закрыть при открытии модальных окон
    function hide() {
      instance.hide();
    }
    $('body').on('modal.open', hide);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $('body').off('modal.open', hide);
      $(instance.popper).off('click');
      $(instance.popper).off('close');
      instance.destroy();
      if (ko.isObservable(ref)) {
        ref(null);
      }
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
