export function isHTMLEmpty(value) {
  if (!value) return true;

  const el = document.createElement('div');
  el.innerHTML = value;
  if (el.querySelector('img')) return false;

  const text = el.textContent;
  return text.trim().length === 0;
}

export function snakeToCamel(s) {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
}
