import { ViewModel } from "./model";
import html from "./template.html";
import "./style.less";

ko.components.register("table-head-cell", {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      const element = componentInfo.element;
      element.classList.add("table-head-cell");

      return new ViewModel(params, element);
    },
  },
  template: html,
});
