/**
 * @class ModalWrapper
 * @classdesc Родительский класс для компонентов модальных окон
 * @property { Function } modal контроллер модального окна
 * @property { HTMLElement } wrapper обертка модального окна для передачи событий
 */

export class ModalWrapper {
  constructor(params = {}, element) {
    this.subscriptions = [];
    this.initializing = ko.observable(true);

    let modal = params.modal;
    if (modal && ko.isObservable(modal)) this.modal = modal;
    else this.modal = ko.observable(null);

    this.hide = params.hide;
    this.static = params.static;
    this.mask = params.mask;

    this._onClose = params.onClose;

    this.wrapper = element;

    this.isSingleModal = params.isSingleModal;
    this.isLastModal = params.isLastModal;
  }

  /**
   * Инициализация компонента после рендера
   */
  onRender() {
    let events = [
      'hidden.bs.modal',
      'hide.bs.modal',
      'show.bs.modal',
      'shown.bs.modal'
    ];
    let $modal = this.modal().$modal;
    let $wrapper = $(this.wrapper);

    events.forEach((eventName) => {
      $modal.on(eventName, () => {
        $wrapper.trigger(eventName);
      });
    });

    $modal.on('hide.bs.modal', () => {
      this.beforeClose();
    });

    $modal.on('hidden.bs.modal', () => {
      if (typeof this._onClose == 'function') this._onClose();
      this.afterClose();
    });

    this.initializing(false);
  }

  beforeClose() {

  }

  afterClose() {

  }

  /**
   * Закрытие модального окна
   */
  close() {

    this.modal().close();
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    //ko.cleanNode(this.wrapper)
    this.modal().dispose();
  }
}
