import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    element.classList.add('fc-error');

    let display = ko.computed(() => {
      if (ko.toJS(params.show)) return '';
      return 'none';
    });

    ko.applyBindingsToNode(element, {
      style: {
        display: display
      },
      text: params.text
    });
  }
}
