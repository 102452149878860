export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.minLimit = ko.computed(() => {
      return ko.isObservable(params.minLimit) ? params.minLimit() : params.minLimit;
    });

    this.maxLimit = ko.computed(() => {
      return ko.isObservable(params.maxLimit) ? params.maxLimit() : params.maxLimit;
    });
    this.range = params.range;
    this.disabled = params.disabled;
    this.withIndicator = params.withIndicator;
    if (this.withIndicator === undefined) {
      this.withIndicator = true
    }
    this.showLabels = params.showLabels;

    if (this.showLabels && element) element.classList.add('foquz-interval-slider--labels')
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
    this.minLimit.dispose();
    this.maxLimit.dispose();
  }
}
