export const minSameWidth = {
  name: "minSameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${
      state.elements.reference.offsetWidth
    }px`;
  }
}
