import { IMAGE_EXTENSIONS } from '../file-loader/exts';

export const isImage = (ext) => {
  return IMAGE_EXTENSIONS.find((e) => e == ext.toUpperCase());
};

export const linkToData = (link) => {
  let name = link.split('/').reverse()[0];
  let ext = name.split('.').reverse()[0];
  return {
    ext,
    name,
    link,
    isImage: isImage(ext)
  };
};

export const getSize = (bytes) => {
  if (bytes < 1024) return `${bytes} б`;
  let kBytes = Math.floor(bytes / 1024);
  if (kBytes < 1024) return `${kBytes} Кб`;
  let mBytes = Math.floor(kBytes / 1024);
  return `${mBytes} Мб`;
};
