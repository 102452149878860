import { FComponent } from 'Components/f-component';

const sizes = ['sm', 'lg'];

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-media');

    this.url = params.url;

    this.preview = ko.computed(() => {
      return ko.toJS(params.preview) || ko.toJS(params.url);
    });

    this.fancybox = 'fancybox' in params ? params.fancybox : true;
    this.gallery =
      params.gallery ||
      ko.computed(() => {
        return [{ src: ko.toJS(this.url) }];
      });

    this.index = params.index || ko.observable(0);
    this.alt = params.alt || '';

    let cover = 'cover' in params ? params.cover : true;
    if (cover) element.classList.add('fc-media--cover');

    if ('width' in params || 'height' in params) {
      element.style.width = params.width + 'px';
      element.style.height = params.height + 'px';
    } else if ('size' in params) {
      if (sizes.includes(params.size)) {
        element.classList.add('fc-media--' + params.size);
      } else {
        element.style.width = params.size + 'px';
        element.style.height = params.size + 'px';
      }
    } else {
      element.classList.add('fc-media--sm');
    }

    let bindings = {};
    if (this.fancybox) {
      bindings.fbFancybox = {
        gallery: this.gallery,
        index: this.index
      };
    }

    ko.applyBindingsToNode(element, bindings);
  }
}
