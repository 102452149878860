let unique = 1;

export class ViewModel {
  constructor(params, element) {
    this.unique = unique++;

    this.element = element;
    this.modals = params.modals;

    console.log('container', params)

    this.$outerContainer = null;

    /** Установить каждому модальному окну контроллер, если его нет */
    this.formattedModals = ko.pureComputed(() => {
      return this.modals().map((modal) => {
        if (!modal.params) modal.params = {};
        if (!modal.params.modal) modal.params.modal = ko.observable(null);
        return modal;
      });
    });

    this.closeAll = params.closeAll;
  }

  dispose() {
    console.log('dispose modals container', this.unique)
    $(this.$outerContainer).remove();
  }

  removeModal(modal) {
    this.modals.remove(modal);
    if (typeof modal.params.onClose == 'function') {
      modal.params.onClose();
    }
  }

  removeAll() {
    this.formattedModals().forEach((modal) => {
      modal.params.modal().close();
      if (typeof modal.params.onClose == 'function') {
        modal.params.onClose();
      }
    });
  }

  onRender() {
    this.$outerContainer = $(this.element).find('.foquz-modals-container');
    this.$outerContainer.appendTo('body');
  }
}
