/**
 * Img rating
 */


import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from '@/utils/engine/register-component';

registerComponent('fc-load-button', { model, template });



import './style.less';

