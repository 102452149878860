import { $directoriesStore } from '@/store/directories';

export class Directory {
  /**
   * @constructor
   * @param {string} url api method name
   * @param {Function} handleData response handler
   * @param {Object} params response params
   */
  constructor(url, handleData, params) {
    this.url = APIConfig.baseApiUrlPath + url;
    this.relativeUrl = url;
    this.data = ko.observableArray([]);
    this.loaded = ko.observable(false).extend({ notify: 'always' });
    this.loading = ko.observable(false);
    this.handleData = handleData;
    params = params || {};
    if (!params.dropToken) {
      params['access-token'] = APIConfig.apiKey;
    } else {
      delete params.dropToken;
    }
    this.params = params;
  }

  onLoad(cb) {
    if (this.loaded()) cb(this.data());
    else {
      this.loaded.subscribe((v) => {
        if (v) cb(this.data());
      });
    }
  }

  /**
   * Loads directories data
   */
  load(force) {
    //this.loaded(false);
    this.loading(true);
    let { directories } = $directoriesStore.get();
    if (directories[this.relativeUrl] === "loading") {
      const unbindListener = $directoriesStore.subscribe(data => {
        if (data.directories[this.relativeUrl] && data.directories[this.relativeUrl] !== "loading") {
          unbindListener();
          if (typeof this.handleData === 'function')
            this.data(this.handleData(data));
          else this.data(data.items);
          this.loading(false);
          this.loaded(true);
        }
      });
      return;
    }
    if (!directories[this.relativeUrl]) {
      directories[this.relativeUrl] = "loading";
    }

    if (!force && Directory[this.url]) {
      if (typeof this.handleData === 'function')
        this.data(this.handleData(Directory[this.url]));

      else {
        let items = Directory[this.url].items;
        this.data(items);
      }
      this.loading(false);
      this.loaded(true);

      return;
    }

    $.getJSON(this.url, this.params, (data) => {
      Directory[this.url] = data;
      directories[this.relativeUrl] = data;
      $directoriesStore.set({ directories });
      if (typeof this.handleData === 'function')
        this.data(this.handleData(data));
      else this.data(data.items);
      this.loading(false);
      this.loaded(true);
    });
  }

  /**
   * Finds directory item by id
   * @param {string|number} id
   */
  getById(id) {
    return Directory._getByField(this.data(), 'id', id);
  }

  sortByField(field = 'name') {
    return ko.pureComputed(() => {
      let data = [...this.data()];
      data.sort((a, b) => {
        if (typeof a[field] == 'number') return a[field] - b[field];
        else if (typeof a[field] == 'string') {
          if (a[field].toUpperCase() < b[field].toUpperCase()) return -1;
          else return 1;
        } else return -1;
      });
      return data;
    });
  }
}
Directory._getByField = function (items, fieldName, fieldValue) {
  const item = items.find((item) => {
    return '' + item[fieldName] === '' + fieldValue;
  });
  return item;
};
Directory.create = function (data) {
  data = ko.unwrap(data);
  return {
    loaded: ko.observable(true),
    load: () => {},
    data: ko.observableArray(data),
    getById(id) {
      return Directory._getByField(data, 'id', id);
    },
  };
};
