export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.isOpen = params.isOpen;
    this.color = params.color || null;
    this.width = params.width || null;
    this.height = params.height || null;
    this.size = params.size || null;
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
