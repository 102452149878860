export const conditionTypes = [
  { id: 'last-order-date', text: 'Дата последнего заказа' },
  { id: 'complaint-in-the-order', text: 'Наличие жалобы в заказе' },
  { id: 'avg-year-count-orders', text: 'Среднее количество заказов в год' },
  { id: 'avg-month-count-orders', text: 'Среднее количество заказов в месяц' },
  {
    id: 'order-amount-from-client-by-year',
    text: 'Выручка от заказов, сделанных контактом за год',
  },
  {
    id: 'order-amount-from-client-by-month',
    text: 'Выручка от заказов, сделанных контактом за месяц',
  },
  { id: 'avg-check-customer-orders', text: 'Средний чек по заказам контакта' },
  { id: 'main-orders-days', text: 'Основные дни заказов' },
  { id: 'favorite-order-time', text: 'Любимое время заказа' },
  { id: 'favorite-dish', text: 'Любимое блюдо' },
  { id: 'polls-participation', text: 'Участие в опросах, %' },
  { id: 'order-type', text: 'Тип заказа' },
  { id: 'source-type', text: 'Способ оформления' },
  { id: 'filial', text: 'Филиал' },
  { id: 'codes-percent', text: 'Процент использованных промокодов (от выданных)' },
  { id: 'promocode', text: 'Промокод' },
  { id: 'contact-data', text: 'Данные контакта' },
];
