import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add("fc-expander");

    this.open = params.open;
    this.disabled = params.disabled;
    this.onChange = params.onChange;

    if (ko.toJS(this.open)) element.classList.add("fc-expander--open");

    this.open.subscribe((v) => {
      element.classList.toggle("fc-expander--open", v);
    });
  }

  toggle() {
    if (typeof this.onChange === "function") {
      this.onChange(!this.open());
      return;
    }
    this.open(!this.open());
  }
}
