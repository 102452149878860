export function ViewModel(name, folders, onSave) {
  this.isEditing = ko.observable(false);
  this.name = name;
  this.folders = folders;

  this.disabled = ko.observable(false);

  this.save = (newValue) => {
    this.name(newValue);
    this.isEditing(false);

    onSave(this.name());
  };

  this.cancel = () => {
    this.isEditing(false);
  };

  $('body').on('before.print', () => {
    this.cancel();
  });
}

export const template = `
  <div class="breadcrumbs page-header__breadcrumbs">

    <!-- ko foreach: folders -->
      <a class="breadcrumbs__item" data-bind="text: $data.name, attr: { href: $data.link }"></a>
    <!-- /ko -->

    <a class="breadcrumbs__item">
      <!-- ko if: !isEditing() -->
        <!-- ko text: name() -->
        <!-- /ko -->
      <!-- /ko -->

      <!-- ko if: isEditing() -->
        <div data-bind="component: { name: 'header-name-form', params: {
            value: name(),
            save: save,
            cancel: cancel,
        }}">
        </div>
      <!-- /ko -->

      <!-- ko if: !isEditing() -->
        <button class="btn btn-icon btn-default btn-default btn-icon-edit ml-2"
                type="button"
                title="Редактировать название"
                data-bind="click: function() { isEditing(true); }, disable: disabled, tooltip">
        </button>

        <!--<button class="btn btn-icon btn-default btn-default btn-icon-copy ml-2"
                type="button"
                title=""
                data-bind="click: function() {}">
        </button>-->
      <!-- /ko -->
    </a>
  </div>


  <!-- ko template: { nodes: $componentTemplateNodes } -->
  <!-- /ko -->
`;
