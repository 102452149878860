import { FComponent } from "@/components/f-component";

import { ranges } from "./ranges";
import { isValidDate } from "@/utils/date/is-valid-date";
import { isValidPeriod } from "@/utils/date/is-valid-period";

import { Translator } from "@/utils/translate";

import "@/presentation/components/fc-period-calendar";

const FREE_RANGE_ID = "free";

const PERIOD_APPLY = "period-apply";
const PERIOD_RESET = "period-reset";

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);
    element.classList.add("fc-period-picker");

    this.translator = Translator("calendar");

    // refs
    this.popper = ko.observable(null);
    this.input = ko.observable(null);
    this.calendar = ko.observable(null);

    // settings
    this.placeholder = params.placeholder || "00.00.0000-00.00.0000";
    this.clearable = params.clearable;
    this.readMode = params.readMode;
    this.inline = params.inline;
    this.disabled = ko.computed(() => !!ko.toJS(params.disabled));

    // ошибки, валидация
    this.invalid = ko.computed(() => !!ko.toJS(params.invalid));
    this.valid = params.valid;
    this.error = ko.computed(() => ko.toJS(params.error));

    // диапазоны
    this.ranges = params.ranges;
    this.currentRange = ko.observable(FREE_RANGE_ID);

    this.rangesList = ko.computed(() => {
      if (!this.translator.loaded()) return [];
      return ranges.map((r) => {
        return {
          ...r,
          name: this.translator.t(r.name)(),
        };
      });
    });

    if (this.inline) element.classList.add("fc-date-picker--inline");

    this.validatePeriod = isValidDate;
    this.validateDate = isValidPeriod;

    // текущее незафиксированное значение
    this.currentValue = ko.observable({
      from: "",
      to: "",
      range: FREE_RANGE_ID,
    });

    // строчное представление currentValue
    this.valueString = ko.computed(() => {
      let value = this.currentValue();
      return this.getPeriodString(value);
    });

    // зафиксированное валидное значение
    this.correctValue = ko.observable({
      from: "",
      to: "",
      range: FREE_RANGE_ID,
    });
    this.currentValue.subscribe((v) => {
      if (this.validateDate(v.from) && this.validateDate(v.to)) {
        this.correctValue(v);
        this.calendar() && this.calendar().sync();
      }
    });

    // значение в текстовом поле (дата, период, rangeId)
    this.fieldValue = ko.observable("").extend({
      validation: {
        validator: (v) => {
          if (!v) return true;
          if (this.ranges) {
            let range = this.rangesList().find((r) => r.name === v);
            if (range) return true;
          }

          if (this.validatePeriod(v)) return true;
          if (this.validateDate(v)) return true;
        },
      },
    });

    // изменение строки
    let fieldValueCb = this.fieldValue.subscribe((newValue) => {
      if (this.inline) {
        setTimeout(() => {
          this.input().resize();
        });
      }

      if (newValue === this.valueString()) return;

      let value = this.getPeriodObject(newValue);

      this.updateValue(value);

      this.emitEvent(PERIOD_APPLY);
    });

    this.onDispose(() => {
      fieldValueCb.dispose();
    });

    // событие apply, обновление значения
    let onApply = () => {
      if (ko.isObservable(params.value)) {
        let currentValue = ko.toJS(params.value);
        let newValue = ko.toJS(this.currentValue());

        if (!this.isSameValue(currentValue, newValue)) params.value(newValue);
      }
    };
    this.on(PERIOD_APPLY, onApply);
    this.onDispose(() => {
      this.off("apply", onApply);
    });

    // изменение значения извне
    if (ko.isObservable(params.value)) {
      let valueCb = params.value.subscribe((v) => {
        if (!this.isSameValue(v, this.currentValue())) {
          this.updateValue(v, "updateField");
          this.emitEvent(PERIOD_APPLY);
        }
      });

      this.onDispose(() => {
        valueCb.dispose();
      });
    }

    // установка начального значения при создании
    if (params.value) {
      this.updateValue(ko.toJS(params.value), "updateField");
      this.emitEvent(PERIOD_APPLY);
    }
  }

  isSameValue(currentValue, newValue) {
    if (!currentValue && !newValue) return true;
    if (!currentValue || !newValue) return false;
    return ["from", "to", "range"].every(
      (field) => currentValue[field] === newValue[field]
    );
  }

  /**
   * Обновляет значение выбранного диапазона
   * @param value {Object.<from,to,range>}
   * @param updateField {Boolean} - нужно ли обновлять текстовое поле
   */
  updateValue(value, updateField) {
    let newValue = {
      from: "",
      to: "",
      range: FREE_RANGE_ID,
    };

    if (value) {
      let range = null;
      if (this.ranges && value.range && value.range !== FREE_RANGE_ID) {
        range = this.rangesList().find((r) => r.id === value.range);
      }

      if (range) {
        let { from, to } = range.getRange();
        newValue.from = from;
        newValue.to = to;
        newValue.range = range.id;
      } else {
        newValue.from = value.from;
        newValue.to = value.to;
      }
    }

    this.currentRange(newValue.range);
    this.currentValue(newValue);

    if (updateField) this.fieldValue(this.getPeriodString(newValue));
  }

  /** Строчное представление выбранного периода */
  getPeriodString(period = {}) {
    let { from, to, range } = period;

    if (this.ranges) {
      let rangeData = null;
      if (range && range !== FREE_RANGE_ID) {
        rangeData = this.rangesList().find((r) => r.id === range);
      }
      if (rangeData) return rangeData.name;
    }

    if (from && to) {
      if (from === to) return from;
      return `${from}-${to}`;
    }
    if (from) return from;
    return "";
  }

  /** Превращает введенное в поле значение в объект периода */
  getPeriodObject(periodString = "") {
    if (!periodString) {
      return {
        from: "",
        to: "",
        range: FREE_RANGE_ID,
      };
    }

    if (this.ranges) {
      let range = this.rangesList().find((r) => r.name === periodString);
      if (range) {
        let { from, to } = range.getRange();
        return {
          from,
          to,
          range: range.id,
        };
      }
    }

    let [from, to] = periodString.split("-");
    if (!from) from = "";
    if (!to) to = from;
    return {
      from,
      to,
      range: FREE_RANGE_ID,
    };
  }

  /**
   * Фокусировка на поле ввода открывает календарь
   */
  focus() {
    if (this.disabled()) return;
    this.popper().show();
  }

  /**
   * Кнопка Сбросить
   */
  reset() {
    this.emitEvent(PERIOD_RESET);
    this.popper().hide();
  }

  /**
   * Кнопка Сохранить
   */
  apply() {
    let { from, to } = this.calendar().getValue();
    this.updateValue({ from, to, range: this.currentRange() }, "updateField");
    this.emitEvent(PERIOD_APPLY);
    this.popper().hide();
  }

  /**
   * Выбор диапазона из списка
   */
  setRange(range) {
    let { from, to } = range.getRange();
    this.calendar().setValue({ from, to });
    this.currentRange(range.id);
  }

  /**
   * При любом изменении в календаре сбросить выбранный ранее готовый диапазон
   */
  onCalendarChange() {
    this.currentRange(FREE_RANGE_ID);
  }
}
