export class ViewModel {
  constructor(params, element) {
    this.loading = params.loading;
    this.needText = params.needText || false;
    this.file = ko.observable(null)
    this.preview = params.preview;
    if (params.file && typeof params.file === 'function') {
      this.file = params.file
    } else if (params.file) {
      this.file(params.file)
    }

    const audioExt = ['mp3', 'ogg', 'wav', 'm4a', 'aac']

    
    this.gallery = ko.pureComputed(() => {
      if (params.gallery) return ko.toJS(params.gallery);
      let preview
      if (typeof this.preview === 'function') {
        preview = this.preview()
      } else {
        preview = this.preview
      }
      const res = { src: preview }
      
      if (this.file) {
        
        if (this.file.fileUrl) {
          res.src = this.file.fileUrl
        } else if (typeof this.file === 'function' && this.file().fileUrl) {
          res.src = this.file().fileUrl
        } else {
          if (typeof this.file === 'function') {
            res.src = this.file()
          } else {
            res.src = this.file
          }
        }

        const urlArr = res.src.split('.')
        
        if (audioExt.includes(urlArr[urlArr.length - 1].toLowerCase())) {
          res.type = 'inline',
          res.src = `<audio class="fs-file-audio-procces" controls src="${res.src}"></audio>`
          res.height = 313
        }
      }
      return res;
    });

    this.index = params.index;
    this.onRemove = params.onRemove;
    this.disabled = params.disabled;
    this.video = ko.pureComputed(() => {
      const videoExt = ['MP4', 'WMV', 'MOV', '3GP', 'FLV', 'MPEG-1', 'MPEG-2', 'WEBM', 'AVI']
      if (this.file && typeof this.file === 'function' && this.file()) {

        const urlArr = this.file().fileUrl?.split('.') || this.file().file_url?.split('.') || this.file().split('.')
        if (videoExt.includes(urlArr[urlArr.length - 1].toUpperCase())) {
          
          /* res.type = 'inline',
          res.src = `<audio class="fs-file-audio" controls src="${this.file.fileUrl}"></audio>`
          res.mainClass = "fs-file-audio-container" */
          return true
        }
      };
      if (this.file && typeof this.file !== 'function') {
        


        const urlArr = this.file.fileUrl?.split('.') || this.file.split('.')
        if (videoExt.includes(urlArr[urlArr.length - 1].toUpperCase())) {
          
          /* res.type = 'inline',
          res.src = `<audio class="fs-file-audio" controls src="${this.file.fileUrl}"></audio>`
          res.mainClass = "fs-file-audio-container" */
          return true
        }
      };
      return false;
    });

    this.backgroundImage = ko.pureComputed(() => {
      if (typeof this.preview === 'function' && this.preview()) return `url(${this.preview()})`;
      if (typeof this.preview !== 'function' && this.preview) return `url(${this.preview})`;
      return 'none';
    });
  }

  remove() {
    if (typeof this.onRemove == 'function') this.onRemove();
  }
}
