export function minWidthModifier(width) {
  return {
    name: 'minWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      if (width === 'equal') state.styles.popper.minWidth = `${state.rects.reference.width}px`;
      else state.styles.popper.minWidth = `${width}px`
    },
    effect: ({ state }) => {
      if (width === 'equal') state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
      else state.elements.popper.style.minWidth = `${width}px`
    }
  };
}
