import "./style.less";

export function openUnsupportedFormatModal(url) {
    $.fancybox.open({
        src: `
      <div class="custom-fancybox__not-supported">
        <div class="custom-fancybox__not-supported-inner">
          <p class="custom-fancybox__not-supported-title">Не удается воспроизвести файл. Формат не поддерживается.</p>
          <a class="custom-fancybox__not-supported-link" href="${url}" download>
            <svg class="custom-fancybox__not-supported-link-icon" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5 10H1.5M6.5 1V7M6.5 7L8.5 5M6.5 7L4.5 5" stroke="#73808D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="custom-fancybox__not-supported-link-text">Скачать</span>
          </a>
        </div>
      </div>
    `,
        type: 'html',
        customErrorHandled: true,
        buttons: ['close'],
    });
}