ko.bindingHandlers.fadeVisible = {
  update: function(element, valueAccessor, allBindings) {
    // First get the latest data that we're bound to
    const value = valueAccessor();
    // Next, whether or not the supplied model property is observable, get its current value
    const valueUnwrapped = ko.unwrap(value);
    // Grab some more data from another binding property
    const duration = allBindings.get('fadeDuration') || 400; // 400ms is default duration unless otherwise specified
    // Now manipulate the DOM element
    if (valueUnwrapped == true) {
      $(element).fadeIn(duration); // Make the element visible
    } else {
      $(element).fadeOut(duration); // Make the element invisible
    }
  }
};
