import { FoquzComponent } from 'Models/foquz-component';
import { linkToData } from 'Utils/file';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    let files = params.files.map((file) => linkToData(file));

    this.photos = files.filter((f) => f.isImage);
    this.gallery = this.photos.map((f) => ({ src: f.link }));
    this.files = files.filter((f) => !f.isImage);
  }
}
