/**
 * Поле выбора даты с выпадающим календарем (fc-input + fc-popper + fc-month-calendar)
 *
 * Параметры:
 *  - value {string} - выбранная дата (DD.MM.YYYY)
 *  - placeholder {string} - плейсхолдер в поле
 *  - clearable {Boolean} - возможность сброса
 *  - readMode {Boolean} - отображение только для чтения
 *  - inline {Boolean} - стиль оформления
 *  - invalid {Boolean} - невалидное значение (оформление)
 *  - valid {Boolean} - валидное значение (оформление)
 *  - error {string} - текст ошибки (если invalid: true)
 *  - disabled {Boolean} - заблокировать контрол
 *  - dropdowns {Boolean} - стиль контролов в выпадающем календаре
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-date-picker', {
  model,
  template
});
