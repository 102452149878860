window.periodPickerConfig = {
  ranges: {
    [_t('Сегодня')]: [moment(), moment()],
    [_t('С начала недели')]: [moment().startOf('isoWeek'), moment()],
    [_t('Прошлая неделя')]: [
      moment().subtract(1, 'weeks').startOf('isoWeek'),
      moment().subtract(1, 'weeks').endOf('isoWeek')
    ],
    [_t('Последние 7 дней')]: [moment().subtract(6, 'days'), moment()],
    [_t('Текущий месяц')]: [moment().startOf('month'), moment()],
    [_t('Прошлый месяц')]: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ],
    [_t('Последний месяц')]: [moment().subtract(29, 'days'), moment()]
  },
  validation: {
    validator: function validator(value) {
      if (periodPickerConfig.ranges[value]) return true;
      var values = value.split('-');
      if (values.length > 1) {
        var from = moment(values[0], 'DD.MM.YYYY'),
          to = moment(values[1], 'DD.MM.YYYY');
        if (
          from.format() !== 'Invalid date' &&
          to.format() !== 'Invalid date'
        ) {
          if (from.format() > to.format()) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        var indexOfDot = values[0].indexOf('.'),
          dotsCount = (values[0].match(RegExp('.', 'g')) || []).length,
          formattedDate = moment(values[0], 'DD.MM.YYYY').format();

        if (
          !values[0].length ||
          (indexOfDot !== -1 &&
            indexOfDot === 2 &&
            dotsCount >= 3 &&
            formattedDate !== 'Invalid date')
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    message: _t('Некорректный формат интервала')
  },
  getValue: function (value, format) {
    format = format || 'DD.MM.YYYY';
    if (periodPickerConfig.ranges[value]) {
      let period = periodPickerConfig.ranges[value];
      return period
        .map(function (d) {
          return d.format(format);
        })
        .join('-');
    }

    return value;
  },
  getRange: function (value, format) {
    format = format || 'DD.MM.YYYY';
    let rangeName = Object.keys(periodPickerConfig.ranges).find(function (
      name
    ) {
      let range = periodPickerConfig.ranges[name]
        .map(function (d) {
          return d.format(format);
        })
        .join('-');
      return range == value;
    });
    return rangeName || value;
  }
};

window.translator.load('main').then(() => {
  window.periodPickerConfig = {
    ranges: {
      [_t('Сегодня')]: [moment(), moment()],
      [_t('С начала недели')]: [moment().startOf('isoWeek'), moment()],
      [_t('Прошлая неделя')]: [
        moment().subtract(1, 'weeks').startOf('isoWeek'),
        moment().subtract(1, 'weeks').endOf('isoWeek')
      ],
      [_t('Последние 7 дней')]: [moment().subtract(6, 'days'), moment()],
      [_t('Текущий месяц')]: [moment().startOf('month'), moment()],
      [_t('Прошлый месяц')]: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ],
      [_t('Последний месяц')]: [moment().subtract(29, 'days'), moment()]
    },
    validation: {
      validator: function validator(value) {
        if (periodPickerConfig.ranges[value]) return true;
        var values = value.split('-');
        if (values.length > 1) {
          var from = moment(values[0], 'DD.MM.YYYY'),
            to = moment(values[1], 'DD.MM.YYYY');
          if (
            from.format() !== 'Invalid date' &&
            to.format() !== 'Invalid date'
          ) {
            if (from.format() > to.format()) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          var indexOfDot = values[0].indexOf('.'),
            dotsCount = (values[0].match(RegExp('.', 'g')) || []).length,
            formattedDate = moment(values[0], 'DD.MM.YYYY').format();

          if (
            !values[0].length ||
            (indexOfDot !== -1 &&
              indexOfDot === 2 &&
              dotsCount >= 3 &&
              formattedDate !== 'Invalid date')
          ) {
            return true;
          } else {
            return false;
          }
        }
      },
      message: _t('Некорректный формат интервала')
    },
    getValue: function (value, format) {
      format = format || 'DD.MM.YYYY';
      if (periodPickerConfig.ranges[value]) {
        let period = periodPickerConfig.ranges[value];
        return period
          .map(function (d) {
            return d.format(format);
          })
          .join('-');
      }

      return value;
    },
    getRange: function (value, format) {
      format = format || 'DD.MM.YYYY';
      let rangeName = Object.keys(periodPickerConfig.ranges).find(function (
        name
      ) {
        let range = periodPickerConfig.ranges[name]
          .map(function (d) {
            return d.format(format);
          })
          .join('-');
        return range == value;
      });
      return rangeName || value;
    }
  };

  (function ($, window, document, undefined) {
    var inputGroupTemplate =
      '\n            <div class="input-group date-input-group">\n                <input class="form-control" placeholder="00.00.0000">\n                <i class="date-input-group__icon"></i>\n            </div>\n        '.trim();

    var rangeContainerTemplate =
      '<div class="period-picker__range-container"></div>';
    var rangeTemplate = '<div class="period-picker__range"></div>';
    var arrowTemplate = '<div class="period-picker__arrow"></div>';

    var PeriodPicker = (function (_BasePlugin) {
      _inherits(PeriodPicker, _BasePlugin);

      function PeriodPicker(element, options) {
        _classCallCheck(this, PeriodPicker);

        var _options = $.extend(
          {
            opens: 'right',
            drops: 'down',
            separator: ' - ',
            customRangeLabel: _t('Произвольный'),
            single: false,
            showDropdowns: false,
            arrowPosition: {
              anchor: 'center',
              offset: 0
            },
          },
          options
        );

        return _possibleConstructorReturn(
          this,
          (PeriodPicker.__proto__ || Object.getPrototypeOf(PeriodPicker)).call(
            this,
            element,
            _options
          )
        );
      }

      _createClass(PeriodPicker, [
        {
          key: 'init',
          value: function init() {
            var _this2 = this;

            this._selectedRangeLabel = null;
            var self = this;

            var $element = $(this.element);

            var onSelectDateCb = null;

            var DateRangePicker = window.daterangepicker;

            function selectDate() {
              if (onSelectDateCb !== null) {
                onSelectDateCb(this.startDate, this.endDate);
              }
            }

            var originalClickDate = DateRangePicker.prototype.clickDate;

            DateRangePicker.prototype.clickDate = function () {
              var v = originalClickDate.call.apply(
                originalClickDate,
                [this].concat(Array.prototype.slice.call(arguments))
              );
              selectDate.call(this);
              return v;
            };

            var originalUpdateFormInputs =
              DateRangePicker.prototype.updateFormInputs;
            DateRangePicker.prototype.updateFormInputs = function () {
              var v = originalUpdateFormInputs.call.apply(
                originalUpdateFormInputs,
                [this].concat(Array.prototype.slice.call(arguments))
              );

              this.container.find('button.applyBtn').prop('disabled', false);
              return v;
            };

            var originalClickApply = DateRangePicker.prototype.clickApply;
            DateRangePicker.prototype.clickApply = function () {
              if (!this.endDate) this.endDate = this.startDate;
              return originalClickApply.call.apply(
                originalClickApply,
                [this].concat(Array.prototype.slice.call(arguments))
              );
            };

            let conf = {
              locale: {
                format: 'DD.MM.YYYY',
                separator: this.options.separator,
                applyLabel: _t('Сохранить'),
                cancelLabel: _t('Отменить'),
                customRangeLabel: _t('Произвольный'),
                firstDay: 1,
                monthNames: [
                  _t('Январь'),
                  _t('Февраль'),
                  _t('Март'),
                  _t('Апрель'),
                  _t('Май'),
                  _t('Июнь'),
                  _t('Июль'),
                  _t('Август'),
                  _t('Сентябрь'),
                  _t('Октябрь'),
                  _t('Ноябрь'),
                  _t('Декабрь')
                ]
              },
              opens: this.options.opens,
              drops: this.options.drops,
              showDropdowns: this.options.showDropdowns,
              autoUpdateInput: false,
              autoApply: this.options.autoApply,
              buttonClasses: 'btn btn-with-icon',
              applyButtonClasses: 'btn-default btn-save',
              singleDatePicker: this.options.single,
              cancelButtonClasses: 'btn-danger btn-delete',
              parentEl: this.options.parentEl || 'body',
            };

            $element.daterangepicker(conf);

            var daterangepicker = $element.data('daterangepicker');

            DateRangePicker.prototype.clickDate = originalClickDate;

            this._daterangepicker = daterangepicker;

            var arrowElement = $.parseHTML(arrowTemplate);

            var $container = daterangepicker.container;

            $container.addClass('period-picker');

            if ('dropdownClass' in this.options) {
              $container.addClass(this.options.dropdownClass);
            }

            $container.prepend(arrowElement);

            var $arrow = $(arrowElement);
            var arrowWidth = $arrow.outerWidth();

            $element.on('show.daterangepicker', function () {
              var elementRelativeX =
                $element.offset().left - $container.offset().left;
              var elementWidth = $element.outerWidth();
              var containerWidth = $container.outerWidth();

              function processBounds(value) {
                return Math.max(
                  0,
                  Math.min(value, containerWidth - arrowWidth / 2)
                );
              }

              switch (_this2.options.arrowPosition.anchor) {
                case 'left':
                  $arrow.css(
                    'left',
                    processBounds(
                      elementRelativeX -
                        arrowWidth / 2 +
                        _this2.options.arrowPosition.offset
                    )
                  );
                  break;
                case 'center':
                  $arrow.css(
                    'left',
                    processBounds(
                      elementRelativeX +
                        (elementWidth - arrowWidth) / 2 +
                        _this2.options.arrowPosition.offset
                    )
                  );
                  break;
                case 'right':
                  $arrow.css(
                    'left',
                    processBounds(
                      elementRelativeX +
                        elementWidth -
                        arrowWidth / 2 +
                        _this2.options.arrowPosition.offset
                    )
                  );
                  break;
              }
            });

            if (this._hasRanges()) {
              var $rangeContainer = $($.parseHTML(rangeContainerTemplate));
              $container.prepend($rangeContainer);

              Object.getOwnPropertyNames(this.options.ranges).forEach(function (
                rangeLabel
              ) {
                var $range = $($.parseHTML(rangeTemplate)).text(rangeLabel);
                $rangeContainer.append($range);

                $range.on('click', function () {
                  _this2.value(rangeLabel);
                });
              });

              var $customRange = $($.parseHTML(rangeTemplate)).text(
                this.options.customRangeLabel
              );
              $rangeContainer.append($customRange);
              $customRange.on('click', function () {
                _this2.value(null);
              });

              var $ranges = $rangeContainer.children();

              this._setRange = function (rangeLabel) {
                _this2._selectedRangeLabel = rangeLabel;

                $ranges.removeClass('period-picker__range--active');

                if (rangeLabel !== null) {
                  $ranges
                    .filter(':contains("' + rangeLabel + '")')
                    .addClass('period-picker__range--active');
                } else {
                  $customRange.addClass('period-picker__range--active');
                }
              };
            }

            onSelectDateCb = function onSelectDateCb(startDate, endDate) {
              if (!self.options.single) {
                self._setInputs(startDate, endDate);
              }

              if (self._hasRanges()) {
                self._setRange(null);
              }
            };

            function isDateValid(date) {
              return (
                date.match(/\d{2}.\d{2}.\d{4}/) !== null &&
                moment(date, daterangepicker.locale.format, true).isValid()
              );
            }

            if (!this.options.single) {
              var startInputGroupElement = $.parseHTML(inputGroupTemplate);
              var endInputGroupElement = $.parseHTML(inputGroupTemplate);

              $container
                .find('.drp-calendar.left')
                .prepend(startInputGroupElement);
              $container
                .find('.drp-calendar.right')
                .prepend(endInputGroupElement);

              this._$startInput = $(startInputGroupElement).find('input');
              this._$endInput = $(endInputGroupElement).find('input');

              this._$startInput.mask('00.00.0000');
              this._$endInput.mask('00.00.0000');

              this._$startInput.on('input', function (event) {
                var value = event.target.value;

                if (isDateValid(value)) {
                  daterangepicker.setStartDate(value);
                } else {
                  daterangepicker.setStartDate(new Date());
                }

                daterangepicker.updateView();
              });

              this._$endInput.on('input', function (event) {
                var value = event.target.value;

                if (isDateValid(value)) {
                  daterangepicker.setEndDate(value);
                } else {
                  daterangepicker.setEndDate(new Date());
                }

                daterangepicker.updateView();
              });
            }

            $element.on('programmatic-change.period-picker', function () {
              _this2.value($element.val());
            });

            $element.on('apply.daterangepicker', function () {
              for (
                var _len = arguments.length, args = Array(_len), _key = 0;
                _key < _len;
                _key++
              ) {
                args[_key] = arguments[_key];
              }

              $element.val(_this2.value());

              $element.trigger('apply.period-picker', args);
            });

            this.value($element.val());
          }
        },
        {
          key: 'value',
          value: function value(_value) {
            if (_value === undefined) {
              var startDateFormatted = this._daterangepicker.startDate.format(
                this._daterangepicker.locale.format
              );
              var endDateFormatted = this._daterangepicker.endDate.format(
                this._daterangepicker.locale.format
              );

              if (this._selectedRangeLabel !== null) {
                return this._selectedRangeLabel;
              } else {
                if (startDateFormatted !== endDateFormatted) {
                  return (
                    startDateFormatted +
                    this._daterangepicker.locale.separator +
                    endDateFormatted
                  );
                } else {
                  return startDateFormatted;
                }
              }
            } else {
              var startDate = null;
              var endDate = null;
              var rangeLabel = null;

              if (_value !== null) {
                var tokens = _value.split(
                  this._daterangepicker.locale.separator
                );

                if (tokens.length === 2) {
                  startDate = moment(
                    tokens[0],
                    this._daterangepicker.locale.format,
                    true
                  );
                  endDate = moment(
                    tokens[1],
                    this._daterangepicker.locale.format,
                    true
                  );
                } else if (tokens.length === 1) {
                  startDate = moment(
                    tokens[0],
                    this._daterangepicker.locale.format,
                    true
                  );

                  if (startDate.isValid()) {
                    endDate = startDate;
                  } else if (this._hasRanges()) {
                    var range = this.options.ranges[tokens[0]];

                    if (range) {
                      rangeLabel = tokens[0];
                      startDate = range[0];
                      endDate = range[1];
                    }
                  }
                }
              } else {
                startDate = moment();
                endDate = moment();
              }

              if (startDate !== null && endDate !== null) {
                this._daterangepicker.setStartDate(startDate);
                this._daterangepicker.setEndDate(endDate);

                this._daterangepicker.updateView();

                if (!this.options.single) {
                  this._setInputs(startDate, endDate);
                }

                if (this._hasRanges()) {
                  this._setRange(rangeLabel);
                }
              }
            }
          }
        },
        {
          key: 'hide',
          value: function hide() {
            this._daterangepicker.hide();
          }
        },
        {
          key: '_setInputs',
          value: function _setInputs(startDate, endDate) {
            this._$startInput.val(this._formatDateToInput(startDate));
            this._$endInput.val(this._formatDateToInput(endDate));
          }
        },
        {
          key: '_hasRanges',
          value: function _hasRanges() {
            return 'ranges' in this.options;
          }
        },
        {
          key: '_formatDateToInput',
          value: function _formatDateToInput(date) {
            return date !== null
              ? date.format(this._daterangepicker.locale.format)
              : '';
          }
        }
      ]);

      return PeriodPicker;
    })(BasePlugin);

    registryJQueryPlugin('periodPicker', PeriodPicker);

    window.PeriodPicker = PeriodPicker;
  })(jQuery, window, document);


});

// $.fn.periodPicker = function () {
//     console.log('FN PERIOD PICKER');
//     var inputGroupTemplate =
//       '\n            <div class="input-group date-input-group">\n                <input class="form-control" placeholder="00.00.0000">\n                <i class="date-input-group__icon"></i>\n            </div>\n        '.trim();

//     return this.each(function () {
//       var onSelectDateCb = null;

//       var DateRangePicker = window.daterangepicker;

//       function selectDate() {
//         if (onSelectDateCb !== null) {
//           onSelectDateCb(this.startDate, this.endDate);
//         }
//       }

//       var originalClickDate = DateRangePicker.prototype.clickDate;
//       var originalElementChanged = DateRangePicker.prototype.elementChanged;

//       DateRangePicker.prototype.clickDate = function () {
//         var v = originalClickDate.call.apply(
//           originalClickDate,
//           [this].concat(Array.prototype.slice.call(arguments))
//         );
//         selectDate.call(this);
//         return v;
//       };

//       DateRangePicker.prototype.elementChanged = function () {
//         var v = originalElementChanged.call.apply(
//           originalElementChanged,
//           [this].concat(Array.prototype.slice.call(arguments))
//         );
//         selectDate.call(this);
//         return v;
//       };

//       $(this).daterangepicker({
//         locale: {
//           format: 'DD.MM.YYYY',
//           separator: ' - ',
//           applyLabel: _t('Сохранить'),
//           cancelLabel: _t('Отменить'),
//           firstDay: 1,
//           monthNames: [
//             _t('Январь'),
//             _t('Февраль'),
//             _t('Март'),
//             _t('Апрель'),
//             _t('Май'),
//             _t('Июнь'),
//             _t('Июль'),
//             _t('Август'),
//             _t('Сентябрь'),
//             _t('Октябрь'),
//             _t('Ноябрь'),
//             _t('Декабрь')
//           ]
//         },
//         setDate: null,
//         buttonClasses: 'btn btn-with-icon',
//         applyButtonClasses: 'btn-default btn-save',
//         cancelButtonClasses: 'btn-danger btn-delete'
//       });

//       DateRangePicker.prototype.clickDate = originalClickDate;
//       DateRangePicker.prototype.elementChanged = originalElementChanged;

//       var daterangepicker = $(this).data('daterangepicker');

//       var startInputGroupElement = $.parseHTML(inputGroupTemplate);
//       var endInputGroupElement = $.parseHTML(inputGroupTemplate);

//       var $container = daterangepicker.container;

//       $container.addClass('period-picker');

//       $container.find('.drp-calendar.left').prepend(startInputGroupElement);
//       $container.find('.drp-calendar.right').prepend(endInputGroupElement);

//       var $startInput = $(startInputGroupElement).find('input');
//       var $endInput = $(endInputGroupElement).find('input');

//       $startInput.mask('00.00.0000');
//       $endInput.mask('00.00.0000');

//       function formatDateToInput(date) {
//         return date !== null ? date.format(daterangepicker.locale.format) : '';
//       }

//       $startInput.val(formatDateToInput(daterangepicker.startDate));
//       $endInput.val(formatDateToInput(daterangepicker.endDate));

//       onSelectDateCb = function onSelectDateCb(startDate, endDate) {
//         $startInput.val(formatDateToInput(startDate));
//         $endInput.val(formatDateToInput(endDate));
//       };

//       $(this).on('show.daterangepicker', function (ev, picker) {
//         selectDate.call(picker);
//       });

//       function isDateValid(date) {
//         return (
//           date.match(/\d{2}.\d{2}.\d{4}/) !== null &&
//           moment(date, daterangepicker.locale.format).isValid()
//         );
//       }

//       $startInput.on('input', function (event) {
//         var value = event.target.value;
//         if (isDateValid(value)) {
//           daterangepicker.setStartDate(value);
//         } else {
//           daterangepicker.setStartDate(new Date());
//         }

//         daterangepicker.updateView();
//       });

//       $endInput.on('input', function (event) {
//         var value = event.target.value;
//         if (isDateValid(value)) {
//           daterangepicker.setEndDate(value);
//         } else {
//           daterangepicker.setEndDate(new Date());
//         }

//         daterangepicker.updateView();
//       });
//     });
//   };

//   $('.period-picker-control').periodPicker();
