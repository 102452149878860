import './translate';
window._t = (...params) => window.translator.translate(...params);

import './polyfills';
import './log';
import './fixes';

import './forms';

import './validation';

import { Directory } from './directory';
window.Directory = Directory;

import { copyToClipboard } from './copy-to-clipboard';
window.copyToClipboard = copyToClipboard;

import './upward-button';
import './sidebar';

import './mobile-info';

import './poll/import-poll'
