export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.element = element;

    this.action = params.action;

    this.config = params.config || {};

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (typeof this.action === 'function') this.action(entry);
      }
    });
    this.observer.observe(this.element, this.config);
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.observer.unobserve(this.element);
  }
}
