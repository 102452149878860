import { FoquzComponent } from "Models/foquz-component";
export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);
    
    this.params = params || {};
    this.config = this.params.config || {};
    this.ajaxConfig = this.params.ajaxConfig || {};

    this.loading = ko.observable(true);

    this.subscriptions = [];

    this.select = ko.observable(null);

    this.value = params.value;
    this.disabled = params.disabled;

    this.list = params.list;

    this.selectConfig = this.getSelectConfig();
  }

  get multiple() {
    return this.params.multiple;
  }

  get fixedOption() {
    return this.params.fixedOption;
  }

  get wrapperCssClass() {
    return "";
  }

  get dropdownCssClass() {
    return "";
  }

  get minWidth() {
    if ("width" in this.params) return this.params.width + "px";
    if (this.params.dense) return "120px";
    return null;
  }

  getSelectConfig() {
    const config = {
      containerCssClass: this.params.dense ? "" : "form-control",
      wrapperCssClass:
        "select2-container--form-control foquz-select2 " + this.wrapperCssClass,
      dropdownCssClass:
        (this.params.dense ? "dense-form-group__dropdown" : "") +
        " " +
        this.dropdownCssClass,
      containerCss: { "min-width": this.minWidth },
      minimumResultsForSearch: 0,
      allowClear: this.multiple ? false : true,
      placeholder: this.placeholder,

      ajax: {
        url: this.url,
        processResults: this.processResults.bind(this),
        delay: 250,
        cache: true,
        ...this.ajaxConfig,
      },

      templateResult: this.templateResult.bind(this),
      templateSelection: this.templateSelection.bind(this),

      ...this.config,
    };

    return config;
  }

  get url() {
    return this.params.url || "";
  }

  get placeholder() {
    return this.params.placeholder || "";
  }

  _getItems(response, params) {
    return response.items;
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }

  _filterDeleted(items) {
    return items.filter((i) => {
      if (i.deleted) {
        if (i.id != this.value()) return false;
      }
      return true;
    });
  }

  _filterByTerm(items, term = "") {
    if (!term) return items;
    term = term.toUpperCase();
    return items.filter((i) => {
      return i.text.toUpperCase().includes(term);
    });
  }

  processResults(data, params) {
    let items = this._getItems(data, params);

    if (this.fixedOption) {
      let optIndex = items.findIndex((i) => i.id == this.fixedOption.id);
      if (optIndex > -1) {
        let opt = items[optIndex];
        items.splice(optIndex, 1);
        opt.fixed = true;
        items.unshift(opt);
      }
    }

    console.log("process results", items);

    return {
      results: items,
    };
  }

  _getResultItem(repo) {
    return $("<span></span>").text(repo.text);
  }

  templateResult(repo) {
    if (repo.loading) {
      return $(
        `<div class="w-100 text-center"><i class="fa fa-spinner fa-pulse f-color-primary"></i></div>`
      ).get(0);
    }

    let item = this._getResultItem(repo);
    item.data.itemData = repo;

    if (repo.fixed) item.addClass("fixed");

    return item;
  }

  templateSelection(repo) {
    console.log("template", { repo });
    return repo.text || "";
  }

  getItemById(itemId, list) {
    for (let i = 0, count = list.length; i < count; i++) {
      if ("children" in list[i]) {
        let item = list[i].children.find((c) => c.id == itemId);
        if (item) return item;
      } else if (list[i].id == itemId) {
        return list[i];
      }
    }

    return null;
  }

  setValue() {
    return new Promise((res) => {
      let values = this.multiple ? this.value() : [this.value()];

      $.ajax({
        url: this.url,
        success: (response) => {
          let items = this._getItems(response);
          values.forEach((v) => {
            let item = this.getItemById(v, items);
            if (item) {
              item.first = true;
              this.onSelect(item);
              let option = new Option(item.text, item.id, true, true);
              $(this.select()).append(option).trigger("change");
            }
          });

          res();
        },
        error: (response) => {
          console.error(response.responseJSON);
        },
      });
    });
  }

  onSelect(item) {
    if (ko.isWritableObservable(this.params.selectedOption)) {
      this.params.selectedOption(item);
    }
  }

  onRender() {
    if (
      (this.multiple && this.value().length) ||
      (!this.multiple && this.value())
    ) {
      this.setValue().then(() => this.loading(false));
    } else {
      this.loading(false);
    }

    $(this.select())
      .on("select2:select", (e) => {
        this.onSelect(e.params.data);
      })
      .on("select2:clear", () => {
        this.onSelect(null);
      });
  }
}
