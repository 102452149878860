import { get as _get } from 'lodash';

function onReady(binding) {
  return new Promise((res) => {
    let subscription = binding.subscribe((v) => {
      subscription.dispose();
      res();
    });
  });
}

export function DialogsModule(ctx, config = {}) {
  ctx = ctx || {};
  let element = config.element || document.body;

  ctx.dialogsConfig = {
    _container: ko.observable(null),
    _sidesheetsContainer: ko.observable(null),
    _confirm: ko.observable(null),
    _info: ko.observable(null),
  };

  let onDialogsReady = onReady(ctx.dialogsConfig._container);
  let onSidesheetsReady = onReady(ctx.dialogsConfig._sidesheetsContainer);
  let onConfirmReady = onReady(ctx.dialogsConfig._confirm);
  let onInfoReady = onReady(ctx.dialogsConfig._info);

  ctx.openDialog = (config) => {
    let ref = null;

    if (!ctx.dialogsConfig._dialogsContainerCreated) {
      let dialogsContainer = document.createElement("dialogs-container");
      dialogsContainer.setAttribute("params", "ref: dialogsConfig._container");

      ko.applyBindings(ctx, dialogsContainer);

      ctx.dialogsConfig._dialogsContainerCreated = true;
    }
    onDialogsReady.then(() => {
      let dialog = ctx.dialogsConfig._container().add(config);
      ref = dialog.ref;
    });

    return {
      ref,
      hide: (...params) => {
        if (!ref) return;
        ref().hide(...params);
      },
    };
  };

  ctx.openSidesheet = (config) => {
    let ref = null;
    if (!ctx.dialogsConfig._sidesheetsContainerCreated) {
      let sidesheetsContainer = document.createElement("dialogs-container");
      sidesheetsContainer.setAttribute(
        "params",
        "ref: dialogsConfig._sidesheetsContainer"
      );
      ko.applyBindings(ctx, sidesheetsContainer);
      ctx.dialogsConfig._sidesheetsContainerCreated = true;
    }
    if (_get(window, 'POLL.statsLink.link', false) && _get(window, 'EXTERNAL', false)) {
      if (!config.params.searchParams) {
        config.params.searchParams = {};
      }
      config.params.searchParams.link = window.POLL.statsLink.link.replace(/.*stats\/(\w*)/, '$1');
    }
    onSidesheetsReady.then(() => {
      let dialog = ctx.dialogsConfig._sidesheetsContainer().add(config);
      ref = dialog.ref;
    });
    return {
      ref,
      hide: () => {
        if (!ref) return;
        ref().hide();
      },
    };
  };

  ctx.confirm = (config) => {
    return new Promise((res) => {
      ctx.openDialog({
        name: "confirm-dialog",
        params: config,
        events: {
          confirm: () => {
            res();
          },
        },
      });
    });

    // if (!ctx.dialogsConfig._confirmCreated) {
    //   let confirmDialog = document.createElement("confirm-dialog");
    //   confirmDialog.setAttribute("params", "ref: dialogsConfig._confirm");
    //   ko.applyBindings(ctx, confirmDialog);
    //   ctx.dialogsConfig._confirmCreated = true;
    // }
    // return onConfirmReady.then(() => ctx.dialogsConfig._confirm().open(config));
  };

  ctx.info = (config) => {
    return new Promise((res) => {
      ctx.openDialog({
        name: "info-dialog",
        params: config,
        events: {
          hide: () => {
            res();
          },
        },
      });
    });

    // if (!ctx.dialogsConfig._infoCreated) {
    //   let infoDialog = document.createElement("info-dialog");
    //   infoDialog.setAttribute("params", "ref: dialogsConfig._info");
    //   ko.applyBindings(ctx, infoDialog);
    //   ctx.dialogsConfig._infoCreated = true;
    // }

    // return onInfoReady.then(() => ctx.dialogsConfig._info().open(config));
  };

  const originalDispose = ctx.dispose;
  ctx.dispose = function () {
    if (typeof originalDispose === "function") {
      originalDispose.call(ctx);
    }
  };

  return ctx;
}
