import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-dropdown-btn');

    let popperId = this._id + '-popper';
    element.id = popperId;

    this.popper = ko.observable(null);

    this.buttonParams = {
      ...params,
      size: 'lg',
      click: () => {
        if (typeof params.click === 'function') {
          params.click();
          this.popper().hide();
        } else {
          this.toggle();
        }
      }
    };
    this.color = params.color;
    this.disabled = params.disabled;

    this.list = params.list;

    this.popperParams = {
      ref: this.popper,
      target: popperId,
      options: {
        placement: 'bottom-start',
        minWidth: 'equal',
        maxWidth: 250,
        fallbackPlacements: 'bottom-end',
        arrowPosition: {
          placement: 'end',
          offset: 20
        }
      },
      behavior: {
        click: false
      }
    };
  }

  toggle() {
    if (ko.toJS(this.disabled)) return;
    this.popper().toggle();
  }
}
