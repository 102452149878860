import './style';

ko.bindingHandlers.periodPicker = {
  init: function init(element, valueAccessor, allBindings) {
    var value = allBindings()['value'];
    var ranges = allBindings()['periodPickerRanges'];
    var dropdownClass = allBindings()['periodPickerDropdownClass'];
    var opens = allBindings()['periodPickerOpens'];
    var drops = allBindings()['periodPickerDrops'];
    var separator = allBindings()['periodPickerSeparator'];
    var apply = allBindings()['periodPickerApply'];
    var single = allBindings()['periodPickerSingle'];
    var arrowPosition = allBindings()['periodPickerArrowPosition'];
    var showDropdowns = allBindings()['periodPickerShowDropdowns'];
    var start = allBindings()['periodPickerStart'];

    if (start === 'today') start = new Date();

    const modal = allBindings.get('modalPicker');

    var config = allBindings.get('periodPickerConfig');

    var options = {};

    if (config) options = config;
    else {
      if (ranges !== undefined) {
        options.ranges = ranges;
      }

      if (dropdownClass !== undefined) {
        options.dropdownClass = dropdownClass;
      }

      if (opens !== undefined) {
        options.opens = opens;
      }

      if (drops !== undefined) {
        options.drops = drops;
      }

      if (separator !== undefined) {
        options.separator = separator;
      }

      if (single !== undefined) {
        options.single = single;
        options.autoApply = single;
      }

      if (arrowPosition !== undefined) {
        options.arrowPosition = arrowPosition;
      }

      if (showDropdowns !== undefined) {
        options.showDropdowns = showDropdowns;
      }
    }

    if (start) {
      options.minDate = start;
    }

    var $element = $(element);

    let parent = element.closest('[data-tooltip-container]');

    if (parent) {
      options.parentEl = parent;
    } else {
      options.parentEl = 'body';
    }

    console.log({ options })
    $element.periodPicker(options);

    var periodPicker = $element.periodPicker('instance');

    let closeBtn =
      $(`<button><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L23 23M23 2L2 23" stroke="#F96261" stroke-width="3" stroke-linecap="round"/>
    </svg></button>`);
    closeBtn.on('click', () => {
      $element.val('').trigger('change');
      periodPicker.hide();
    });
    let closeWrapper = $(`<div class="period-picker__close">
    </div>`).append(closeBtn);
    periodPicker._daterangepicker.container.append(closeWrapper);

    if (valueAccessor() !== undefined) {
      valueAccessor()(periodPicker);
    }

    if (value !== undefined) {
      periodPicker.value(value());

      value.subscribe(function (v) {
        if (periodPicker.value() !== v) {
          $element.trigger('programmatic-change.period-picker');
        }
      });
    }

    $element.on('apply.period-picker', function () {
      var v = periodPicker.value();

      if (value !== undefined) {
        value(v);
      }

      if (apply !== undefined) {
        apply(v);
      }
    });

    $element.on('showCalendar.daterangepicker', function () {
      let selects = periodPicker._daterangepicker.container.find('select');
      [...selects].forEach((el) => {
        ko.applyBindingsToNode(el, {
          select2: {
            dropdownParent: periodPicker._daterangepicker.container
          }
        });

        // $(el).select2({
        //   dropdownParent: periodPicker._daterangepicker.container,
        //   width: 'element'
        // });
      });
    });
  }
};

// TODO описать настройки
// TODO перенести настройки из main.js
// TODO dispose
