var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function declOfNum(number, titles) {
    var _number = Math.abs(number);
    var _titles = __spreadArray([], titles, true);
    if (_titles.length < 3)
        _titles[2] = _titles[1];
    var cases = [2, 0, 1, 1, 1, 2];
    return _titles[_number % 100 > 4 && _number % 100 < 20
        ? 2
        : cases[_number % 10 < 5 ? _number % 10 : 5]];
}
