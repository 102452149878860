/**
 * Группа радио-кнопок (горизонтальный блок)
 *
 * - value {String}
 * - options {Array.<{ id: String, text: String }>}
 */


 import { ViewModel as model } from './model';
 import template from './template.html';
 import './style.less';

 import { registerComponent } from 'Utils/engine/register-component';


 registerComponent('fc-button-group', { model, template });
