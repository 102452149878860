export const email = {
  id: 'email',
  name: 'Email',
  fullName: 'Email',
  isHTML: true,
};

export const sms = {
  id: 'sms',
  name: 'SMS',
  fullName: 'SMS',
  isHTML: false,
};

export const telegram = {
  id: 'telegram',
  name: 'Telegram',
  fullName: 'Telegram',
  isHTML: false,
};

export const viber = {
  id: 'viber',
  name: 'Viber',
  fullName: 'Viber',
  isHTML: false,
};

export const push = {
  id: 'push',
  name: 'Push-уведомления',
  fullName: 'Push-уведомления (FCM)',
  isHTML: true,
};

export const list = [email, sms, telegram, viber, push];

export const channelTypes = {
  'Email': 'email',
  'SMS': 'sms',
  'Viber': 'viber',
  'Telegram': 'telegram',
  'Push': 'push'
}

export const channels = {
  'email': email,
  'sms': sms,
  'viber': viber,
  'telegram': telegram,
  'push': push
}



export function getChannelType(channel) {
  if (!channel) return null;
  if (channel in channelTypes) return channelTypes[channel];
  if (channel in channels) return channel;
  return null;
}

export function getChannelData(channel) {
  let type = getChannelType(channel);
  if (!type) return null;
  return channels[type];
}
