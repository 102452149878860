export const RUSSIAN_LANG = 1;
export const ENGLISH_LANG = 2;
export const FRENCH_LANG = 3;
export const GERMAN_LANG = 4;
export const CHINESE_LANG = 5;
export const VIETNAMESE_LANG = 6;
export const TURKISH_LANG = 7;
export const SPANISH_LANG = 8;
export const ITALIAN_LANG = 9;
export const JAPANESE_LANG = 10;
export const KOREAN_LANG = 11;
export const PORTUGUESE_LANG = 12;
export const CHINESE_TRADITIONAL_LANG = 13;

export const DEFAULT_LANG = RUSSIAN_LANG;

export const LANGS_DATA = {
  [RUSSIAN_LANG]: {
    name: "Русский",
    shortCode: "ru",
    code: "ru-RU",
  },
  [ENGLISH_LANG]: {
    name: "English",
    shortCode: "en",
    code: "en-US",
  },
  [FRENCH_LANG]: {
    name: "Français",
    shortCode: "fr",
    code: "fr-FR",
  },
  [GERMAN_LANG]: {
    name: "Deutsche",
    shortCode: "de",
    code: "de-DE",
  },
  [CHINESE_LANG]: {
    name: "简体中文",
    shortCode: "ch",
    code: "ch-CH",
  },
  [VIETNAMESE_LANG]: {
    name: "Tiếng Việt",
    shortCode: "vi",
    code: "vi-VN",
  },
  [TURKISH_LANG]: {
    name: "Türkçe",
    shortCode: "tr",
    code: "tr-TR",
  },
    [SPANISH_LANG]: {
      name: "Español",
      shortCode: "es",
      code: "es-ES",
    },
    [ITALIAN_LANG]: {
      name: "Italiano",
      shortCode: "it",
      code: "it-IT",
    },
    [JAPANESE_LANG]: {
      name: "日本語",
      shortCode: "ja",
      code: "ja-JP",
    },
    [KOREAN_LANG]: {
      name: "한국어",
      shortCode: "ko",
      code: "ko-KR",
    },
    [PORTUGUESE_LANG]: {
      name: "Português",
      shortCode: "pt",
      code: "pt-PT",
    },
    [CHINESE_TRADITIONAL_LANG]: {
      name: "繁體中文",
      shortCode: "zh",
      code: "zh-TW",
    },
};
export const LANGS_ORDER = [
  RUSSIAN_LANG,
  ENGLISH_LANG,
  FRENCH_LANG,
  GERMAN_LANG,
  CHINESE_LANG,
  CHINESE_TRADITIONAL_LANG,
  VIETNAMESE_LANG,
  TURKISH_LANG,
  SPANISH_LANG,
  ITALIAN_LANG,
  JAPANESE_LANG,
  KOREAN_LANG,
  PORTUGUESE_LANG
];
