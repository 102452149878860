import './autosize-input';
import './autosize-textarea';
import './content-editable';
import './interval-input';
import './only-numbers';
import './decimal-numbers';
import './period-picker';
import './phone-input';
import './date-input';
import './mask';
import './select2';
import './slider';
import './time-input';
import './time-picker';

// TODO
// оставить один плагин для маски
