import { icons } from './icons';

export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    let name = ko.toJS(params.name);

    this.icon = ko.observable(icons['foquz-icon-' + name]);
    element.classList.add('svg-icon--' + name);

    if (ko.isObservable(params.name)) {
      this.subscriptions.push(
        params.name.subscribe((v) => {
          element.classList.remove('svg-icon--' + name);
          name = v;
          this.icon(icons['foquz-icon-' + name]);
          element.classList.add('svg-icon--' + name);
        })
      );
    }

    if (params.width) element.style.width = params.width + 'px';
    if (params.height) element.style.height = params.height + 'px';
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
