import { FoquzModalPage } from './model';
import html from './template.html';
import './style.less';

ko.components.register('foquz-modal-page', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      let model = new FoquzModalPage(params, element);

      // Контроллер модального окна
      let controller = params.modal;
      if (ko.isObservable(controller)) {
        controller(model);
      }

      return model;
    },
  },
  template: html,
});
