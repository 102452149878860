import { FComponent } from "@/components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    const { type, icons, value, showLabels, showNumbers, labels } = params;

    element.classList.add("fc-img-rating");
    element.setAttribute("data-type", type);
    element.setAttribute("data-icons", icons.length);

    this.type = type;
    this.icons = icons;
    this.value = ko.isObservable(value) ? value : ko.observable(value);
    this.showLabels = showLabels;

    this.label = ko.pureComputed(() => {
      let activeIconId = this.value();
      if (!activeIconId) return "";

      if (labels) {
        const labelsList = ko.toJS(labels);
        const label = labelsList[activeIconId];
        if (label) return label;
      }

      let icon = this.icons.find((i) => i.id == activeIconId);
      return icon ? icon.label : "";
    });

    this.getLabel = (smile) => {
      if (labels) {
        const labelsList = ko.toJS(labels);
        const label = labelsList[smile.id];
        if (label) return label;
      }
      return smile.label;
    };

    ko.applyBindingsToNode(element, {
      css: {
        "fc-img-rating--inited": this.value,
        "fc-img-rating--labels": showLabels,
      },
    });
  }
}
