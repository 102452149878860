import { FileLoader } from './file-loader';

ko.bindingHandlers.charsCounter = {
  init: function init(element, valueAccessor, allBindings) {
    var count = allBindings()['charsCounterCount'];
    var maxCount = allBindings()['charsCounterMaxCount'];
    if (maxCount === undefined) {
      maxCount = $(element).find('.form-control').attr('maxlength');
    }

    $(element).charsCounter({
      initCount: ko.utils.unwrapObservable(count),
      maxCount: maxCount,
      registryCountFn: function registryCountFn(countFn) {
        if (ko.isObservable(count)) {
          count.subscribe(function (newCount) {
            return countFn(newCount);
          });
        } else {
          $(element).data('charsCounterCountFn', countFn);
        }
      }
    });
  },
  update: function update(element, valueAccessor, allBindings) {
    var count = allBindings()['charsCounterCount'];
    if (!ko.isObservable(count)) {
      $(element).data('charsCounterCountFn')(count);
    }
  }
};

ko.bindingHandlers.phoneMask = {
  init: function (element) {
    $(element).inputmask('+7 (999) 999 9999', {
      placeholder: ''
    });
  },
  update: function (element, valueAccessor) {}
};

ko.bindingHandlers.swiperSlider = {
  init: function (element, valueAccessor, allBindings) {
    const $element = $(element);
    new Swiper($element.get(0), {
      spaceBetween: 8,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      preventClicks: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      on: {
        init: function () {
          $(document.body).on('click', '.js-slider-prev-btn', (event) => {
            event.preventDefault();
            this.slidePrev();
          });

          $(document.body).on('click', '.js-slider-next-btn', (event) => {
            event.preventDefault();
            this.slideNext();
          });
        }
      }
    });
  }
};

ko.bindingHandlers.sortable = {
  init: function init(element, valueAccessor, allBindings) {
    element.classList.add('sortable-container');
    var selector = allBindings().sortableItem;
    $(element).find(selector).addClass('sortable-item');
    var handle = allBindings().sortableHandle;

    var params = {
      draggable: selector,
      delay: 200,
      classes: {
        mirror: 'sortable-item--mirror',
        'source:dragging': 'sortable-item--dragging',
        'container:over': 'sortable-container--over',
        'body:dragging': 'body--dragging'
      },
      mirror: {
        constrainDimensions: true
      }
    };
    if (handle) params.handle = handle;

    var onSort = allBindings().onSort;
    var sortable = new Draggable.Sortable(element, params);
    sortable.on('sortable:stop', function (e, ui) {
      if (typeof onSort === 'function') onSort(e, ui);
    });
  }
};

ko.bindingHandlers.numericField = {
  init: function init(element) {
    $(element).inputFilter(function (value) {
      return /^\d*$/.test(value) && value >= 0;
    });
  },
  update: function update(element, valueAccessor) {}
};

ko.bindingHandlers.numericIntervalField = {
  init: function init(element, valueAccessor) {
    let { min, max } = valueAccessor();

    $(element).inputFilter(function (value) {
      if (!value) return true;
      return /^\d*$/.test(value) && value >= min && value <= max;
    });
  },
  update: function update(element, valueAccessor) {}
};

ko.bindingHandlers.inputMask = {
  init: function init(element, valueAccessor, allBindings) {
    let config = valueAccessor() || {};
    $(element).inputmask(config);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).inputmask('remove');
    });
  }
};

ko.components.register('formFieldError', {
  viewModel: function (params) {
    this.field = params.field;
    this.errorStateMatcher = params.errorStateMatcher;
    this.duration = params.duration || 200;
  },
  template: `
    <!-- ko template: {
      foreach: templateIf(errorStateMatcher(field)(), $data),
      afterAdd: fadeAfterAddFactory(duration),
      beforeRemove: fadeBeforeRemoveFactory(duration)
    } -->
    <div class="form-error" data-bind="text: field.error()"></div>
    <!-- /ko -->
  `
});

ko.bindingHandlers.imagePicker = {
  init: function (element) {
    $(element).imagePicker();
  },
  update: function (element, valueAccessor) {}
};

ko.bindingHandlers.dateInputGroup = {
  init: function init(element) {
    var $element = $(element);
    var $control = $element.find('.form-control');
    $element.find('.date-input-group__icon').on('click', function () {
      $control.focus();
    });
  }
};

ko.bindingHandlers.copyToClipboard = {
  init: function init(element, valueAccessor) {
    let copiedValue = valueAccessor();

    $(element).on('click', () => {
      window.copyToClipboard(ko.unwrap(copiedValue), element);
    });
  }
};

ko.bindingHandlers.fileLoader = {
  init: (element, valueAccessor) => {
    let config = valueAccessor() || {};

    let limitErrors = {
      [FileLoader.IMAGE_TYPE]: _t(
        'main',
        'Размер файла не должен превышать {size} Мб',
        {
          size: 5
        }
      ),
      [FileLoader.VIDEO_TYPE]: _t(
        'main',
        'Размер файла не должен превышать {size} Мб',
        {
          size: 10
        }
      )
    };

    let limits = {
      image: 5 * 1024 * 1024,
      video: 10 * 1024 * 1024
    };

    const loader = new FileLoader({
      images: config.images,
      videos: config.videos,
      imageLimit: 'imageLimit' in config ? config.imageLimit : limits.image,
      videoLimit: 'videoLimit' in config ? config.videoLimit : limits.video
    });

    loader.on(FileLoader.LOAD, () => {
      $(element).trigger('load', loader.file);
      if (typeof config.onLoad == 'function') config.onLoad(loader.file);
    });

    loader.on(FileLoader.LIMIT_ERROR, () => {
      let fileType = loader.fileType;
      let errorText = limitErrors[fileType];

      if ('limitErrors' in config) {
        errorText = config.limitErrors[fileType];
      }

      $(element).trigger('limit', errorText);
      if (typeof config.onError == 'function') {
        config.onError(errorText);
      }
    });

    $(element).click(() => {
      loader.open();
    });
  }
};
