import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add("fc-scrollable-table");

    this.content = ko.observable(null);
    this.scroll = ko.observable(null);
    this.header = ko.observable(null);
    this.originalTable = null;
    this.cloneHeader = null;

    this.fixedHeader = params.fixedHeader;
    if (this.fixedHeader)
      element.classList.add("fc-scrollable-table--sticky-header");

    this.loading = params.loading;

    this.leftScrollDisabled = ko.observable(false);
    this.rightScrollDisabled = ko.observable(false);
    this.needScroll = ko.observable(false);

    this.isHeaderFixed = ko.observable(false);

    ko.applyBindingsToNode(element, {
      css: {
        "fc-scrollable-table--fixed": this.isHeaderFixed,
      },
    });
  }

  setCellWidth(cell, width) {
    width = width ? width + "px" : "";
    cell.style.width = width;
    cell.style.minWidth = width;
    cell.style.maxWidth = width;
  }

  sync() {
    let header = this.header();
    let cloneHeader = this.cloneHeader;
    cloneHeader.innerHTML = "";

    let cloneHeaderRow = document.createElement("tr");

    [...header.querySelectorAll("th")].forEach((hCell) => {
      let cloneCell = document.createElement("th");
      cloneHeaderRow.appendChild(cloneCell);

      let width = hCell.getAttribute("width") || 0;
      let realWidth = hCell.offsetWidth;
      let finalWidth = Math.max(width, realWidth);

      this.setCellWidth(cloneCell, finalWidth);
      this.setCellWidth(hCell, finalWidth);
    });

    cloneHeader.appendChild(cloneHeaderRow);
  }

  onElementRender() {
    this.originalTable = this.content().querySelector("table");
    let originalHeader = this.originalTable.querySelector("thead");

    let header = this.header();
    let headerTable = header.querySelector("table");

    // Копировать заголовок таблицы
    if (originalHeader) {
      this.cloneHeader = document.createElement("thead");

      originalHeader.parentElement.replaceChild(
        this.cloneHeader,
        originalHeader
      );
      headerTable.appendChild(originalHeader);

      this.sync();
    }

    return;

    if (this.fixedHeader) {
      let tableHead = this.originalTable.querySelector("thead");
      let top = this.top();
      let bottom = this.bottom();

      this.topObserver = new IntersectionObserver(([entry]) => {
        if (entry.boundingClientRect.top < 0) this.isHeaderFixed(true);
        else this.isHeaderFixed(false);
      });
      this.topObserver.observe(top);

      this.bottomObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.boundingClientRect.top < 0) this.isHeaderFixed(true);
          else this.isHeaderFixed(false);
        },
        {
          rootMargin: tableHead.offsetHeight + "px",
        }
      );
      this.bottomObserver.observe(bottom);
    }

    if (false) {
      let scrollRef = this.scroll();
      let scroll = scrollRef.scroll();

      this.needScroll(scroll.horizontal());
      this.leftScrollDisabled(scroll.left());
      this.rightScrollDisabled(scroll.right());

      this.addSubscription(
        scroll.horizontal.subscribe((v) => {
          this.needScroll(v);
        })
      );
      this.addSubscription(
        scroll.left.subscribe((v) => {
          this.leftScrollDisabled(v);
        })
      );
      this.addSubscription(
        scroll.right.subscribe((v) => {
          this.rightScrollDisabled(v);
        })
      );
    }
  }

  dispose() {
    if (this.topObserver) this.topObserver.unobserve(this.top());
    if (this.bottomObserver) this.bottomObserver.unobserve(this.bottom());
  }

  scrollLeft() {
    this.scroll().move({ x: "-= 150px" });
  }

  scrollRight() {
    this.scroll().move({ x: "+= 150px" });
  }
}
