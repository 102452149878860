/**
 * fc-popper - дропдаун
 *
 * target - id целевого элемента
 * options: {
 *   placement
 *   interactive
 *   fix
 *   hover
 *   arrow
 *   arrowPosition
 *   offset,
 *   minWidth, // число|'equal'
 *   width, // число|'equal'
 *   maxWidth, // число
 *   minWidth, // число
 *   flip
 * }
 * behavior : {
 *   hover - открытие/закрытие при наведении
 *   click - открытие/закрытие при клике на элемент
 *   clickOutside - закрытие при клике снаружи или на поппер (если не интерактивный)
 *   interactive - возможность кликать по попперу без закрытия
 * }
 * mobileView: 'modal'
 *
 * fbPopper - тултип
 * title
 */

import './style.less';

import { binding } from './binding';
import { ViewModel as model } from './model';
import template from './template.html';

import { registerComponent } from 'Utils/engine/register-component';
import { registerBinding } from 'Utils/engine/register-binding';

registerBinding('fbPopper', binding);
registerBinding('tooltip', binding);
registerComponent('fc-popper', { model, template });
