import { FComponent } from "Components/f-component";
import { getMobileWatcher } from "Utils/window/mobile-watcher";
import { flattenList } from "@/utils/list/flatten-list";
import { useList } from "@/utils/list/use-list";
import { createComponent } from "@/utils/engine/create-component";
import { elementDispatcher } from "@/utils/dispatch-event";
import "./fc-select-result/single";
import "./fc-select-result/multiple";
export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    this.onChange = params.onChange;

    this.elementDispatcher = elementDispatcher(element);

    element.classList.add("fc-select");
    this.inline = params.inline;
    if (this.inline) element.classList.add("fc-select--inline");

    this.emptyText = "Совпадений не найдено";

    this.dropdownClass = params.dropdownClass;
    this.activeCategories = params.activeCategories;

    this.dropdown = ko.observable(null);
    this.listSearch = ko.observable(null);
    this.fieldSearch = ko.observable(null);

    this.disabled = params.disabled;
    this.readMode = params.readMode;

    this.canRenderList = ko.observable(false);
    this.listRendered = ko.observable(false);

    this.fields = params.fields;

    this.query = ko.observable("");
    this.debouncedQuery = ko.observable("");

    const updateQuery = _.debounce(() => {
      this.debouncedQuery(this.query());
    }, 500);
    this.query.subscribe(() => updateQuery());
    this.debouncedQuery.subscribe(v => {
      if (this.onSearch) {
        this.onSearch(v);
      }
    });

    this.placeholder = params.placeholder;
    this.showSearch = ko.observable(false);
    this.searchPlaceholder = params.searchPlaceholder;
    this.multiple = params.multiple;
    element.classList.add(
      this.multiple ? "fc-select--multiple" : "fc-select--single"
    );
    this.clearable = params.clearable;
    this.blockSelectedGroup = params.blockSelectedGroup;
    this.canRemoveFromList = this.multiple;
    this.disabledLevel = params.disabledLevel;

    this.optionsForSearch =
      "optionsForSearch" in params ? params.optionsForSearch : 10;
    this.searchable = ko.pureComputed(() => {
      return (
        !params.onSearch && 
        (
          this.multiple ||
          params.searchable ||
          this.optionsForSearch <= this.options().length
        )
      );
    });
    this.onSearch = params.onSearch;

    // выбранные варианты
    this.value = params.value;

    this.childrenProp = params.childrenProp;
    this.optionTextProp = params.optionTextProp; 
    this.disableFoldersSelect = params.disableFoldersSelect;

    const {
      items,
      dispose: disposeList,
      loading: loadingList,
    } = useList({
      items: params.options,
      handleItems: (items) => {
        if (this.fields) {
          items = items.map((i) => {
            let item = { ...i };
            if (this.fields.value) item.id = i[this.fields.value];
            if (this.fields.text) item.text = i[this.fields.text];
            if (this.fields.children) item.items = i[this.fields.children];
            return item;
          });
        }
        return flattenList(items, this.childrenProp, { disableFoldersSelect: this.disableFoldersSelect, textProp: this.optionTextProp });
      },
    });
    this.options = items;

    this.disabledOptions = ko.observableArray([]);

    this.allDisabledOptions = ko.computed(() => {
      const selectOptions = ko.toJS(params.disabledOptions);

      return [
        ...this.disabledOptions(),
        ...(Array.isArray(selectOptions) ? selectOptions : []),
      ];
    });

    this.loading = loadingList;

    // const { list } = useListState(items, {
    //   selected: this.value
    // });
    // // список вариантов (с состояниями)
    // this.list = list;

    this.filteredOptions = ko.computed(() => {
      if (!this.searchable()) {
        return this.options();
        // return this.list();
      }
      let q = this.debouncedQuery().trim().toLowerCase();
      if (!q) {
        return this.options();
        // return this.list();
      }

      return this.options().filter((o) => this.filterFn(o, q));
      // return this.list().filter((o) => this.filterFn(o, q));
    });

    this.value.subscribe((v) => {
      if (this.multiple) {
        if (!v.length) {
          this.disabledOptions([]);
        }
      }
    });

    this.selected = ko.computed(() => {
      let value = this.value();
      if (this.multiple) {
        const selected = this.options().filter((i) => value.find(v => v == i.id));

        return selected;
      }

      return [this.options().find((i) => i.id == value)].filter(Boolean);
      // return this.list().filter((i) => i.selected);
    });

    if ("selected" in params && ko.isObservable(params.selected)) {
      params.selected(this.multiple ? this.selected() : this.selected()[0]);
      this.selected.subscribe((v) => params.selected(this.multiple ? v : v[0]));
    }

    if (this.multiple && this.blockSelectedGroup) {
      const items = this.selected();
      items.forEach((item) => this.blockGroup(item.id));
    }

    this.active = ko.observable(false);
    this.dropdownOpened = ko.observable(false);

    this.dropdownOpened.subscribe((v) => {
      console.log("dropdown opened", v);
      this.emitEvent(v ? "show_dropdown" : "hide_dropdown");
    });
    this.active.subscribe((v) => {
      if (v) {
        if (!this.listRendered()) {
          setTimeout(() => {
            this.canRenderList(true);
          }, 200);
        }

        setTimeout(() => {
          if (this.listSearch()) this.listSearch().focus();
          if (this.fieldSearch()) this.fieldSearch().focus();
        });
      } else {
        this.showSearch(false);
        this.query("");
      }
    });

    this.showSearch.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          if (this.fieldSearch()) this.fieldSearch().focus();
        });
      }
    });

    this.resultItemComponent = null;

    if (this.slots.result)
      this.resultItemComponent = createComponent(this.slots.result);

    ko.applyBindingsToNode(element, {
      css: {
        "fc-select--active": this.active,
        "fc-select--disabled": this.disabled,
        "fc-select--invalid": params.invalid,
        "fc-select--valid": params.valid,
        "fc-select--read-mode": this.readMode,
      },
    });

    let isMobile = getMobileWatcher();

    this.listView = ko.computed(() => {
      if (!this.multiple) return null;
      if (isMobile()) return "checkbox";
      return null;
    });

    this.showPlaceholder = ko.pureComputed(() => {
      if (this.selected().length) return false;
      if (this.multiple) {
        if (this.showSearch()) return false;
      }
      if (!this.inline) return true;
      return true;
    });

    this.popperOptions = {
      placement: this.inline ? "bottom-start" : "bottom-end",
      width: this.inline ? 250 : "equal",
      minWidth: 100,
      arrowPosition: {
        placement: this.inline ? "start" : "end",
        offset: 24,
      },
      flip: true,
      ...(params.popperOptions || {}),
    };

    this.popperMobileView =
      "popperMobileView" in params ? params.popperMobileView : "modal";

    this.onReachEnd = params.onReachEnd;
  }

  filterFn(option, q) {
    const field = option.text;
    if (!field) return false;
    let isNameFiltered = field.toLowerCase().includes(q);
    if (isNameFiltered) return true;

    if (option.items && option.items.length) {
      let isOptionsFiltered = option.items.some((opt) => this.filterFn(opt, q));
      if (isOptionsFiltered) return true;
    }

    return false;
  }

  _getValue() {
    return this.multiple ? ko.toJS(this.value) : [ko.toJS(this.value)];
  }

  handleOptions(options) {
    let result = [];

    let flatOption = (option, level, parentDisabled) => {
      let isOptionDisabled = parentDisabled || option.disabled;
      let opt = {
        ...option,
        disabled: ko.observable(isOptionDisabled),
        level,
      };

      result.push(opt);

      if ("items" in option && Array.isArray(option.items)) {
        option.items.map((o) => flatOption(o, level + 1, isOptionDisabled));
      }
    };

    options.forEach((o) => flatOption(o, 0));

    return result;
  }

  getOptionByData(optionData) {
    return this.options().find((option) => {
      return option.id == optionData.id;
    });
  }

  isParent(parentId, childId) {
    let list = this.options();
    let item = list.find((i) => i.id === childId);

    while (item && item.level > 0) {
      if (item.parent === parentId) return true;
      item = list.find((i) => i.id === item.parent);
    }

    return false;
  }

  setValue(newValue) {
    if (typeof this.onChange === "function") {
      this.onChange(newValue);
      return;
    }
    this.value(newValue);
  }

  selectOption(option) {
    if (!("id" in option)) return;

    if (option.disabled) {
      if (this.multiple) this.removeOption(option);
      if (this.listView() !== "checkbox") this.dropdown().hide();
      return;
    }

    let value = this.value();
    let optionValue = option.id;
    let removed = false;

    if (this.multiple) {
      if (value.includes(optionValue)) {
        if (this.canRemoveFromList) {
          value = value.filter((o) => o !== optionValue);
          removed = true;
        } else return;
      } else {
        value.push(optionValue);
      }
    } else {
      if (value == optionValue) return;
      value = optionValue;
    }

    if (removed) {
      this.unblockGroup(option.id);
    } else if (this.multiple && this.blockSelectedGroup) {
      value = value.filter((id) => !this.isParent(option.id, id));
      this.blockGroup(option.id);
    }

    if (this.listView() !== "checkbox") this.dropdown().hide();
    if (this.fieldSearch()) this.fieldSearch().focus();
    this.setValue(value);
  }

  blockGroup(optionId) {
    const option = this.options().find((o) => o.id === optionId);
    if (!option) return;

    const items = option.items;

    if (items && items.length) {
      this.disabledOptions([
        ...this.disabledOptions(),
        ...items.map((i) => i.id),
      ]);
    }
  }

  unblockGroup(optionId) {
    let disabled = this.disabledOptions().filter((id) => {
      return !this.isParent(optionId, id);
    });
    this.disabledOptions(disabled);
  }

  removeOption(option) {
    const newValue = this.value().filter((item) => item != option.id);
    this.setValue(newValue);

    if (this.multiple && this.blockSelectedGroup) {
      this.unblockGroup(option.id);
    }

    if (this.listView() !== "checkbox") this.dropdown().hide();
  }

  removeAll() {
    if (this.multiple) {
      this.setValue([]);
    } else this.setValue('');
    if (this.dropdown && this.dropdown() && this.dropdown().hide) {
      this.dropdown().hide();
    }
  }

  toggleList(event) {
    if (ko.toJS(this.disabled)) return;

    if (event.target.closest(".fc-select-result__remove")) return;
    if (event.target.closest(".fc-select-field__remove")) return;

    if (!this.dropdown().isOpen()) this.showSearch(true);
    setTimeout(() => {
      this.dropdown().toggle();
    });
  }

  openList() {
    setTimeout(() => {
      if (this.dropdown().isOpen()) {
        this.dropdown().hide();
      } else {
        this.dropdown().toggle();
      }
    });
  }

  hideList() {
    this.dropdown().hide();
  }
}
