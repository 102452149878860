import { DialogsModule } from "@/utils/dialogs-module";
import "Dialogs/poll-folder/select-poll-folder-dialog";
import { Translator } from "@/utils/translate";
import { IS_EDITOR_WITHOUT_FOLDERS } from "@/api/user/editor";

const pollsTranslator = Translator("polls");

const CREATE_POLL_LINK = "/foquz/foquz-poll/create";

const getCreatePollLink = (params) => {
  const search = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return `${CREATE_POLL_LINK}?${search}`;
};

export function ViewModel(params, element) {
  element.classList.add("fc-create-poll-link");

  const { isEditor } = params;

  const dialogs = DialogsModule();
  const goToPoll = (params) => {
    const link = getCreatePollLink(params);
    location.href = link;
  };

  const createPoll = (urlParams = {}, event) => {
    const folder = event.target.getAttribute("data-folder");
    const params = {
      ...urlParams,
    };
    
    if (isEditor) {
      dialogs.openDialog({
        name: "select-poll-folder-dialog",
        params: {
          title: pollsTranslator.t("Выберите папку для создания опроса"),
          confirm: pollsTranslator.t("Создать опрос"),
          mode: "success",
          onSelect: (folderId) => {
            return new Promise((resolve) => {
              goToPoll({
                folder_id: folderId,
                ...urlParams,
              });
              resolve();
            });
          },
        },
      });
    } else {
     
      if (folder) params.folder_id = folder;
      goToPoll(params);
    }
  };

  return {
    editorWithoutFolders: IS_EDITOR_WITHOUT_FOLDERS,
    createPoll,
  };
}
