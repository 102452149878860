/**
 * Календарь на один месяц
 * все даты в формате 'DD.MM.YYYY'
 *
 * Параметры:
 *  - dropdowns {Boolean} - стиль контролов (false - стрелки переключения месяца, true - селекты месяца и года)
 *  - selected {string|null} - выбранная дата (DD.MM.YYYY)
 *  - from {string} - начало выбранного периода (DD.MM.YYYY)
 *  - to {string} - конец выбранного периода (DD.MM.YYYY)
 *  - rangeClosed {Boolean} - период закрыт (конец периода выбран, определяет стиль последнего дня)
 *  - date {string} - отображаемый месяц (DD.MM.YYYY), число (DD) не важно
 *
 * События:
 *  - calendar-day-hover - наведение на день
 *  - calendar-day-select - выбор дня
 *  - calendar-month-change - переключение отображаемого месяца
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from '@/utils/engine/register-component';

registerComponent('fc-month-calendar', { model, template });
