import { FoquzComponent } from 'Models/foquz-component';

let unique = 1;

export class CheckInputModel extends FoquzComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('check-input');

    this.id = 'check-input-' + unique++;

    this.input = ko.observable(null);

    this.checked = params.checked;
    this.value = params.value;
    this.disabled = params.disabled;
    this.name = params.name;
    this.events = params.events;
    this.onChange = params.onChange;
  }

  onLabelClick() {
    if (typeof this.onChange === 'function') this.onChange(this.value);
    else return true;
  }
}
