export function getScrollbarSize() {
  let outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);
  let widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';
  let inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);
  let widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  return widthNoScroll - widthWithScroll;
}

export function hasScrollbar() {
  return document.body.scrollHeight > document.body.clientHeight;
}

export function watchScroll(className = 'scrolled') {
  const cb = _.throttle(() => {
    document.body.classList.toggle(className, window.pageYOffset > 5);
  });
  window.addEventListener('scroll', cb);
}
