import Inputmask from 'inputmask/lib/inputmask';

ko.bindingHandlers.mask = {
  init: function init(element, valueAccessor, allBindings) {
    var pattern = allBindings()['maskPattern'];
    $(element).mask(pattern);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      // TODO
    });
  }
};

ko.bindingHandlers.foquzMask = {
  init: function init(element, valueAccessor, allBindings) {
    var pattern = allBindings()['maskPattern'];

    //$(element).mask(pattern);

    Inputmask({ regex: pattern, showMaskOnHover: true, }, element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Inputmask.remove(element);
    });
  }
};
