import { FComponent } from "@/components/f-component";
import { months } from "@/utils/date/months";
import { dateFromString } from "@/utils/date/date-from-string";
import { startOfMonth } from "@/utils/date/start-of-month";
import { endOfMonth } from "@/utils/date/end-of-month";
import { startOfWeek } from "@/utils/date/start-of-week";
import { endOfWeek } from "@/utils/date/end-of-week";
import { today } from "@/utils/date/today";

import { Translator } from "@/utils/translate";

export const DAY_HOVER = "calendar-day-hover";
export const DAY_SELECT = "calendar-day-select";
export const MONTH_CHANGE = "calendar-month-change";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);
    element.classList.add("fc-month-calendar");

    this.translator = Translator("calendar");

    this.today = +today;

    let start = params.start;

    if (start === "today") {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      this.start = +today;
    }

    // Выбранная дата (одиночная)
    this.selectedDate = ko.computed(() => {
      if (!("selected" in params)) null;
      let selected = ko.toJS(params.selected);
      if (!selected) return null;

      let date = dateFromString(selected);
      return +date;
    });

    // Выбранный диапазон
    this.rangeClosed = ko.computed(() => {
      return !!ko.toJS(params.rangeClosed);
    });

    this.fromDate = ko.computed(() => {
      if (!("from" in params)) return null;
      let from = ko.toJS(params.from);
      if (!from) return null;
      let date = dateFromString(from);
      return +date;
    });

    this.toDate = ko.computed(() => {
      if (!("to" in params)) return null;
      let to = ko.toJS(params.to);
      if (!to) return null;
      let date = dateFromString(to);
      return +date;
    });

    // Отображаемый месяц
    this.date = ko.observable(today);

    if (ko.toJS(params.date)) {
      this.update(ko.toJS(params.date));
    }

    if (ko.isObservable(params.date)) {
      let valueCb = params.date.subscribe((v) => {
        this.update(v);
      });
      this.onDispose(() => valueCb.dispose());
    }

    // Списки (месяц, год)
    this.dropdowns = params.dropdowns;
    this.dropdownMonth = ko.observable(null);
    this.dropdownYear = ko.observable(null);

    this.dropdownDate = ko.computed(() => {
      let month = this.dropdownMonth() + 1;
      let year = this.dropdownYear();
      month = ("" + month).padStart(2, "0");
      return `01.${month}.${year}`;
    });

    this.years = [];
    this.month = [];

    if (this.dropdowns) {
      let startYear = today.getFullYear() - 100;
      this.years = Array(200)
        .fill(null)
        .map((_, i) => {
          let year = startYear + i;
          return {
            id: year,
            text: year,
          };
        });
      this.months = months.map((m, i) => {
        return {
          id: i,
          text: this.translator.t(m),
        };
      });
      this.dropdownMonth(today.getMonth());
      this.dropdownYear(today.getFullYear());

      let dropdownCb = this.dropdownDate.subscribe((v) => {
        this.update(v, "fromDropdowns");
      });

      this.onDispose(() => dropdownCb.dispose());
    }

    // Дни, сгруппированные по неделям
    this.weeks = ko.computed(() => {
      return this.calculateWeeks(this.date());
    });

    // Название отображаемого месяца
    this.monthName = ko.computed(() => {
      let name = months[this.date().getMonth()];
      return this.translator.t(name)();
    });

    // Отображаемый год
    this.year = ko.computed(() => {
      return this.date().getFullYear();
    });

    // При незакрытом диапазоне цвет ховера изменяется
    ko.applyBindingsToNode(element, {
      css: {
        "fc-month-calendar--range": ko.computed(() => {
          return this.fromDate() && !this.rangeClosed();
        }),
      },
    });
  }

  /**
    Обновляет отображаемую дату (пересчитываются дни)
  */
  update(newDate, fromDropdowns) {
    if (!newDate) {
      this.date(today);
    } else {
      let date = dateFromString(newDate);
      let currentDate = this.date();
      if (
        currentDate &&
        currentDate.getFullYear() === date.getFullYear() &&
        currentDate.getMonth() === date.getMonth()
      )
        return;

      date.setHours(0, 0, 0, 0);

      this.date(date);
    }

    if (this.dropdowns && !fromDropdowns) {
      this.updateDropdowns();
    }
  }

  /**
   * Обновляет значения в селектах (месяц, год)
   */
  updateDropdowns() {
    let date = this.date();
    this.dropdownMonth(date.getMonth());
    this.dropdownYear(date.getFullYear());
  }

  /**
   * Рассчитывает дни текущего отображаемого месяца
   * и группирует их по неделям
   * (отображается 6 недель, чтобы все месяцы были равны по высоте)
   */
  calculateWeeks(date) {
    let _startOfMonth = startOfMonth(date);
    let _startOfWeek = startOfWeek(_startOfMonth);
    let _endOfMonth = endOfMonth(_startOfMonth);
    let _endOfWeek = endOfWeek(_endOfMonth);

    let days = [];

    let currentMoment = +_startOfWeek;
    let finishMoment = +_endOfWeek;
    let startMonthMoment = +_startOfMonth;
    let endMonthMoment = +_endOfMonth;

    while (currentMoment <= finishMoment) {
      let currentDate = new Date(currentMoment);

      let inactive = false;

      if (this.start && currentMoment < this.start) inactive = true;
      else
        inactive =
          currentMoment < startMonthMoment || currentMoment > endMonthMoment;

      days.push({
        moment: currentMoment,
        dateObject: currentDate,
        date: currentDate.getDate(),
        inactive,
      });

      let nextDate = new Date(currentMoment).setDate(currentDate.getDate() + 1);
      currentMoment = +nextDate;
    }

    if (days.length < 6 * 7) {
      for (let i = 0; i < 7; i++) {
        let currentDate = new Date(currentMoment);
        days.push({
          moment: currentMoment,
          dateObject: currentDate,
          date: currentDate.getDate(),
          inactive:
            currentMoment < startMonthMoment || currentMoment > endMonthMoment,
        });

        let nextDate = new Date(currentMoment).setDate(
          currentDate.getDate() + 1
        );
        currentMoment = +nextDate;
      }
    }

    let weeks = [];
    let index = 0;

    while (days.length > index) {
      let week = days.slice(index, index + 7);
      weeks.push(week);
      index = index + 7;
    }

    return weeks;
  }

  onDayClick(day) {
    this.emitEvent(DAY_SELECT, day.dateObject);
  }

  onDayHover(day) {
    this.emitEvent(DAY_HOVER, day.dateObject);
  }

  prevMonth() {
    let newDate = new Date(+this.date());
    newDate.setMonth(newDate.getMonth() - 1);
    this.date(newDate);
    this.emitEvent(MONTH_CHANGE, newDate);
  }

  nextMonth() {
    let newDate = new Date(+this.date());
    newDate.setMonth(newDate.getMonth() + 1);
    this.date(newDate);
    this.emitEvent(MONTH_CHANGE, newDate);
  }
}
