import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-stats-container');

    if (params.adaptive) element.classList.add('fc-stats-container--adaptive');

    this.padding = params.padding || 20;
  }
}
