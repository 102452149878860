import './style';
ko.bindingHandlers.timeInput = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {
    let config = valueAccessor() || {};
    let isDense = config.dense;
    let regex = isDense ?  '\\d\\d:\\d\\d' : '\\d\\d : \\d\\d';
    element.classList.add('time-input');
    new Inputmask({ regex, showMaskOnHover: true }).mask(element);
    element.placeholder = isDense ? '00:00' : '00 : 00';

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      Inputmask.remove(element);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext,
  ) {},
};
