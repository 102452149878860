import { FComponent } from 'Components/f-component';
import '../../fc-select-result-item';
export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-select__wrapper');

    this.inline = params.inline;

    this.itemComponent = params.itemComponent || 'fc-select-result-item';

    this.fieldSearch = ko.observable(null);

    this.disabled = params.disabled;
    this.readMode = params.readMode;
    this.loading = params.loading;
    this.placeholder = params.placeholder;
    this.clearable = params.clearable;
    this.selected = params.selected;
    this.active = params.active;

    this.query = params.query;
    this.showSearch = ko.observable(false);
    this.showSearch.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          if (this.fieldSearch()) this.fieldSearch().focus();
        });
      }
    });
    this.active.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          if (this.fieldSearch()) this.fieldSearch().focus();
        });
      } else {
        this.query('');
      }
    });
    this.searchPlaceholder = params.searchPlaceholder;

    this.showPlaceholder = ko.pureComputed(() => {
      if (this.query()) return false;
      if (this.selected().length) return false;
      if (!this.inline) return true;
      return true;
    });
  }

  removeOption(option) {
    this.emitEvent('removeOption', option);
  }

  removeAll() {
    this.emitEvent('removeAll', option);
  }

  activate(event) {
    if (ko.toJS(this.disabled)) return;

    if (event.target.closest('.fc-select-result__remove')) return;
    if (event.target.closest('.fc-select-field__remove')) return;

    this.showSearch(true);

    setTimeout(() => {
      this.emitEvent('activate');
    });
  }
}
