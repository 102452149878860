/**
 * Автокомплит
 *
 * getOptions: функция
 * value
 *
 * disabled
 * searchPlaceholder
 * invalid
 * valid
 * placeholder
 * dropdownClass
 * fields[] - поля, по которым производится поиск
 *
 * слоты:
 *   - option
 *   - result
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';
registerComponent('fc-autocomplete', {
  model,
  template
});
