/**
 * Многострочное поле ввода
 * value
 * height
 * maxlength
 * counter
 * invalid
 * valid
 * disabled
 * readMode
 * ...attrs - любые атрибуты для textarea
 */

import { ViewModel as model } from './model';
import template from './template.html';
import { autoHeightBinding } from './auto-height';
import './style.less';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbAutoHeight', autoHeightBinding);
registerComponent('fc-textarea', { model, template });
