export function ApiUrl(url, params = {}) {
  if (!params.dropToken) {
    params['access-token'] = APIConfig.apiKey;
  } else {
    delete params.dropToken;
  }
  
  return `${APIConfig.baseApiUrlPath}${url}?${$.param(params)}`;
}


window.ApiUrl = ApiUrl;
