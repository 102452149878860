/**
 * Форма редактирования названия
 *
 * name
 * disabled
 *
 * events:
 *   - name.change
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-name-form', { model, template });
