import "./style";

ko.bindingHandlers._tooltip = {
  init: function (element, valueAccessor, allBindings) {
    const options = {};

    function updateTitle(title) {
      $(element)
        .attr("data-original-title", title || $(element).attr("title"))
        .tooltip("update")
        .tooltip("show")
        .removeAttr("title");
    }

    const isClick =
      allBindings.get("tooltipClick") ||
      element.classList.contains("btn-question");

    let container = element.closest("[data-tooltip-container]");
    if (container) {
      options.container = container;
    }

    if (isClick) {
      options.trigger = "hover focus click";
    } else {
      options.trigger = "hover focus";
    }

    if (allBindings.has("tooltipPlacement")) {
      options.placement = allBindings.get("tooltipPlacement");
    }

    if (allBindings.has("tooltipText")) {
      let content = allBindings.get("tooltipText");
      if (ko.isObservable(content)) {
        content = ko.toJS(content);
      }
      let el = document.createElement("div");
      el.innerHTML = content;
      content = el.textContent;
      el = null;
      $(element).attr("title", content);
    }

    $(element).tooltip(options);

    const viewModel = new (function () {
      this.updateTitle = updateTitle;
    })();

    if (valueAccessor() !== undefined) {
      if (ko.isObservableArray(valueAccessor())) {
        valueAccessor().push(viewModel);
      } else {
        valueAccessor()(viewModel);
      }
    }

    let hideCb = (e) => {
      try {
        if (isClick) {
          if (!element.contains(e.target)) $(element).tooltip("hide");
        } else {
          $(element).tooltip("hide");
        }
      } catch (e) {}
    };

    document.addEventListener("click", hideCb);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      if (valueAccessor() !== undefined) {
        if (ko.isObservableArray(valueAccessor())) {
          valueAccessor().remove(this);
        } else {
          valueAccessor()(null);
        }
      }

      document.removeEventListener("click", hideCb);

      try {
        $(element).tooltip("dispose");
      } catch (e) {}
    });
  },

  update(element, valueAccessor, allBindings) {
    if (allBindings.has("tooltipText")) {
      let content = allBindings.get("tooltipText");

      if (ko.isObservable(content)) content = ko.toJS(content);

      let el = document.createElement("div");
      el.innerHTML = content;
      content = el.textContent;
      el = null;

      try {
        $(element)
          .attr("data-original-title", content)
          .tooltip("update")
          .removeAttr("title");
      } catch (e) {}
    }

    let disable = allBindings.get("disable");
    let enable = allBindings.get("enable");

    if (disable || !enable) {
      try {
        $(element).tooltip("hide");
      } catch (e) {}
    }
  },
};
