import { ViewModel, template } from './model';

ko.components.register('name-edit-form', {
  viewModel: {
    createViewModel: function (params) {

      return new ViewModel(params.name, [], (newValue) => {
        if (typeof params.onEdit === 'function') params.onEdit(newValue);
      });
    },
  },
  template,
});
