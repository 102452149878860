import ee from 'event-emitter';

export class FoquzComponent {
  constructor(params = {}, element) {
    ee(this);
    if ('ref' in params) {
      this._ref = params.ref;

      if (ko.isObservable(this._ref)) {
        this._ref(this);
      } else {
        this._ref = this;
      }
    }

    this.subscriptions = [];

    if (element) {
      this.element = element;
    }

    if (element && typeof this.onElementRender == 'function') {
      ko.applyBindingsToNode(element, {
        descendantsComplete: () => {
          this.onElementRender();
        }
      })
    }
  }

  emitEvent(eventName, eventData) {
    this.emit(eventName, eventData);
    if (this.element) {
      $(this.element).trigger(eventName, eventData);
    }
  }

  get ref() {
    return this._ref;
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
    if (this.ref) {
      if (ko.isObservable(this.ref)) this.ref(null);
    }
  }
}
