ko.bindingHandlers.onEnter = {
  init: function (element, valueAccessor) {
    let callback = valueAccessor();

    if (typeof callback == 'function') {
      element._onEnterCallback = function (e) {
        if (e.key === 'Enter') {
          callback();
        }
      };
      element.addEventListener('keydown', element._onEnterCallback);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        element.removeEventListener('keydown', element._onEnterCallback);
      });
    }
  }
};
