import { ViewModel } from "./model";
import html from './template.html';
import './style';

ko.components.register('edit-form', {
  viewModel: {
    createViewModel: (params, componentInfo) => {
      let element = componentInfo.element;
      element.classList.add('edit-form');
      return new ViewModel(params, element)
    }
  },
  template: html
})
