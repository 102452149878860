export class ViewModel {
  constructor(params, element) {
    this.subscriptions = [];

    this.progress = params.progress;
  }

  dispose() {
    this.subscriptions.forEach(s => s.dispose());
  }
}
