import { MOBILE_MAX, TABLET_MAX } from "./points";

let points = {
  tablet: MOBILE_MAX + 1,
  desktop: TABLET_MAX + 1
};

const mediaWatcher = (cb, config = {}) => {
  let state = ko.observable();

  let tabletQuery = `(min-width: ${config.tablet || points.tablet}px)`;
  let desktopQuery = `(min-width: ${config.desktop || points.desktop}px)`;

  let tabletMediaQuery = window.matchMedia(tabletQuery);
  let desktopMediaQuery = window.matchMedia(desktopQuery);

  function handleTabletChange(e) {
    if (e.matches) {
      state('tablet');
    } else {
      state('mobile');
    }
  }

  function handleDesktopChange(e) {
    if (e.matches) {
      state('desktop');
    } else {
      state('tablet');
    }
  }

  tabletMediaQuery.addListener(handleTabletChange);
  desktopMediaQuery.addListener(handleDesktopChange);

  let subscription = null;

  if (typeof cb === 'function') {
    subscription = state.subscribe(cb);
  }

  let currentState = 'mobile';

  if (desktopMediaQuery.matches) currentState = 'desktop';
  else if (tabletMediaQuery.matches) currentState = 'tablet';

  state(currentState);

  const dispose = () => {
    tabletMediaQuery.removeListener(handleTabletChange);
    desktopMediaQuery.removeListener(handleDesktopChange);

    if (subscription) subscription.dispose();
  };

  return {
    state,
    dispose
  };
};

export { mediaWatcher };
