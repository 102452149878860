import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add("fc-select-with-except");

    const { value, selected } = params;

    this.state = ko.computed(() => {
      if (!selected().length) return "inactive";
      if (value()) return "selected";
      return "active";
    });

    this.disabled = ko.computed(() => {
      return this.state() === "inactive";
    });

    this.selected = ko.computed(() => {
      return this.state() === "selected";
    });

    this.tooltipText = ko.computed(() => {
      const state = this.state();

      if (state === "inactive")
        return "Фильтр по всем значениям в справочнике, кроме выбранных";
      if (state === "selected")
        return "Выключить фильтр по всем значениям в справочнике, кроме выбранных";
      return "Включить фильтр по всем значениям в справочнике, кроме выбранных";
    });

    this.onClick = () => {
      if (this.state() === "inactive") return;
      value(!value());
    };
  }
}
