import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-horizontal-table');

    this.loading = params.loading;

    this.headerTable = ko.observable(null);
    this.content = ko.observable(null);
    this.scroll = ko.observable(null);
    this.empty = ko.pureComputed(() => {
      if (ko.toJS(this.loading)) return false;
      if (ko.toJS(params.empty)) return true;
      return false;
    })
    this.emptyText = params.emptyText || 'Ничего не найдено';

    this.headers = [];

    this.originalTable = null;
    this.throttledSync = _.throttle(() => this.sync(), 200);
    this.observer = null;
    this.tableWidth = ko.observable(null);
    this.cellWidth = ko.pureComputed(() => {
      let tableWidth = this.tableWidth();
      if (!tableWidth) return 'auto';
      return Math.min(tableWidth, 350) + 'px';
    });
  }

  cloneHeaderRow(row) {
    let rowClone = document.createElement('tr');

    let cell = row.children[0];

    let cellClone = cell ? cell.cloneNode(true) : document.createElement('th');
    if (cell) {
      cell.classList.add('fc-horizontal-table-original-table__hidden');
    }
    cellClone.classList.add('fc-horizontal-table-header__cell');
    rowClone.appendChild(cellClone);

    return {
      original: row,
      row: rowClone,
      cell: cellClone
    };
  }

  onElementRender() {
    let table = this.content().querySelector('table');
    table.classList.add('fc-horizontal-table-original-table');
    this.originalTable = table;

    let headerTable = this.headerTable();

    let tableHead = table.querySelector('thead');
    let tableBody = table.querySelector('tbody');

    if (tableHead) {
      let tableHeadClone = document.createElement('thead');
      [...tableHead.querySelectorAll('tr')].forEach((row) => {
        let clone = this.cloneHeaderRow(row);
        tableHeadClone.appendChild(clone.row);
        this.headers.push(clone);
      });

      headerTable.appendChild(tableHeadClone);
    }

    let tableBodyClone = document.createElement('tbody');

    [...tableBody.querySelectorAll('tr')].forEach((row) => {
      let clone = this.cloneHeaderRow(row);
      tableBodyClone.appendChild(clone.row);
      this.headers.push(clone);
    });

    headerTable.appendChild(tableBodyClone);

    setTimeout(() => {
      this.sync();
    });

    window.addEventListener('resize', this.throttledSync);

    this.observer = new MutationObserver(() => {
      this.sync();
    }).observe(table, {
      childList: true,
      subtree: true
    });

    let scrollRef = this.scroll();

    let scroll = scrollRef.scroll();

    this.tableWidth(scroll.width());

    this.addSubscription(
      scroll.width.subscribe((v) => {
        this.tableWidth(v);
      })
    );

    this.addSubscription(
      scroll.right.subscribe((v) => {
        if (v) this.emitEvent('reachend');
      })
    );
  }

  sync() {
    this.headers.forEach((h) => {
      let height = h.original.offsetHeight;
      h.cell.style.height = height + 'px';
      let newHeight = h.cell.offsetHeight;
      if (newHeight > height) {
        h.original.style.height = newHeight + 'px';
      }
    });
  }

  dispose() {
    if (this.observer) {
      this.observer.unobserve(this.originalTable);
    }

    window.removeEventListener('resize', this.throttledSync);
  }
}
