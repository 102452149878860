/**
 * Превью медиа-файла
 *
 * url
 * preview
 * alt
 * fancybox: true|false
 * gallery[{src}]
 * index
 * size: sm(35), lg (105) | число
 * width
 * height
 * cover: true|false
 */

import { binding } from './binding'
import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerBinding } from 'Utils/engine/register-binding';
import { registerComponent } from 'Utils/engine/register-component';

registerBinding('fbFancybox', binding)
registerComponent('fc-media', { model, template });
