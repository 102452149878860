export const NPSNeutralColor = 'rgb(172, 172, 182)';

export function NPSGradient(startColor, endColor) {
  startColor = ko.unwrap(startColor);
  endColor = ko.unwrap(endColor);
  let left = utils.color.getGradient({
    startColor: startColor,
    endColor: NPSNeutralColor,
    count: 7,
  });
  let right = utils.color.getGradient({
    startColor: NPSNeutralColor,
    endColor: endColor,
    count: 5,
  });

  return [...left, ...right.slice(1)].map(
    (c) => {
      return `rgb(${Math.round(c[0])}, ${Math.round(c[1])}, ${Math.round(c[2])})`;
    },
  );
}

export function NPSLabel(value) {
  if (value <= 6) return 'Критик';
  if (value <= 8) return 'Нейтрал';
  return 'Промоутер';
}

export function NPSGroups(stats) {
  let critic = stats.slice(0, 7);
  let neutral = stats.slice(7, 9);
  let promoter = stats.slice(9);

  return {
    critic: critic.reduce((a, i) => a + i, 0),
    neutral: neutral.reduce((a, i) => a + i, 0),
    promoter: promoter.reduce((a, i) => a + i, 0),
  }
}
