import { FComponent } from 'Components/f-component';


export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    this.show = params.show;
    const delay = params.delay || 3000;
    this.text = params.text || '';
    this.mode = params.mode || 'default';

    this.show.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          if (!this.show()) {
            return;
          }
          this.show(false);
        }, delay);
      }
    });
  }
}
