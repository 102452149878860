import './fc-label';
import './fc-error';
import './fc-input';
import './fc-number-input';
import './fc-number-range';
import './fc-auto-input';
import './fc-textarea';
import './fc-select';
import './fc-select-except';
import './fc-autocomplete';
import './fc-question';
import './fc-check';
import './fc-switch';
import './fc-calendar';
import './fc-date-picker';
import './fc-period-picker';
import './fc-range';
import './fc-radio-group';
import './fc-button-group';
