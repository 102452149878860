let nextId = 0;

let margins = {};

const starTemplate = ` <svg  class="rating__star f-rating__star" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1743 17.7605C14.5513 17.7126 14.8195 17.3567 14.7729 16.9657C14.7718 16.9555 13.891 11.4042 13.891 11.4042L17.733 7.46303C18.0029 7.18604 18.0047 6.73468 17.7375 6.45484C17.634 6.34653 17.5 6.2755 17.3547 6.25207L12.007 5.4051L9.58294 0.392906C9.41208 0.0409797 8.99857 -0.100714 8.65927 0.0761368C8.52742 0.145031 8.42042 0.255829 8.3538 0.392906L5.92969 5.4051L0.582019 6.25207C0.206477 6.31279 -0.0503274 6.67715 0.008351 7.06636C0.0307869 7.21693 0.0994751 7.35579 0.203716 7.46303L4.04577 11.4042L3.16801 16.9345C3.10484 17.323 3.35751 17.6906 3.73201 17.7563C3.88078 17.7822 4.03369 17.7566 4.16727 17.6834L8.9682 15.1074L13.7695 17.6834C13.8937 17.7516 14.0349 17.7783 14.1743 17.7605Z" fill="currentColor" />
</svg>`;
const emptyStarTemplate = `<svg class="rating__star f-rating__star" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.1914 15.7689L9.28779 13.6753C9.08893 13.5681 8.85175 13.5681 8.65289 13.6753L4.74923 15.7689L5.46491 11.2738C5.50116 11.0454 5.42797 10.8131 5.26916 10.6505L2.14195 7.44371L6.48476 6.76457C6.70606 6.73047 6.89767 6.58662 6.99814 6.37953L8.97016 2.30432L10.9425 6.37953C11.043 6.58662 11.2343 6.73047 11.4559 6.76457L15.7984 7.44371L12.6715 10.6505C12.5124 10.8131 12.4395 11.0454 12.4754 11.2738L13.1914 15.7689ZM14.1775 17.7644C14.5545 17.7165 14.8227 17.3605 14.7761 16.9695C14.7751 16.9592 14.7737 16.9485 14.772 16.9382L13.894 11.4067L17.7369 7.46467C18.0069 7.18761 18.0086 6.73616 17.7414 6.45626C17.6378 6.34792 17.5039 6.27688 17.3585 6.25344L12.0097 5.40629L9.58504 0.392992C9.41415 0.0409887 9.00055 -0.100736 8.66117 0.0761536C8.52929 0.145062 8.42226 0.255885 8.35563 0.392992L5.93099 5.40629L0.582146 6.25344C0.206522 6.31418 -0.0503384 6.67861 0.00835283 7.06791C0.0307936 7.21852 0.0994969 7.3574 0.20376 7.46467L4.04666 11.4067L3.1687 16.9382C3.10553 17.3268 3.35824 17.6944 3.73283 17.7601C3.88163 17.7861 4.03457 17.7605 4.16818 17.6873L8.97016 15.1107L13.7725 17.6873C13.8968 17.7555 14.038 17.7822 14.1775 17.7644Z" fill="currentColor" />
</svg>
`;

const starTemplateThin = `<svg class="rating__star f-rating__star" width="18" height="18" viewBox="0 0 230 227" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M181.756 226.806C186.589 226.194 190.028 221.65 189.431 216.657C189.418 216.526 178.122 145.634 178.122 145.634L227.389 95.3049C230.85 91.7676 230.872 86.0036 227.446 82.43C226.118 81.0469 224.401 80.1399 222.538 79.8405L153.965 69.0246L122.881 5.01751C120.69 0.523323 115.388 -1.28614 111.037 0.972288C109.346 1.85208 107.974 3.267 107.12 5.01751L76.0358 69.0246L7.46316 79.8405C2.64763 80.616 -0.645342 85.2689 0.107084 90.2393C0.394776 92.1622 1.27556 93.9353 2.61222 95.3049L51.8784 145.634L40.623 216.258C39.8131 221.219 43.0529 225.913 47.8552 226.752C49.7628 227.083 51.7235 226.757 53.4364 225.822L114.998 192.925L176.564 225.822C178.158 226.693 179.968 227.033 181.756 226.806Z" fill="currentColor"/>
        </svg>`;
const emptyStarTemplateThin = `<svg class="rating__star f-rating__star" width="18" height="18" viewBox="0 0 230 228" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1" fill="white">
<path d="M189.431 217.642C190.028 222.658 186.589 227.222 181.756 227.837C179.968 228.065 178.158 227.723 176.564 226.849L114.998 193.802L53.4364 226.849C51.7235 227.787 49.7628 228.115 47.8552 227.783C43.0529 226.94 39.8131 222.225 40.623 217.241L51.8784 146.296L2.61222 95.7381C1.27556 94.3623 0.394776 92.5811 0.107084 90.6495C-0.645343 85.6565 2.64763 80.9825 7.46316 80.2035L76.0358 69.3383L107.12 5.04032C107.974 3.28185 109.346 1.8605 111.037 0.976707C115.388 -1.29199 120.69 0.525701 122.881 5.04032L153.965 69.3383L222.538 80.2035C224.401 80.5041 226.118 81.4152 227.446 82.8047C230.872 86.3945 230.85 92.1847 227.389 95.7381L178.122 146.296L189.378 217.241C189.4 217.373 189.418 217.51 189.431 217.642Z"/>
</mask>
<path d="M189.431 217.642C190.028 222.658 186.589 227.222 181.756 227.837C179.968 228.065 178.158 227.723 176.564 226.849L114.998 193.802L53.4364 226.849C51.7235 227.787 49.7628 228.115 47.8552 227.783C43.0529 226.94 39.8131 222.225 40.623 217.241L51.8784 146.296L2.61222 95.7381C1.27556 94.3623 0.394776 92.5811 0.107084 90.6495C-0.645343 85.6565 2.64763 80.9825 7.46316 80.2035L76.0358 69.3383L107.12 5.04032C107.974 3.28185 109.346 1.8605 111.037 0.976707C115.388 -1.29199 120.69 0.525701 122.881 5.04032L153.965 69.3383L222.538 80.2035C224.401 80.5041 226.118 81.4152 227.446 82.8047C230.872 86.3945 230.85 92.1847 227.389 95.7381L178.122 146.296L189.378 217.241C189.4 217.373 189.418 217.51 189.431 217.642Z" stroke="currentColor" stroke-width="12" mask="url(#path-1-inside-1)"/>
</svg>`;

ko.components.register('rating', {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      const $element = $(componentInfo.element);

      $element.addClass(['rating', 'f-rating']);

      return new (function () {
        this.id = nextId++;

        this.max = params.max || 5;
        this.stars = Array(this.max)
          .fill()
          .map((s, i) => i);
        this.color = params.color || '#F8CD1C';
        this.size = params.size;
        this.thin = params.thin;

        if (this.size) {
          $element.addClass('f-rating--' + this.max);
        }

        componentInfo.element.style.setProperty(
          '--f-rating-stars-count',
          this.max
        );

        this.isCanChange = params.isCanChange || false;
        this.value = params.value;
        this.onChange = params.onChange;

        if (typeof this.onChange == 'function' && ko.isObservable(this.value)) {
          this.value.subscribe((v) => {
            this.onChange(v);
          });
        }

        this.scrollToView = () => {
          return;
          let target = document.querySelector('.survey-questions__variants');
          if (this.isCanChange && target) {
            setTimeout(() => {
              target.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              });
            }, 350);
          }
        };
      })();
    }
  },
  template: `
    <!-- ko foreach: stars -->
    <!-- ko if: ko.unwrap($component.value) >= $data + 1 -->
    <div
      class="f-rating__item"
      data-bind="
        click: function() {
          if (!$component.isCanChange) {
            return;
          }
          if ($component.value() === $data + 1) {
            $component.value(0);
            return;
          }
          $component.value($data + 1);
          $component.scrollToView();
        },
        style: {
          color: $component.color,
        },
      "
    >
      <!-- ko if: $parent.thin -->
      ${starTemplateThin}
      <!-- /ko -->
      <!-- ko ifnot: $parent.thin -->
      ${starTemplate}
      <!-- /ko -->
    </div>
    <!-- /ko -->
    <!-- ko if: ko.unwrap($component.value) < $data + 1 -->
    <div
      class="f-rating__item"
      data-bind="
        click: function() {
          if (!$component.isCanChange) {
            return;
          }
          if ($component.value() === $data + 1) {
            $component.value(0);
            return;
          }
          $component.value($data + 1);
          $component.scrollToView();
        },
        style: {
          color: $component.color,
        },
      "
    >
      <!-- ko if: $parent.thin -->
      ${emptyStarTemplateThin}
      <!-- /ko -->
      <!-- ko ifnot: $parent.thin -->
      ${emptyStarTemplate}
      <!-- /ko -->
    </div>
    <!-- /ko -->
    <!-- /ko -->
  `
});
