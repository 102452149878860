import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-textarea');

    this.textarea = ko.observable(null);

    const {
      value,
      height,
      maxheight,
      maxlength,
      counter,
      invalid,
      valid,
      disabled,
      readMode,
      $raw,
      ...attrs
    } = params;

    this.value = value;
    this.height = height;
    this.maxHeight = maxheight;
    this.maxLength = maxlength;
    this.counter = counter;
    this.disabled = ko.computed(() => !!ko.toJS(disabled));
    this.readMode = readMode;

    this.clearable = ko.pureComputed(() => {
      return ko.toJS(clearable) && ko.toJS(value);
    });

    ko.applyBindingsToNode(element, {
      css: {
        'fc-textarea--invalid': invalid,
        'fc-textarea--valid': valid,
        'fc-textarea--disabled': this.disabled,
        'fc-textarea--counter': counter
      }
    });

    this.textareaAttrs = {
      maxlength: maxlength,
      disabled: this.disabled,
      ...attrs
    };

    this.count = ko.pureComputed(() => {
      let value = ko.toJS(this.value) || '';
      return ko.toJS(this.maxLength) - value.length;
    });
  }

  focus() {
    this.textarea().focus();
  }

  clear() {
    if (ko.isObservable(this.value)) {
      this.value('');
    } else {
      this.textarea().value = '';
    }
  }

  update() {
    $(this.textarea()).trigger('change');
  }

  onElementRender() {
    $(this.textarea()).trigger('change');
  }
}
