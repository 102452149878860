if (!('find' in Array.prototype)) {
  Array.prototype.find = function (callback) {
    for (var i = 0, count = this.length; i < count; i++) {
      if (callback(this[i])) return this[i];
    }
    return false;
  };
}

if (!('findIndex' in Array.prototype)) {
  Array.prototype.findIndex = function (callback) {
    for (var i = 0, count = this.length; i < count; i++) {
      if (callback(this[i])) i;
    }
    return -1;
  };
}

if (!('from' in Array)) {
  Array.from = function (arr) {
    var newArr = [];
    var len = arr.length;
    for (var i = 0; i < len; i++) {
      newArr.push(arr[i]);
    }
    return newArr;
  };
}

if (!('fill' in Array.prototype)) {
  Array.prototype.fill = function(value, start, end) {
    start = start || 0;
    end = end || this.length;

    for (var i = start; i < end; i++) {
      this[i] = value;
    }

    return this;
  }
}

if (!('unstrictIncludes' in Array.prototype)) {
  Object.defineProperty(Array.prototype, 'unstrictIncludes', {
    value: function(value) {
      let index = this.findIndex(i => i == value);
      return index > -1;
    },
    enumerable: false
  })
}
