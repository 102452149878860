/**
 * Форматированный вывод даты
 *
 * from
 * to
 */

import { ViewModel as model } from './model';
import template from './template.html';
import './style.less';

import { registerComponent } from 'Utils/engine/register-component';

registerComponent('fc-date-text', { model, template });
