function ViewModel(params) {
  let filialText = "";

  const { filial } = params;

  if (filial) {
    const parts = filial.split("/");
    if (parts.length === 1) {
      filialText = filial;
    } else {
      const [category, filialName] = parts;

      filialText = `<span class="f-color-service">${category}</span>/${filialName}`;
    }
  }

  return {
    filialText,
  };
}

const template = `<span data-bind="html: filialText"></span>`;

ko.components.register("filial-text", {
  viewModel: {
    createViewModel: (params) => {
      return ViewModel(params);
    },
  },
  template: template,
});
