import { dispose, init } from './plugin';

export const binding = {
  init: function (element, valueAccessor) {
    const customConfig = valueAccessor();
    const settings = {
      space: 5,
      ...(customConfig || {})
    }

    setTimeout(() => {
      init(element, settings);
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      dispose(element);
    });
  }
};
