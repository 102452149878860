import { FComponent } from '@/components/f-component';
import { dateToString } from '@/utils/date/date-to-string';
import { dateValidator } from '@/utils/validation/date';

import '@/presentation/components/fc-month-calendar';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    // settings
    this.dropdowns = params.dropdowns;
    this.placeholder = params.placeholder || '00.00.0000';
    this.clearable = params.clearable;
    this.readMode = params.readMode;
    this.inline = params.inline;
    this.disabled = params.disabled;

    this.start = params.start;


    // ошибки, валидация
    this.invalid = ko.computed(() => !!ko.toJS(params.invalid));
    this.valid = params.valid;
    this.error = ko.computed(() => ko.toJS(params.error));

    element.classList.add('fc-date-picker');
    if (this.inline) element.classList.add('fc-date-picker--inline');

    // refs
    this.popper = ko.observable(null);
    this.input = ko.observable(null);
    this.calendar = ko.observable(null);

    let validator = dateValidator({ format: 'DD.MM.YYYY' });

    // валидное значение
    this.correctValue = ko.observable('');

    // незафиксированное значение (в поле ввода)
    this.currentValue = ko.observable('').extend({
      validation: {
        validator: (v) => {
          if (!v) return true;
          v = v.replace(/_/g, '');
          return validator(v);
        },

      }
    });

    let currentValueCb = this.currentValue.subscribe((newValue) => {
      if (newValue === this.correctValue()) return;
      if (this.currentValue.isValid()) {
        this.correctValue(newValue);
      }
      if (ko.isObservable(params.value)) params.value(newValue);
    });
    this.onDispose(() => {
      currentValueCb.dispose();
    });

    // изменение значения извне
    if (ko.isObservable(params.value)) {
      this.currentValue(params.value())
      let valueCb = params.value.subscribe((v) => {
      
        this.currentValue(v);
      });
      this.onDispose(() => {
        valueCb.dispose();
      });
    }

    let correctValueCb = this.correctValue.subscribe((newValue) => {
      if (ko.isObservable(params.value)) params.value(newValue);
      if (this.calendar()) this.calendar().update(newValue);
    });
    this.onDispose(() => {
      correctValueCb.dispose();
    });

    // установка начального значения
    if (params.value) {
      this.currentValue(ko.toJS(params.value));
    }
  }

  focus() {
    if (ko.toJS(this.disabled)) return;
    this.popper().show();
  }

  onSelect(date) {
    let value = dateToString(date, 'DD.MM.YYYY');
    this.currentValue(value);
    this.popper().hide();

    if (this.inline) {
      this.input().resize();
    }
  }
}
