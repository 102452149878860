class ViewModel {
  constructor(params) {
    this.selectedOptions = params.selectedOptions;

    this.selectionTemplate = params.selectionTemplate;

    this.element = ko.observable(null);
    this.$select = ko.observable(null);
  }

  onInit() {
    this.$select = $(this.element()).find('select');
    this.$select.prop('multiple', false);
    this.$select.on('change', (v) => {
      this.closeSelect();
      let value = this.$select.val();
      if (value) {
        this.selectedOptions.push(value);
        this.$select.val(null);
        this.updateList();
      }
    });

    this.updateList();
  }

  openSelect() {
    this.$select.select2('open');
  }

  closeSelect() {
    this.$select.select2('close');
  }

  updateList() {
    let selected = this.selectedOptions();
    this.$select.find('option').each((_, opt) => {
      let isSelected = selected.includes(opt.value);
      opt.disabled = isSelected;
    });
  }

  removeValue(value) {
    this.selectedOptions.remove(value);
    this.updateList();
  }

  getItemTemplate(optionValue) {
    const option = [...this.$select.find('option')].find((o) => {
      return o.value == optionValue;
    });

    if (typeof this.selectionTemplate == 'function') {
      return this.selectionTemplate({
        text: option.textContent,
        element: option,
        value: optionValue
      });
    }

    return $('<span>')
      .text(option.textContent || optionValue)
      .get(0);
  }
}

ko.components.register('f-select', {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      return new ViewModel(params, componentInfo);
    }
  },
  template: `
    <div class="f-select tag-input" data-bind="descendantsComplete: $component.onInit.bind($component), element: element">
      <span class="f-select__value tag-input__content d-inline-flex">
        <!-- ko foreach: selectedOptions -->

          <span class="tag-input__item">

            <!-- ko template: {
              nodes: [$component.getItemTemplate($data)]
            } -->
            <!-- /ko -->

            <button class="btn btn-icon btn-default tag-input__item-remove-button" data-bind="click: function() {
              $component.removeValue($data);
            }">
            </button>
          </span>

        <!-- /ko -->
      </span>

      <button type="button" style="margin-left: 15px" class="btn btn-circle tag-input__add-tag-button f-select__button d-inline-flex" title="" data-bind="click: openSelect"></button>

      <!-- ko template: { nodes: $componentTemplateNodes } --><!-- /ko -->

    </div>
  `
});
