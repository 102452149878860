/**
 * Calendar
 * обеспечивает связь между календарем и инпутом
 * - показ календаря при клике
 * - отображение элементов календаря, размещение стрелки
 * - поля ввода для периода
 * - кнопки для периода
 * - диапазоны дат для периода
 *
 * {} - опции для DateRangePicker
 *
 * single
 * arrowPosition { anchor, offset }
 * dropdownClass
 */

import { DateRangePicker } from './date-range-picker';
import './style.less';

let inputGroupTemplate = `<div class="input-group date-input-group">
    <input class="form-control" placeholder="00.00.0000">
    <i class="date-input-group__icon"></i>
  </div>`;
let rangeContainerTemplate = `<div class="period-picker__range-container"></div>`;
let rangeTemplate = `<div class="period-picker__range"></div>`;
let arrowTemplate = `<div class="period-picker__arrow calendar-dropdown__arrow"></div>`;
let closeButtonTemplate = `<div class="calendar-dropdown__close">
<button><svg width="12" height="12" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2L23 23M23 2L2 23" stroke="#73808D" stroke-width="3" stroke-linecap="round"/>
</svg></button>
</div>`;

const views = ['modal'];

export class Calendar {
  constructor(element, options) {
    this.element = element;
    this.elements = {};

    this.view = ko.observable();

    this.single = 'single' in options ? options.single : true;

    let { single, arrowPosition, dropdownClass, ...pluginOptions } =
      options || {};

    this.separator = '-';
    this.dropdownClass = dropdownClass;
    this.customRangeLabel = _t('Произвольный');

    this.arrowPosition = arrowPosition || {
      anchor: 'center',
      offset: 0
    };

    this._selectedRangeLabel = null;

    this.options = {
      locale: {
        format: 'DD.MM.YYYY',
        separator: this.separator,
        applyLabel: _t('Сохранить'),
        cancelLabel: _t('Отменить'),
        customRangeLabel: this.customRangeLabel,
        firstDay: 1,
        monthNames: [
          _t('Январь'),
          _t('Февраль'),
          _t('Март'),
          _t('Апрель'),
          _t('Май'),
          _t('Июнь'),
          _t('Июль'),
          _t('Август'),
          _t('Сентябрь'),
          _t('Октябрь'),
          _t('Ноябрь'),
          _t('Декабрь')
        ]
      },
      opens: 'right',
      drops: 'auto',
      autoUpdateInput: false,
      buttonClasses: 'btn btn-with-icon',
      applyButtonClasses: 'btn-default btn-save',
      singleDatePicker: this.single,
      cancelButtonClasses: 'btn-danger btn-delete',
      ...pluginOptions
    };

    this.daterangepicker = new DateRangePicker($(this.element), this.options);

    this.daterangepicker.on('select.daterangepicker', ({ start, end }) => {
      if (!this.single) {
        this._setInputs(start, end);
      }

      if (this.hasRanges()) {
        this._setRange(null);
      }
    });

    this.init();
  }

  hasRanges() {
    return this.options.ranges;
  }

  setArrow() {
    let $element = $(this.element);
    let $container = this.daterangepicker.container;

    let arrowElement = $.parseHTML(arrowTemplate);
    $container.prepend(arrowElement);
    let $arrow = $(arrowElement);
    let arrowWidth = $arrow.outerWidth();

    $element.on('show.daterangepicker', () => {
      let elementRelativeX = $element.offset().left - $container.offset().left;
      let elementWidth = $element.outerWidth();
      let containerWidth = $container.outerWidth();

      function processBounds(value) {
        return Math.max(0, Math.min(value, containerWidth - arrowWidth / 2));
      }

      switch (this.arrowPosition.anchor) {
        case 'left':
          $arrow.css(
            'left',
            processBounds(
              elementRelativeX - arrowWidth / 2 + this.arrowPosition.offset
            )
          );
          break;
        case 'center':
          $arrow.css(
            'left',
            processBounds(
              elementRelativeX +
                (elementWidth - arrowWidth) / 2 +
                this.arrowPosition.offset
            )
          );
          break;
        case 'right':
          $arrow.css(
            'left',
            processBounds(
              elementRelativeX +
                elementWidth -
                arrowWidth / 2 +
                this.arrowPosition.offset
            )
          );
          break;
      }
    });
  }

  setRanges() {
    if (this.hasRanges()) {
      let daterangepicker = this.daterangepicker;
      let $container = daterangepicker.container;

      let $rangeContainer = $($.parseHTML(rangeContainerTemplate));
      $container.prepend($rangeContainer);

      Object.getOwnPropertyNames(this.options.ranges).forEach((rangeLabel) => {
        let $range = $($.parseHTML(rangeTemplate)).text(rangeLabel);
        $rangeContainer.append($range);

        $range.on('click', () => {
          this.value(rangeLabel);
        });
      });

      let $customRange = $($.parseHTML(rangeTemplate)).text(
        this.customRangeLabel
      );
      $rangeContainer.append($customRange);
      $customRange.on('click', () => {
        this.value(null);
      });

      this.element.customRange = $customRange;
      this.elements.ranges = $rangeContainer.children();
    }
  }

  isDateValid(date) {
    return (
      date.match(/\d{2}.\d{2}.\d{4}/) !== null &&
      moment(date, this.daterangepicker.locale.format, true).isValid()
    );
  }

  setPeriodInputs() {
    if (!this.single) {
      let daterangepicker = this.daterangepicker;
      let $container = daterangepicker.container;

      let startInputGroupElement = $.parseHTML(inputGroupTemplate);
      let endInputGroupElement = $.parseHTML(inputGroupTemplate);

      $container.find('.drp-calendar.left').prepend(startInputGroupElement);
      $container.find('.drp-calendar.right').prepend(endInputGroupElement);

      this.elements.startInput = $(startInputGroupElement).find('input');
      this.elements.endInput = $(endInputGroupElement).find('input');

      this.elements.startInput.mask('00.00.0000');
      this.elements.endInput.mask('00.00.0000');

      this.elements.startInput.on('input', (event) => {
        var value = event.target.value;

        if (isDateValid(value)) {
          daterangepicker.setStartDate(value);
        } else {
          daterangepicker.setStartDate(new Date());
        }

        daterangepicker.updateView();
      });

      this.elements.endInput.on('input', (event) => {
        var value = event.target.value;

        if (isDateValid(value)) {
          daterangepicker.setEndDate(value);
        } else {
          daterangepicker.setEndDate(new Date());
        }

        daterangepicker.updateView();
      });
    }
  }

  setCloseButton() {
    let closeBtn = $($.parseHTML(closeButtonTemplate));
    this.daterangepicker.container.append(closeBtn);
    closeBtn.find('button').on('click', () => {
      this.hide();
    });
  }

  setMask() {
    let $element = $(this.element);

    this.elements.mask = $('<div class="drp-mask"></div>');
    this.daterangepicker.root.prepend(this.elements.mask);

    this.elements.mask.on('click', () => {
      this.hide();
    });

    $element.on('hide.daterangepicker', () => {
      this.elements.mask.hide();
    });

    $element.on('show.daterangepicker', () => {
      if (this.view() === 'modal') this.elements.mask.show();
    });
  }

  init() {
    let $element = $(this.element);
    let daterangepicker = this.daterangepicker;
    let $container = daterangepicker.container;

    $container.addClass('period-picker');
    $container.addClass('calendar-dropdown');

    if (this.options.singleDatePicker)
      $container.addClass('calendar-dropdown--single');
    else $container.addClass('calendar-dropdown--multiple');

    if (this.options.showDropdowns)
      $container.addClass('calendar-dropdown--dropdowns');

    $container.addClass(this.dropdownClass);

    this.view.subscribe((v) => {
      this.daterangepicker.root.attr('data-mode', v);
    });

    this.setArrow();
    this.setRanges();
    this.setPeriodInputs();
    this.setMask();
    this.setCloseButton();

    $element.on(Calendar.events.forceUpdate, () => {
      this.value($element.val());
    });

    $element.on('apply.daterangepicker', (...params) => {
      $element.val(this.value());
      $element.trigger(Calendar.events.apply, params);
    });

    this.value($element.val());
  }

  value(_value) {
    if (_value === undefined) {
      var startDateFormatted = this.daterangepicker.startDate.format(
        this.daterangepicker.locale.format
      );
      var endDateFormatted = this.daterangepicker.endDate.format(
        this.daterangepicker.locale.format
      );

      if (this._selectedRangeLabel !== null) {
        return this._selectedRangeLabel;
      } else {
        if (startDateFormatted !== endDateFormatted) {
          return (
            startDateFormatted +
            this.daterangepicker.locale.separator +
            endDateFormatted
          );
        } else {
          return startDateFormatted;
        }
      }
    } else {
      var startDate = null;
      var endDate = null;
      var rangeLabel = null;

      if (_value !== null) {
        var tokens = _value.split(this.daterangepicker.locale.separator);

        if (tokens.length === 2) {
          startDate = moment(
            tokens[0],
            this.daterangepicker.locale.format,
            true
          );
          endDate = moment(tokens[1], this.daterangepicker.locale.format, true);
        } else if (tokens.length === 1) {
          startDate = moment(
            tokens[0],
            this.daterangepicker.locale.format,
            true
          );

          if (startDate.isValid()) {
            endDate = startDate;
          } else if (this.hasRanges()) {
            var range = this.options.ranges[tokens[0]];

            if (range) {
              rangeLabel = tokens[0];
              startDate = range[0];
              endDate = range[1];
            }
          }
        }
      } else {
        startDate = moment();
        endDate = moment();
      }

      if (startDate !== null && endDate !== null) {
        this.daterangepicker.setStartDate(startDate);
        this.daterangepicker.setEndDate(endDate);

        this.daterangepicker.updateView();

        if (!this.single) {
          this._setInputs(startDate, endDate);
        }

        if (this.hasRanges()) {
          this._setRange(rangeLabel);
        }
      }
    }
  }

  hide() {
    this.daterangepicker.hide();
  }

  _setRange(rangeLabel) {
    this._selectedRangeLabel = rangeLabel;

    let $ranges = this.elements.ranges;
    let $customRange = this.element.customRange;

    $ranges.removeClass('period-picker__range--active');

    if (rangeLabel !== null) {
      $ranges
        .filter(':contains("' + rangeLabel + '")')
        .addClass('period-picker__range--active');
    } else {
      $customRange.addClass('period-picker__range--active');
    }
  }

  _setInputs(startDate, endDate) {
    this.elements.startInput.val(this._formatDateToInput(startDate));
    this.elements.endInput.val(this._formatDateToInput(endDate));
  }

  _formatDateToInput(date) {
    return date !== null ? date.format(this.daterangepicker.locale.format) : '';
  }

  setView(view) {
    if (view && views.includes(view)) {
      this.view(view);
    } else this.view(null);
  }
}

Calendar.events = {
  forceUpdate: 'calendar.events.forceupdate',
  apply: 'calendar.events.apply'
};
