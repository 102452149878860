export function dateToString(dateObject, format = 'DD.MM.YYYY') {
  let month = dateObject.getMonth() + 1;
  month = ('' + month).padStart(2, '0');

  let date = dateObject.getDate();
  date = ('' + date).padStart(2, '0');

  let result = format.replace('DD', date);
  result = result.replace('MM', month);
  result = result.replace('YYYY', dateObject.getFullYear());

  return result;
}
