export function maxWidthModifier({ width }) {
  return {
    name: 'maxWidth',
    enabled: !!width,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.maxWidth = `${width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.maxWidth = `${width}px`;
    }
  };
}
