export class ViewModel {
  constructor(params, element) {
    console.log('NUMBER INTERVAL', params);
    this.interval = params.interval;
    this.formControlErrorStateMatcher =
      params.formControlErrorStateMatcher ||
      commonFormControlErrorStateMatcher();

    this.min = params.min || 0;
    this.max = params.max || Infinity;

    this.dense = params.dense;
  }
}
