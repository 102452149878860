import { FComponent } from "@/components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    this.target = params.target;

    this.options = {
      arrow: false,
      placement: "top-end",
      ...(params.options || {}),
    };

    this.mode = params.mode;

    const delay = params.delay || 3000;

    this.show = params.show;

    let _timeout = null;
    this.show.subscribe((v) => {
      if (v) {
        if (_timeout) clearTimeout(_timeout);
        _timeout = setTimeout(() => {
          this.show(false);
        }, delay);
      }
    });
  }
}
