export function getCalendarRanges() {
  return {
    [_t('Сегодня')]: [moment(), moment()],
    [_t('С начала недели')]: [moment().startOf('isoWeek'), moment()],
    [_t('Прошлая неделя')]: [
      moment().subtract(1, 'weeks').startOf('isoWeek'),
      moment().subtract(1, 'weeks').endOf('isoWeek')
    ],
    [_t('Последние 7 дней')]: [moment().subtract(6, 'days'), moment()],
    [_t('Текущий месяц')]: [moment().startOf('month'), moment()],
    [_t('Прошлый месяц')]: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ],
    [_t('Последний месяц')]: [moment().subtract(29, 'days'), moment()]
  };
}
