ko.bindingHandlers.autosizeInput = {
  init: function init(element, valueAccessor) {
    // setTimeout: при перерендеринге, страница почему-то прокручивается к элементу
    // пример: форма вопросов, тип простая матрица
    // если инициализировать асинхронно, то все нормально

    var instanceObservable;
    if (valueAccessor() !== undefined) {
      instanceObservable = valueAccessor();
    }

    setTimeout(() => {
      $(element).autosizeInput({ space: 0 });
      if (instanceObservable) {
        instanceObservable($(element).data('autosizeInputInstance'));
      }
    }, 10);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      let instance =  $(element).data('autosizeInputInstance');
      if (instance) instance.dispose();
      if (instanceObservable) {
        instanceObservable(null);
      }
    });
  }
};
