

ko.bindingHandlers.fancyboxGalleryItem = {
  init: function (element, valueAccessor) {
    let { gallery, index, noCursor } = valueAccessor();

    if (!noCursor) element.classList.add('cursor-zoom-in');

    element.gallery = gallery;
    element.index = index;

    const clickHandler = () => {
      let list = element.gallery;

      if (ko.isObservable(list)) {
        list = list();
      } else if (typeof list == 'function') {
        list = list();
      }

      $.fancybox.open(list, {
        index: ko.toJS(element.index),
        loop: false,
        buttons: ['rotate', 'zoom', 'slideShow', 'close'],
        afterLoad : function() {
          $('.fancybox-video').removeProp('controlslist');
          const href = this.src;
          const ext = href.substr(href.lastIndexOf('.') + 1).toLowerCase();
          const attachment_movie = [ 'mov' ];
          if ( $.inArray(ext, attachment_movie) > -1 ) {
            $('.fancybox-video').prop('src', href);
          }
        },
      });
    };

    $(element).on('click', clickHandler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('click', clickHandler);
    });
  },
  update: function (element, valueAccessor) {
    let { gallery, index } = valueAccessor();
    element.gallery = gallery;
    element.index = index;
  }
};
