import Inputmask from "inputmask/lib/inputmask";

ko.bindingHandlers.phoneInput = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let config = valueAccessor() || {};

    let placeholder =
      "placeholder" in config ? config.placeholder : "+7 (___) ___ ____";

    $(element).attr("maxlength", 25).attr("placeholder", placeholder);

    new Inputmask({ mask: "+7 (999) 999 9999", showMaskOnHover: true }).mask(
      element
    );

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Inputmask.remove(element);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let config = valueAccessor() || {};

    let placeholder =
      "placeholder" in config ? config.placeholder : "+7 (___) ___ ____";

    $(element).attr("placeholder", placeholder);
  },
};
