import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add("fc-number-input");

    this.input = ko.observable(null);

    const { value, invalid, valid, disabled } = params;

    this.value = value;
    this.localValue = ko.observable(ko.toJS(value));

    this.disableDecrement = ko.computed(() => {
      return this.value() <= 0;
    })

    this.value.subscribe((v) => {
      if (this.localValue() != v) this.localValue(v);
    });
    this.localValue.subscribe((v) => {
      const newValue = v === "" ? v : parseInt(v);
      if (v !== newValue) this.localValue(newValue);
      else if (this.value() !== newValue) this.value(newValue);
    });

    this.disabled = ko.computed(() => {
      return !!ko.toJS(disabled);
    });

    ko.applyBindingsToNode(element, {
      css: {
        "fc-number-input--invalid": invalid,
        "fc-number-input--valid": valid,
        "fc-number-input--disabled": this.disabled,
      },
    });
  }

  focus() {
    this.input().focus();
  }

  onElementRender() {
    // this.element.addEventListener("click", () => this.focus());
  }

  getInput() {
    return this.input();
  }

  increment() {
    const newValue = (parseInt(this.localValue()) || 0) + 1;
    
    this.localValue(newValue);
  }

  decrement() {
    const newValue = (parseInt(this.localValue()) || 0) - 1;
    this.localValue(newValue);
  }
}
