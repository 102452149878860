ko.bindingHandlers.mediaTrigger = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let config = valueAccessor();
    let action = config.action;

    let mql = window.matchMedia(config.query);
    if (typeof action == 'function') {
      let cb = (event) => {
        if (event.matches) {
          action(element);
        }
      };

      if (mql.matches) action(element);
      mql.addListener(cb);

      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        mql.removeListener(cb);
      });
    }
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
