export function endOfWeek(date) {
  let endOfWeek = new Date(+date);
  let endDayOfWeek = endOfWeek.getDay();

  if (endDayOfWeek === 0) return endOfWeek;

  let diff = 7 - endDayOfWeek;
  endOfWeek.setDate(endOfWeek.getDate() + diff);

  return endOfWeek;
}
