import './style';

ko.bindingHandlers.autosizeTextarea = {
  init: function init(element, valueAccessor, allBindings) {
    element.classList.add('autosize-textarea');

    let defaultHeight = allBindings.get('minHeight') || 72;
    let maxHeight = allBindings.get('maxHeight');
    if (maxHeight) element.style.maxHeight = maxHeight + 'px';
    else maxHeight = Infinity;

    element.style.minHeight = defaultHeight + 'px';

    let inputHandler = function () {
      element.style.height = defaultHeight + 'px';
      let height = element.scrollHeight;

      element.style.height = height + 'px';
      if (height > maxHeight) {
        element.style.overflowY = 'auto';
      } else {
        element.style.overflowY = 'hidden';
      }
    };

    element.style.overflowY = 'hidden';
    element.style.height = element.scrollHeight + 'px;';

    $(element).on('input change', inputHandler);

    let ref = allBindings.get('ref');
    if (ko.isObservable(ref)) {
      ref({
        update: () => $(element).trigger('input')
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('input', inputHandler);
    });

    setTimeout(() => {
      $(element).trigger('input');
    }, 4);
  }
};
