
ko.bindingHandlers.stickyHeader = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {


    let observer = new IntersectionObserver(([entry]) => {
      element.classList.toggle('stucky', entry.intersectionRatio < 1);
      $(element).trigger(entry.intersectionRatio < 1 ? 'stuck' : 'unstuck')
    }, {
      threshold: [0.0, 1.0]
    });
    observer.observe(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {});
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
