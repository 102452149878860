import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-stats-item');

    this.hasValue = 'value' in params;
    this.value = params.value;
    this.total = params.total;

    this.text = params.text;

    this.label = params.label;
    this.color = params.color;
    if (this.color) element.classList.add('fc-stats-item--' + this.color);

    this.adaptive = params.adaptive;
    if (this.adaptive) element.classList.add('fc-stats-item--adaptive');

    this.maxWidth = params.maxWidth;
    this.minWidth = params.minWidth;

    if ('padding' in params) {
      let padding = params.padding || 20;
      element.style.setProperty('--fc-stats-space', padding + 'px');
    }


    if (params.separator) {
      element.classList.add('fc-stats-item--separator')
    }
  }
}
