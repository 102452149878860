/**
 * Поле ввода
 * value
 * maxlength
 * counter
 * clearable
 * invalid
 * valid
 * icon
 * appendIcon
 * disabled
 * readMode
 * mask
 * ...attrs - любые атрибуты для input
 *
 *
 * events:
 *  - enter
 *  - clear
 */

import { ViewModel as model } from './model';

import template from './template.html';
import './style.less';


import { registerComponent } from 'Utils/engine/register-component';


registerComponent('fc-input', { model, template });
