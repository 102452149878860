import { DialogWrapper } from "@/dialogs/wrapper";
import { Translator } from "@/utils/translate";

const defaultTexts = {
  cancel: "Отменить",
  confirm: "Подтвердить",
};

const MainTranslator = Translator("main");
export class ViewModel extends DialogWrapper {
  constructor(params, element) {
    super(params, element);

    this.translator = MainTranslator;

    this.texts = {
      title: ko.observable(""),
      text: ko.observable(""),
      cancel: ko.observable(""),
      confirm: ko.observable(""),
    };

    this.mode = ko.observable("default");

    this.warning = ko.observable(false);

    this.update(params);
  }

  update(params = {}) {
    Object.keys(this.texts).forEach((key) => {
      this.texts[key](
        ko.toJS(params[key]) ||
          ko.toJS(this.translator.t(defaultTexts[key] || ""))
      );
    });
    this.mode(params.mode || "default");
    this.warning(params.warning);
  }

  open(params, onReject) {
    return new Promise((res, rej) => {
      if (params) this.update(params);
      this.show();

      this.once("confirm", () => {
        res();
        this.hide("confirm");
      });

      this.modal().once("hide", ({ source }) => {
        if (source !== "confirm") {
          rej();
          if (typeof onReject == "function") onReject();
        }
      });
    });
  }

  onEnter() {
    this.onConfirm();
  }

  onConfirm() {
    this.emitEvent("confirm");
    this.hide("confirm");
  }

  onReset() {
    this.hide("reset");
  }

  dispose() {
    super.dispose();
  }
}
