ko.bindingHandlers.slider = {
  init: function init(element, valueAccessor, allBindings) {
    var subscriptions = [];

    var value = allBindings()['sliderValue'];
    var min = allBindings()['sliderMin'];
    var max = allBindings()['sliderMax'];
    var range = allBindings()['sliderRange'];
    var nonEmptyRange = allBindings()['sliderNonEmptyRange'];
    var disabled = allBindings()['disabled'] || false;
    var step = allBindings()['sliderStep'] || 1;
    // custom позволяет вводить любое значение, но оставляет шаги.
    var mode = allBindings()['sliderMode'] || 'default'; // default or custom

    var $element = $(element);

    var options = {
      range: 'min',
      min: ko.toJS(min),
      max: ko.toJS(max),
      step: mode === 'default' ? step : 1,
      disabled: ko.toJS(disabled),
      slide: function slide(_, ui) {
        if (mode === 'default') {
          if (range !== undefined && nonEmptyRange) {
            if (ui.values[0] == ui.values[1]) {
              return false;
            }
          }
        } else if (mode === 'custom') {
          var sliderValue = ui.value;
          if ((sliderValue - ko.toJS(min)) % step === 0 || sliderValue === ko.toJS(max)) {
            if (value !== undefined && ko.isObservable(value)) {
              value(sliderValue);
            }
          } else {
            return false;
          }
        }

        if (value !== undefined && ko.isObservable(value)) {
          if (range !== undefined) {
            value(ui.values);
          } else {
            value(ui.value);
          }
        }
      },
      change: function change(_, ui) {
        if (value !== undefined && ko.isObservable(value)) {
          if (range !== undefined) {
            value(ui.values);
          } else {
            value(ui.value);
          }
        }
      }
    };

    if (range !== undefined) {
      options.range = range;
      options.values = ko.utils.unwrapObservable(value);
    } else {
      options.value = ko.utils.unwrapObservable(value);
    }

    $element.slider(options);

    if (value !== undefined && ko.isObservable(value)) {
      subscriptions.push(
        value.subscribe(function (v) {
          if (range !== undefined) {
            if (!_.isEqual(v, $element.slider('values'))) {
              $element.slider('values', v);
            }
          } else {
            if (!_.isEqual(v, $element.slider('value'))) {
              $element.slider('value', v);
            }
          }
        })
      );
    }

    if (disabled && ko.isObservable(disabled)) {
      subscriptions.push(
        disabled.subscribe(v => {
          $element.slider("option", "disabled", !!v);
        })
      )
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      subscriptions.forEach(function (s) {
        return s.dispose();
      });
    });
    if (ko.isObservable(min)) {
      subscriptions.push(
        min.subscribe(newMin => {
          if (typeof newMin === 'number') {
            $element.slider("option", "min", newMin);
          }
        })
      );
    }

    if (ko.isObservable(max)) {
      subscriptions.push(
        max.subscribe(newMax => {
          if (typeof newMax === 'number') {
            $element.slider("option", "max", newMax);
          }
        })
      );
    }
  }
};
