import { FComponent } from 'Components/f-component';

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add('fc-switch');

    this.checked = params.checked;
    this.label = params.label;
    this.disabled = params.disabled;
    this.onChange = params.onChange;
    this.hint = params.hint;

    this.input = ko.observable(null);

    ko.applyBindingsToNode(element, {
      css: {
        'fc-switch--checked': this.checked,
        'fc-switch--disabled': this.disabled
      }
    });
  }

  focus() {
    this.input().focus();
  }

  onInputChange() {
    if (ko.toJS(this.disabled)) return false;

    if (typeof this.onChange === 'function') {
      if (!this.checked) {
        this.onChange();
      } else {
        this.onChange(!this.checked());
      }
    } else {
      this.checked(!this.checked());
      return true;
    }
  }
}
