import './style';

ko.bindingHandlers.stickyFooter = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    element.classList.add('sticky-footer');

    let observer = new IntersectionObserver(([entry]) => {
      element.classList.toggle('stucky', entry.intersectionRatio < 1);
    }, {
      threshold: [0.0, 1.0]
    });
    observer.observe(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {});
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
