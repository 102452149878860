import { FComponent } from 'Components/f-component';
import { icons } from './icons';

const colors = [
  'primary',
  'secondary',
  'danger',
  'success',
  'light',
  'black',
  'stroke',
  'white'
];
const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-icon');

    let name = params.name;
    this.button = params.button;
    this.disabled = params.disabled;

    this.icon = ko.computed(() => {
      let iconName = 'foquz-icon-' + ko.toJS(name);
      return icons[iconName];
    });

    let iconNameClass = ko.computed(() => 'fc-icon--' + ko.toJS(name));

    let iconSize = ko.computed(() => {
      let width = ko.toJS(params.width);
      let height = ko.toJS(params.height);
      let size = ko.toJS(params.size);

      if (width || height) {
        return {
          width: width + 'px',
          height: height + 'px',
        };
      } else if (size) {
        if (sizes.includes(size)) {
          return {
            css: 'fc-icon--' + size
          };
        }

        return {
          width: size && size + 'px',
          height: size && size + 'px'
        };
      }

      return {};
    });

    let iconColor = ko.computed(() => {
      if ('color' in params) {

        let color = ko.toJS(params.color);
        if (colors.includes(color)) {
          return {
            css: 'fc-icon--' + color
          };
        }
        return { color: color };
      }

      return {};
    });


    let css = ko.computed(() => {
      let name = iconNameClass();
      let size = iconSize();
      let color = iconColor();



      return [ name, size.css, color.css ].filter(Boolean).join(' ');
    })

    let style = ko.computed(() => {
      let size = iconSize();
      let color = iconColor();

      return {
        color: color.color,
        width: size.width,
        height: size.height
      };
    })


    ko.applyBindingsToNode(element, {
      css,
      style
    });

  }
}
