export const binding = {
  init: function (element, valueAccessor) {
    let { gallery, index } = valueAccessor();

    element.classList.add("cursor-zoom-in");

    element.gallery = gallery;
    element.index = index;

    const clickHandler = () => {
      let list = element.gallery;
      if (typeof list == "function") {
        list = list();
      }

      $.fancybox.open(list, {
        index: ko.toJS(element.index),
        loop: false,
        buttons: ["rotate", "zoom", "slideShow", "close"],
      });
    };

    $(element).on("click", clickHandler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off("click", clickHandler);
    });
  },
  update: function (element, valueAccessor) {
    let { gallery, index } = valueAccessor();
    element.gallery = gallery;
    element.index = index;
  },
};
