import StickyEvents from 'sticky-events';

let unique = 1;

ko.bindingHandlers.sticky = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    element.classList.add('foquz-sticky');
    element.id = 'foquz-sticky-' + unique++;

    const stickyEvents = new StickyEvents({
      enabled: true,
      stickySelector: '#' + element.id
    });

    let observable = valueAccessor();

    element.addEventListener(StickyEvents.CHANGE, (event) => {
      if (ko.isObservable(observable)) {
        observable(event.detail.isSticky);
      }
      let isSticky = event.detail.isSticky;
      element.classList.toggle('stuck', isSticky);
      $(element).trigger(isSticky ? 'stuck' : 'unstuck');
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      stickyEvents.disableEvents();
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
