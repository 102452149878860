var pureComputed = ko.pureComputed, unwrap = ko.unwrap;
export function commonFormControlErrorStateMatcher(condition) {
    return function (formControl) {
        return pureComputed(function () {
            var isConditionMet = typeof condition === "function" ? condition() : true;
            return ("isValid" in formControl && isConditionMet && !formControl.isValid());
        });
    };
}
export function commonFormControlSuccessStateMatcher(condition) {
    return function (formControl) {
        return pureComputed(function () {
            var isConditionMet = typeof condition === "function" ? condition() : true;
            return ("isValid" in formControl && isConditionMet && formControl.isValid());
        });
    };
}
export var errorMatcher = function (condition) {
    return function (formControl) {
        return pureComputed(function () {
            if (!formControl)
                return false;
            if (!("isValid" in formControl))
                return false;
            var isMatch = !!unwrap(condition);
            if (!isMatch)
                return false;
            return !formControl.isValid();
        });
    };
};
export var successMatcher = function (condition) {
    return function (formControl) {
        return pureComputed(function () {
            if (!formControl)
                return false;
            if (!("isValid" in formControl))
                return false;
            var isMatch = !!unwrap(condition);
            if (!isMatch)
                return false;
            return formControl.isValid();
        });
    };
};
