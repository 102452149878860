const handleFolder = (folder) => {
  const { id, content, ...folderParams } = folder;
  return {
    id: id.toString(),
    ...folderParams,
    children: content.map(handleFolder),
  };
};

export function getPollFolders(companyId) {
  return new Promise((resolve) => {
    $.post(
      "/foquz/ajax/folders",
      { name: "" },
      (response) => {
        resolve(response.data.map(handleFolder));
      },
      "json"
    );
  });
}
