import { FComponent } from "Components/f-component";

export class ViewModel extends FComponent {
  constructor(params, element, nodes) {
    super(params, element, nodes);

    element.classList.add("fc-autocomplete");

    this.dropdownClass = params.dropdownClass;

    this.dropdown = ko.observable(null);
    this.listSearch = ko.observable(null);

    this.disabled = params.disabled;

    this.loading = ko.observable(false);
    this.loaded = ko.observable(false);

    this.query = ko.observable("");
    this.placeholder = params.placeholder;
    this.searchPlaceholder = params.searchPlaceholder;
    this.fields = params.fields || ["text"];

    this.value = params.value;

    this.getOptions = params.getOptions;
    this.options = ko.observableArray([]);

    let debouncedLoading = _.debounce(() => {
      this.loadOptions();
    }, 400);

    this.addSubscription(
      this.query.subscribe((v) => {
        debouncedLoading();
      })
    );

    this.active = ko.observable(false);
    this.active.subscribe((v) => {
      if (v) {
        setTimeout(() => {
          this.listSearch().focus();
        });
      } else {
        this.query("");
      }
    });

    ko.applyBindingsToNode(element, {
      css: {
        "fc-autocomplete--active": this.active,
        "fc-autocomplete--disabled": this.disabled,
        "fc-autocomplete--invalid": params.invalid,
        "fc-autocomplete--valid": params.valid,
      },
    });
  }

  selectQuery(str, q) {
    if (!str) return "";
    if (!q) return str;

    let arr = [];

    let lowerStr = str.toLowerCase();
    let index = lowerStr.indexOf(q);

    while (index > -1) {
      arr.push(lowerStr.slice(0, index));
      arr.push("<b>");
      arr.push(lowerStr.slice(index, index + q.length));
      arr.push("</b>");
      lowerStr = lowerStr.slice(index + q.length);
      index = lowerStr.indexOf(q);
    }

    arr.push(lowerStr);

    return arr.join("");
  }

  handleOptions(items) {
    let q = this.query().trim().toLowerCase();

    return items.map((item) => {
      let res = {
        ...item,
        disabled: ko.observable(false),
      };

      this.fields.forEach((fieldName) => {
        res[fieldName] = this.selectQuery(item[fieldName], q);
      });

      return res;
    });
  }

  loadOptions() {
    let q = this.query();
    if (!q) {
      this.loaded(false);
      this.options([]);
      return;
    }

    this.loaded(false);
    this.loading(true);
    this.getOptions({ q }, (data) => {
      this.options(this.handleOptions(data));
      this.loading(false);
      this.loaded(true);
    });
  }

  selectOption(option) {
    this.value(option);
    this.dropdown().hide();
  }

  remove() {
    this.value(null);
    this.dropdown().hide();
  }

  toggleList() {
    if (ko.toJS(this.disabled)) return;
    if (event.target.closest(".fc-autocomplete-field__remove")) return;

    setTimeout(() => {
      this.dropdown().toggle();
    });
  }

  hide() {
    this.dropdown().hide();
  }
}
