window._fancyboxActive = window._fancyboxActive || false;

export const isFancyboxActive = () => {
  return window._fancyboxActive;
};

export const openFancybox = () => {
  window._fancyboxActive = true;
};
export const closeFancybox = () => {
  window._fancyboxActive = false;
};
