import { FoquzComponent } from "Models/foquz-component";
import { DialogsModule } from "Utils/dialogs-module";
import { PollPublishEvent } from "Utils/events/poll";
import { PollInfoModel } from "Models/poll-info-model";
import { Translator } from "@/utils/translate";
import { copyToClipboard } from "../../utils/copy-to-clipboard";
import { PollSettingsUpdateEvent } from "../../utils/events/poll";

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);
    DialogsModule(this);

    this.translator = Translator("poll");

    this.poll = params.poll;
    this.model = PollInfoModel(params.poll);

    this.copied = ko.observable(false);

    this.noAnswersLeft = ko.computed(() => {
      return this.model.isPublished() && this.model.hasPublishedAnswersLimit() && !this.model.answersLeft();
    });
  }

  openInfoDialog() {
    this.openDialog({
      name: "poll-info-dialog",
      params: {
        poll: this.poll,
      },
    });
  }

  copyId() {
    copyToClipboard(this.model.id).then(() => {
      this.copied(true);
      setTimeout(() => {
        this.copied(false);
      }, 3000);
    });
  }
}
