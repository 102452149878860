import { FComponent } from 'Components/f-component';


export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-user');

    this.name = params.name;
    this.avatar = params.avatar;
    this.hasAvatar = true;

    if (!this.avatar) {
      this.avatar = '/img/user-placeholder.png';
      this.hasAvatar = false;
    }
  }
}
