import { bindPopper } from "./bindPopper";
import { maxWidthModifier } from "./modifiers/max-width";

function updateTooltip(element, title, disabled = false) {
  const tooltip = element._fbTooltip;
  if (!tooltip) return;

  const _title = ko.toJS(title)

  if (!_title) tooltip.remove();
  else {
    const text = tooltip.querySelector(".fc-popper__content");
    if (text) {
      text.innerHTML = _title;
      document.body.append(tooltip);
    }
  }

  if (disabled) {
    tooltip.style.opacity = 0;
  } else {
    tooltip.style.opacity = 1;
  }
}

function createTooltip(title, customClass) {
  let tooltip = document.createElement("div");
  tooltip.classList.add("fc-popper");
  tooltip.classList.add("fc-tooltip");

  if (customClass) tooltip.classList.add(customClass);

  tooltip.innerHTML = `<div class="fc-popper__container">
    <div class="fc-popper__content"></div>
  </div>
  <div class="fc-popper__arrow" data-popper-arrow></div>`;

  const text = tooltip.querySelector(".fc-popper__content");

  const _title = ko.toJS(title)

  if (_title) {
    text.innerHTML = _title;
    document.body.append(tooltip);
  }

  return {
    element: tooltip,
  };
}

export const binding = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let title =
      allBindings.get("title") ||
      allBindings.get("tooltipText") ||
      element.getAttribute("title");

    element.removeAttribute("title");

    let options = valueAccessor() || {};
    const { placement, customClass, ...popperOptions } = options;

    let tooltip = createTooltip(title, customClass);

    element._fbTooltip = tooltip.element;

    bindPopper(element, tooltip.element, {
      placement: placement || "top",
      modifiers: [
        { name: "arrow" },
        { name: "offset", options: { offset: [0, 10] } },
        { name: "flip" },
        maxWidthModifier({ width: 250 }),
      ],
    }).init({ hover: true, click: false, ...popperOptions });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let title =
      allBindings.get("title") ||
      allBindings.get("tooltipText") ||
      element.getAttribute("title");

    updateTooltip(element, title, allBindings.get("fbPopperDisabled"));
  },
};
