import { declOfNum } from "../../../utils/string/decl-of-num";
import "./style";

ko.bindingHandlers.lazySelect2 = {
  init: function init(element, valueAccessor, allBindings) {
    var bindings = allBindings();
    delete bindings.lazySelect2;
    bindings.select2 = valueAccessor();

    ko.applyBindingsToNode(element, {
      childrenComplete: function childrenComplete() {
        ko.applyBindingsToNode(element, bindings);
      },
    });
  },
};

ko.bindingHandlers.select2 = {
  init: function init(element, valueAccessor, allBindings) {
    var $element = $(element);
    var isMultiple = $element.prop("multiple");
    var valueObservable =
      allBindings()[!isMultiple ? "value" : "selectedOptions"];
    let onSelect = allBindings.get("onSelect");
    const onSelecting = allBindings.get("onSelecting");
    const onUnselecting = allBindings.get("onUnselecting");

    try {
      console.log('unwrapObservable', allBindings.get("options")())
    } catch (e) {

    }

    var customOptionNames = ["wrapperCssClass", "dropdownWrapperCssClass"];

    var defaultOptions = _defineProperty(
      {
        minimumResultsForSearch: Infinity,
        language: "ru",
        width: "element",
        dropdownAutoWidth: true,
        placeholder: " ",
      },
      "language",
      {
        inputTooShort: function inputTooShort(args) {
          var remainingChars = args.minimum - args.input.length;

          const count = remainingChars;
          const word = declOfNum(remainingChars, [
            "символ",
            "символа",
            "символов",
          ]);

          var message = _t(
            "main",
            "Введите хотя бы {symbols} для отображения списка",
            {
              symbols: _t("main", `{count} ${word}`, {
                count,
              }),
            }
          );

          return message;
        },
      }
    );

    if (valueObservable) {
      $element.val(ko.utils.unwrapObservable(valueObservable));
    }

    var options = ko.utils.unwrapObservable(valueAccessor());

    var customOptions = _.pick(options, customOptionNames);

    var Select = $.fn.select2.amd.require("jquery.select2");
    var Search = $.fn.select2.amd.require("select2/selection/search");

    var originalSelectRenderFn = Select.prototype.render;
    var originalSearchRenderFn = Search.prototype.render;
    var originalSearchResizeSearchFn = Search.prototype.resizeSearch;

    Select.prototype.render = function () {
      var $container = originalSelectRenderFn.call.apply(
        originalSelectRenderFn,
        [this].concat(Array.prototype.slice.call(arguments))
      );

      if ("wrapperCssClass" in customOptions) {
        $container.addClass(customOptions.wrapperCssClass);
      }

      return $container;
    };

    Search.prototype.render = function () {
      var $rendered = originalSearchRenderFn.call.apply(
        originalSearchRenderFn,
        [this].concat(Array.prototype.slice.call(arguments))
      );

      this.$search.attr("type", "text");

      return $rendered;
    };

    Search.prototype.resizeSearch = function () {
      var _this = this;

      var $mirror = $(
        '<span style="position:absolute; top:-999px; left:0; white-space:pre;"/>'
      );

      // Copy to mirror
      $.each(
        [
          "fontFamily",
          "fontSize",
          "fontWeight",
          "fontStyle",
          "letterSpacing",
          "textTransform",
          "wordSpacing",
          "textIndent",
        ],
        function (i, property) {
          $mirror.css(property, _this.$search.css(property));
        }
      );

      $mirror.text(
        this.$search.val() !== ""
          ? this.$search.val()
          : this.$search.attr("placeholder")
      );

      $("body").append($mirror);

      this.$search.css("width", Math.max($mirror.width(), 15)); 

      $mirror.remove();
    };

    $element.select2(
      $.extend(defaultOptions, _.omit(options, customOptionNames))
    );

    // $element.on('select2:unselecting', function (e) {
    //   // console.log('UNSELECTING', $(this).val(), isMultiple);
    //   // $(this).val(null).trigger('change');
    //   // e.preventDefault();
    // });

    Select.prototype.render = originalSelectRenderFn;
    Search.prototype.render = originalSearchRenderFn;
    Search.prototype.resizeSearch = originalSearchResizeSearchFn;

    function updateEmptyState() {
      var value = $element.val();
      var isEmpty = value === null || value.length === 0;

      let el = $element.data("select2");
      if (el) el.$container.toggleClass("select2-container--empty", isEmpty);
    }

    updateEmptyState();

    $element.on("change", function (evt) {
      $(".select2-selection__rendered").removeAttr("title");
    });

    $element.on("change.select2", function () {
      updateEmptyState();
    });

    $element.on("select2:selecting", function (event) {
      if (onSelecting) {
        return onSelecting(event);
      }
      return true;
    });

    $element.on("select2:unselecting", function (event) {
      if (onUnselecting) {
        return onUnselecting(event);
      }
      return true;
    });

    if ("wrapperCssClass" in customOptions) {
      var $wrapper = $element.data("select2").$container;
      $wrapper.addClass(customOptions.wrapperCssClass);
    }

    var dropdown = $element.data("select2").$dropdown;
    dropdown.addClass("ps-wrapper");
    dropdown.addClass("ps-wrapper--select");

    if ("dropdownWrapperCssClass" in customOptions) {
      dropdown.addClass(customOptions.dropdownWrapperCssClass);
    }

    var scroll = $element.data("select2").$results;
    scroll.addClass("scrollbar-inner");
    // scroll.scrollbar();
    var ps = new PerfectScrollbar(scroll.get(0));

    $element.on("select2:open", function () {
      setTimeout(function () {
        dropdown.removeClass("opening");
        ps.update();
      }, 100);
    });

    $element.on("select2:opening", async function (e) {
      dropdown.addClass("opening");
    });

    let topCloseBtn = $(
      `<button class="select2-dropdown__top-close">&times;</button>`
    );
    topCloseBtn.on("click", () => {
      $element.select2("close");
    });

    let closeBtn =
      $(`<button><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L23 23M23 2L2 23" stroke="#F96261" stroke-width="3" stroke-linecap="round"/>
    </svg></button>`);
    closeBtn.on("click", () => {
      $element.val("").trigger("change");
      $element.select2("close");
    });
    let closeWrapper = $(`<div class="select2-dropdown__close">
    </div>`).append(closeBtn);
    $element
      .data("select2")
      .$dropdown.find(".select2-dropdown")
      .append(closeWrapper)
      .append(topCloseBtn);

    if (valueObservable) {
      if (ko.isObservable(valueObservable)) {
        $element.on("change", function () {
          var value = $element.val();
          console.log('valueObservable', value, isMultiple)
          if (typeof onSelect === "function") onSelect(value);
          else {
            valueObservable(value);
          }
        });
      }
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $element.select2("close");
    });
  },
  update: function update(element, valueAccessor, allBindings) {
    var $element = $(element);
    var isMultiple = $element.prop("multiple");
    var valueObservable =
      allBindings()[!isMultiple ? "value" : "selectedOptions"];
    var containerCss = allBindings.get("select2ContainerCss");

    if (valueObservable && ko.isObservable(valueObservable)) {
      valueObservable.subscribe(function (v) {
        $element.val(v).trigger("change.select2");
      });
    }

    var wrapperCssValue = ko.utils.unwrapObservable(containerCss);
    var $container = $element.data("select2").$selection;

    if (
      wrapperCssValue !== null &&
      (typeof wrapperCssValue === "undefined"
        ? "undefined"
        : _typeof(wrapperCssValue)) == "object"
    ) {
      ko.utils.objectForEach(
        wrapperCssValue,
        function (className, shouldHaveClass) {
          shouldHaveClass = ko.utils.unwrapObservable(shouldHaveClass);
          ko.utils.toggleDomNodeCssClass(
            $container.get(0),
            className,
            shouldHaveClass
          );
        }
      );
    }
  },
};

// TODO перенести настройки из main.js
// TODO описать конфигурацию
// TODO dispose
