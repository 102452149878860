/** Поле для ввода только цифр */
import Inputmask from 'inputmask/lib/inputmask';
import "inputmask/lib/extensions/inputmask.numeric.extensions"

ko.bindingHandlers.decimalNumbers = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    let config = valueAccessor() || {};

    let im = new Inputmask("decimal", {
      radixPoint: '.',
      inputtype: 'text',
      placeholder: '',
      ...config
    }).mask(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Inputmask.remove(element);
    });
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {}
};
