import { FoquzComponent } from 'Models/foquz-component';

const classes = {
  stickyHead: 'complex-table--sticky-head',
  stickyHeadBlock: 'complex-table--sticky-head-block',
  fixedHead: 'complex-table--fixed-head',
  horizontalScroll: 'complex-table--scroll'
};

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params, element);

    this.fixedHeader = params.fixedHeader;
    this.horizontalScroll = params.scroll;

    if (this.fixedHeader) {
      if (this.horizontalScroll) element.classList.add(classes.stickyHeadBlock);
      else element.classList.add(classes.stickyHead);
    }

    if (this.horizontalScroll) {
      element.classList.add(classes.horizontalScroll);
    }

    this.headTableContainer = ko.observable(null);
    this.bodyTableContainer = ko.observable(null);
    this.stickyTarget = ko.observable(null);

    this.headTable = ko.observable(null);
    this.bodyTable = ko.observable(null);

    this.scrollbar = ko.observable(null);

    this.onDisposeCb = [];
  }

  render(el) {
    let bodyContainer = this.bodyTableContainer();

    if (!bodyContainer) return;

    if (this.horizontalScroll && this.fixedHeader) {
      let headContainer = this.headTableContainer();

      let head = el.querySelector('thead');
      let headTable = document.createElement('table');
      headTable.appendChild(head);
      headContainer.appendChild(headTable);

      this.updateSizes();

      let table = bodyContainer.querySelector('table');

      let observer = new MutationObserver(() => {
        this.updateSizes();
      });
      observer.observe(table, {
        childList: true,
        subtree: true
      });

      let cb = (e) => {
        headContainer.scrollLeft = e.target.scrollLeft;
      };

      let viewport = bodyContainer.querySelector('.os-viewport');
      viewport.addEventListener('scroll', cb);

      this.onDisposeCb.push(() => {
        observer.disconnect();
      });
      this.onDisposeCb.push(() => {
        viewport.removeEventListener('scroll', cb);
      });
    }

    if (this.fixedHeader) {
      setTimeout(() => {
        let stickyTarget = this.stickyTarget();
        if (!stickyTarget) return;
        let observer = new IntersectionObserver(
          ([e]) => {
            let isIntersecting = e.isIntersecting;
            this.element.classList.toggle(classes.fixedHead, !isIntersecting);
            this.emitEvent(isIntersecting ? 'unstuck' : 'stuck');
          },
          { rootMargin: '0px 100px' }
        );
        observer.observe(stickyTarget);

        this.onDisposeCb.push(() => observer.unobserve(stickyTarget))
      }, 1000);
    }
  }

  scroll(value) {
    let sign = value > 0 ? '+' : '-';
    this.scrollbar().instance.scroll(
      { x: `${sign}= ${Math.abs(value)}px` },
      400
    );
  }

  updateSizes() {
    let headCells = this.headTableContainer().querySelectorAll('th');
    let row = this.bodyTableContainer().querySelector('tr');
    if (!row) return;
    let cells = row.querySelectorAll('td');

    headCells.forEach((headCell, i) => {
      let cell = cells[i];
      headCell.style.minWidth = '';
      cell.style.minWidth = '';

      let sWidth = headCell.width;
      let hWidth = headCell.offsetWidth;
      let cWidth = cell.offsetWidth;

      let width = Math.max(sWidth, hWidth, cWidth);

      headCell.style.minWidth = width + 'px';
      cell.style.minWidth = width + 'px';
    });
  }

  dispose() {
    super.dispose();
    this.onDisposeCb.forEach(cb => cb())
  }
}
