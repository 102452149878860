$(function () {
  ko.components.register('number-control', {
    viewModel: {
      createViewModel: function (params, componentInfo) {
        const $element = $(componentInfo.element);

        const viewModel = new (function () {
          this.value = params.value;
          this.isCurrency = params.isCurrency || false;
          this.isInvalid = params.isInvalid || false;
          this.isDisabled = params.isDisabled || ko.observable(false);
          if (!ko.isObservable(this.isDisabled))
            this.isDisabled = ko.observable(this.isDisabled);
          this.className = params.className || '';

          this.min = 'min' in params ? params.min : -Infinity;
          this.max = 'max' in params ? params.max : Infinity;

          this.hasValue = ko.pureComputed(() => {
            return this.value() !== '';
          });

          this.numberValue = ko.pureComputed(() => {
            return parseInt(this.value()) || 0;
          });

          this.disableIncrement = ko.pureComputed(() => {
            return this.numberValue() >= this.max;
          });
          this.disableDecrement = ko.pureComputed(() => {
            return this.numberValue() <= this.min;
          });

          this.incrementValue = () => {
            if (this.isDisabled()) return false;
            if (this.disableIncrement()) return false;
            this.value(this.numberValue() + 1);
          };

          this.decrementValue = () => {
            if (this.isDisabled()) return false;
            if (this.disableDecrement()) return false;
            this.value(this.numberValue() - 1);
          };

          this.isNumberKey = (evt) => {
            let charCode = evt.which ? evt.which : event.keyCode;
            return !(charCode > 31 && (charCode < 48 || charCode > 57));
          };

          this.value.subscribe(v => {
            if (this.numberValue() < this.min || this.numberValue() > this.max) {
              this.value(this.min);
            }
          })
        })();

        viewModel.onInit = function () {};

        return viewModel;
      }
    },
    template: `
            <!-- ko template: { afterRender: onInit } -->
              <div class="number-control" data-bind="class: className">
                  <span class="control-button control-button--minus"
                        data-bind="click: decrementValue, css: {
                            'disabled': isDisabled() || disableDecrement()
                        }">
                  </span>
                  <input class="form-control"
                        value="1"
                        data-bind="textInput: value,
                        css: {
                            'js-is-currency': isCurrency,
                            'is-invalid': isInvalid,
                        },
                        disable: isDisabled,
                        event: {
                            keypress: function(event) { return isNumberKey(event); },
                            blur: function() {
                              value(numberValue());
                            }
                        }">
                  <span class="control-button control-button--plus"
                        data-bind="click: incrementValue, css: {
                          'disabled': isDisabled() || disableIncrement()
                      }">
                  </span>
              </div>
            <!-- /ko -->
            `
  });
});
