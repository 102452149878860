import { FComponent } from '@/components/f-component';
import { dateToString } from '@/utils/date/date-to-string';
import { dateFromString } from '@/utils/date/date-from-string';
import { today } from '@/utils/date/today';
import { getMobileWatcher } from '@/utils/window/mobile-watcher';

import { PeriodSection } from './period-section';

import '../fc-month-calendar';

export const RANGE_CLOSED = 'calendar-range-closed';
export const RANGE_CHANGED = 'calendar-range-changed';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-period-calendar');

    // для мобильного вида
    this.isMobile = getMobileWatcher();
    this.activeTab = ko.observable('from');

    this.from = new PeriodSection();
    this.to = new PeriodSection();

    // незафиксированный конец периода
    this.tmpTo = ko.observable(null);

    // отображаемый месяц на второй календаре связан с первым
    this.setToDate();
    this.from.on('date.change', (v) => {
      this.setToDate();
    });

    this.to.on('value.change', (v) => {
      setTimeout(() => {
        // при закрытии периода переключиться на его начало (кроме мобильного отображения)
        if (!this.isMobile() && v && this.from.value) {
          this.from.setDate(dateFromString(this.from.value));
        }
        // при сбросе периода отобразить текущий месяц
        else if (!v && !this.from.value) {
          this.from.setDate(today);
          this.activeTab('from');
        }
      });
    });

    this.from.on('temp.value.change', () => {
      this.emitEvent(RANGE_CHANGED);
    });

    // настройки диапазона для компонента календаря
    this.rangeClosed = ko.computed(() => {
      return this.to.value && this.from.value;
    });
    this.rangeFrom = ko.computed(() => {
      if (this.from.value) {
        return this.from.value;
      }
      return null;
    });
    this.rangeTo = ko.computed(() => {
      if (!this.from.value) return null;

      if (this.rangeClosed()) return this.to.value;
      if (this.tmpTo()) {
        return dateToString(this.tmpTo(), 'DD.MM.YYYY');
      }
      return null;
    });

    if (params.value) {
      // установка начального значения
      this.setValue(ko.toJS(params.value));

      if (ko.isObservable(params.value)) {
        // изменение значения извне
        let valueCb = params.value.subscribe((v) => {
          this.setValue(v);
        });

        this.onDispose(() => {
          valueCb.dispose();
        });
      }
    }

    // закрытие диапазона
    let rangeCb = this.rangeClosed.subscribe((v) => {
      if (v) {
        this.emitEvent(RANGE_CLOSED, {
          from: this.rangeFrom(),
          to: this.rangeTo()
        });
      }
    });

    this.onDispose(() => {
      rangeCb.dispose();
    });
  }

  getValue() {
    return {
      from: this.rangeFrom(),
      to: this.rangeClosed() ? this.rangeTo() : null
    };
  }

  /**
   * Обновление выбранного диапазона
   * @param value {Object.<from, to>}
   */
  setValue(value) {
    if (!value) {
      this.from.setValue('');
      this.to.setValue('');
      return;
    }
    let { from, to } = value;
    if (from && to) {
      this.from.setValue(from);
      this.to.setValue(to);
    } else {
      this.from.setValue('');
      this.to.setValue('');
    }
  }

  /**
   * Обновление второго календаря в соответствии с первым
   */
  setToDate() {
    let fromDate = +this.from.date();
    let toDate = new Date(fromDate);
    toDate.setMonth(toDate.getMonth() + 1);
    this.to.date(toDate);
  }

  /** Выбор даты (на любом календаре) */
  onSelect(date) {
    if (!this.from.value) {
      this.from.setValue(date);
    } else if (this.to.value) {
      this.from.setValue(date);
      this.to.setValue('');
    } else {
      let fromDate = dateFromString(this.from.value);

      if (fromDate > date) {
        this.from.setValue(date);
      } else {
        this.to.setValue(date);
      }
    }
  }

  /** Изменение  месяца на первом календаре */
  onFromMonthChange(date) {
    this.from.setDate(date);
  }

  /** Изменение  месяца на втором календаре */
  onToMonthChange(date) {
    let fromDate = new Date(+date);
    fromDate.setMonth(fromDate.getMonth() - 1);
    this.from.setDate(fromDate);
  }

  /** Наведение курсора на день (возможный конец диапазона) */
  onDayHover(date) {
    if (!this.from.date()) return;
    this.tmpTo(date);
  }

  /** Синхронизирует отображение календарей с выбранным началом диапазона */
  sync() {
    this.from.sync();
  }

  /** Синхронизирует первый календарь с выбранным началом диапазона */
  syncFrom() {
    this.from.sync();
  }

  /** Синхронизирует второй календарь с выбранным началом диапазона */
  syncTo() {
    this.to.sync();
  }
}
