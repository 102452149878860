import moment from 'moment';
export const periodPickerRanges = {
  Сегодня: [moment(), moment()],
  'С начала недели': [moment().startOf('isoWeek'), moment()],
  'Прошлая неделя': [
    moment().subtract(1, 'weeks').startOf('isoWeek'),
    moment().subtract(1, 'weeks').endOf('isoWeek')
  ],
  'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
  'Текущий месяц': [moment().startOf('month'), moment()],
  'Прошлый месяц': [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')
  ],
  'Последний месяц': [moment().subtract(29, 'days'), moment()]
};

export function getRanges() {
  let ranges = {};
  Object.keys(periodPickerRanges).forEach((key) => {
    ranges[_t(key)] = periodPickerRanges[key];
  });
  return ranges;
}
