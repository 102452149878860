import { FoquzComponent } from "Models/foquz-component";
import { ApiUrl } from "Utils/url/api-url";
import { PollActivateEvent, PollPublishEvent } from "@/utils/events/poll";
import { Translator } from "@/utils/translate";
import { stopPoll, startPoll } from "@/api/poll/publish-poll";
import { DialogsModule } from "@/utils/dialogs-module";
export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    DialogsModule(this);

    this.translator = Translator("poll");

    this.confirmText = `<div class="mb-3">
        ${this.translator.t(
          "Для опубликованного опроса в прохождении не будет отображаться метка Тестовый режим."
        )()}
      </div>
      <div>
        ${this.translator.t(
          "В опубликованном опросе нельзя очистить статистику, а также сам опрос нельзя будет удалить."
        )()}
      </div>`;

    this.poll = params.poll;
    this.id = this.poll.id;

    this.isPublished = ko.observable(this.poll.isPublished);
    this.isTmp = ko.observable(this.poll.isNew);
    this.isActive = ko.observable(this.poll.isActive);

    if (this.isTmp()) {
      PollActivateEvent.once((params) => {
        if (params.id == this.id) this.isTmp(false);
      });
    }

    if (!this.isPublished()) {
      PollPublishEvent.once((params) => {
        if (params.id == this.id) this.isPublished(true);
      });
    }
  }

  publish() {
    this.confirm({
      title: this.translator.t("Опрос будет опубликован"),
      text: this.confirmText,
      confirm: this.translator.t("Опубликовать"),
      mode: "success",
    }).then(() => {
      $.ajax({
        url: ApiUrl("poll/publish"),
        data: {
          id: this.id,
        },
        success: (response) => {
          PollPublishEvent.emit(this.poll);
        },
        error: (response) => {
          console.error(response.responseJSON);
          // PollPublishEvent.emit({ id: this.id, test: true });
        },
      });
    });
  }

  stop() {
    stopPoll(this.id).then(() => {
      this.isActive(false);
    });
  }

  start() {
    startPoll(this.id).then(() => {
      this.isActive(true);
    });
  }
}
