import { FComponent } from 'Components/f-component';
import { detectTouch } from 'Utils/detect/touch';

export class ViewModel extends FComponent {
  constructor(params, element) {
    super(params, element);

    element.classList.add('fc-scroll');
    this.scroll = ko.observable({
      onReachEnd: params.onReachEnd,
    });

    this.indicator = 'indicator' in params ? params.indicator : true;

    if (this.indicator) {
      element.classList.add('fc-scroll--indicator');
    }

    if (detectTouch() || !this.indicator) {
      element.classList.add('fc-scroll--touch');
    }
  }

  onElementRender() {
    let scroll = this.scroll();
    ko.applyBindingsToNode(this.element, {
      css: {
        'fc-scroll--has-x': scroll.horizontal,
        'fc-scroll--has-y': scroll.vertical,
        'fc-scroll--start-x': scroll.left,
        'fc-scroll--end-x': scroll.right,
        'fc-scroll--start-y': scroll.top,
        'fc-scroll--end-y': scroll.bottom
      }
    });
  }

  get instance() {
    return this.scroll().instance;
  }

  update(force) {
    this.instance.update(force);
  }

  move(offset) {
    this.instance.scroll(offset);
  }
}
