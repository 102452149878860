import { DEFAULT_LANG, LANGS_DATA } from "@/constants/langs";

export let cache = {};
export const translators = {};

const defaultLang = LANGS_DATA[DEFAULT_LANG];
const defaultLangCode = defaultLang?.code;

export const getLang = () => {
  const lang = window.APP_LANG;

  if (lang) {
    const langData = Object.values(LANGS_DATA).find(
      (l) => l.code === lang || l.shortCode === lang
    );
    if (langData) return langData.code;
  }

  return defaultLangCode;
};

export const loadDict = (file, lang) => {
  const _lang = lang || getLang();

  if (_lang === defaultLangCode) return Promise.resolve({});

  if (cache[file]) return Promise.resolve(cache[file]);

  return import(/* webpackMode: "lazy" */ `/messages/${_lang}/${file}.json`)
    .then((mod) => {
      cache[file] = mod.default;
      return cache[file];
    })
    .catch((err) => {
      console.warn(err);
      cache[file] = {};
      return cache[file];
    });
};

export const setParams = (str, params) => {
  if (!params) return str;

  Object.keys(params).forEach((key) => {
    let value = params[key];
    if (ko.isObservable(params[key]) || typeof params[key] === "function") {
      value = params[key]();
    }
    str = str.replace(new RegExp("{" + key + "}", "g"), value);
  });

  return str;
};

export const Translator = (file) => {
  if (translators[file]) return translators[file];

  let _savedLang = getLang();

  let loaded = ko.observable(false);
  let dictionary = ko.observable({});

  loadDict(file).then((dict) => {
    if (getLang() !== _savedLang) return;
    dictionary(dict);
    loaded(true);
  });

  const model = {
    t: (str, params) => {
      return ko.computed(() => {
        let localeStr = dictionary()[str] || str;
        return setParams(localeStr, params);
      });
    },
    setLang: (lang) => {
      loaded(false);
      loadDict(file, lang).then((dict) => {
        if (lang !== getLang()) return;
        dictionary(dict);
        loaded(true);
      });
    },
    loaded,
  };

  translators[file] = model;

  return model;
};

export function setGlobalLang(lang) {
  window.APP_LANG = lang;
  cache = {};
  Object.values(translators).forEach((t) => {
    if (typeof t.setLang === "function") t.setLang(lang);
  });
}

window.translator =
  window.translator ||
  (() => {
    const defaultDictionaries = ["main"];
    const dictionaries = {};

    const loadDictionary = (file) => {
      const lang = getLang();
      if (lang === defaultLangCode) return Promise.resolve();

      if (dictionaries[file]) return dictionaries[file];
      return import(
        /* webpackMode: "lazy" */ `/messages/${lang}/${file}.json`
      ).then((mod) => {
        dictionaries[file] = mod.default;
        return dictionaries[file];
      });
    };

    const load = (...files) => {
      const lang = getLang();

      if (lang === "ru-RU") return Promise.resolve();

      let dicts = [...new Set([...defaultDictionaries, ...files])];

      let promises = dicts.map((dict) => {
        if (dictionaries[dict]) return Promise.resolve();

        return import(
          /* webpackMode: "lazy" */ `/messages/${lang}/${dict}.json`
        ).then((mod) => {
          dictionaries[dict] = mod.default;
        });
      });

      return Promise.all(promises);
    };

    const translate = (file, str, params) => {
      if (!str && !params) {
        str = file;
        file = "main";
      }

      const lang = getLang();

      if (lang !== "ru-RU") {
        const dictionary = dictionaries[file];

        if (dictionary && dictionary[str]) {
          str = dictionary[str];
        } else {
          console.warn("translator fail", str, file, dictionary);
        }
      }

      return setParams(str, params);
    };

    return {
      getLang,
      load,
      loadDictionary,
      translate,
      formatString: setParams,
      dictionaries,
    };
  })();
