/**
 * https://kingsora.github.io/OverlayScrollbars/#!documentation
 */

import OverlayScrollbars from 'overlayscrollbars';
import 'overlayscrollbars/css/OverlayScrollbars.min.css';

export const binding = {
  init: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {
    ko.applyBindingsToDescendants(bindingContext, element);

    let scroll = {
      xScroll: ko.observable(0),
      yScroll: ko.observable(0),
      top: ko.observable(true),
      bottom: ko.observable(false),
      left: ko.observable(true),
      right: ko.observable(false),
      vertical: ko.observable(false),
      horizontal: ko.observable(false),
      width: ko.observable(false),
      height: ko.observable(false),
      scrollWidth: ko.observable(false),
      scrollHeight: ko.observable(false)
    };

    function setPositions(element) {
      let track = element.querySelector('.os-viewport');
      if (!track) return;

      scroll.xScroll(track.scrollLeft);
      scroll.yScroll(track.scrollTop);

      let width = track.offsetWidth;
      let scrollWidth = track.scrollWidth;
      let hasXScroll = scrollWidth > width;

      let height = track.offsetHeight;
      let scrollHeight = track.scrollHeight;
      let hasYScroll = scrollHeight > height;

      scroll.width(width);
      scroll.scrollWidth(scrollWidth);
      scroll.height(height);
      scroll.scrollHeight(scrollHeight);

      scroll.vertical(hasYScroll);
      scroll.horizontal(hasXScroll);

      if (hasXScroll) {
        let xOffset = track.scrollLeft;
        scroll.left(xOffset === 0);
        scroll.right(xOffset + width === scrollWidth);
      }

      if (hasYScroll) {
        let yOffset = track.scrollTop;
        scroll.top(yOffset === 0);
        scroll.bottom(yOffset + height === scrollHeight);
      }
    }

    let config = {
      sizeAutoCapable: true,
      ...(valueAccessor() || {}),
      callbacks: {
        onInitialized: () => {
          setPositions(element);
        },
        onContentSizeChanged: (event) => {
          setPositions(element);
        },
        onScroll: function() {
          setPositions(element);
          const scrollInfo = this.scroll();
          if (scrollInfo.ratio.y === 1 && ko.unwrap(valueAccessor()).onReachEnd) {
            ko.unwrap(valueAccessor()).onReachEnd();
          }
        },
      }
    };

    let scrollbar = OverlayScrollbars(element, config);

    element.classList.add('fb-scroll');

    let throttledSetPositions = _.throttle(() => setPositions(element), 200);

    let ref = valueAccessor();
    if (ko.isObservable(ref)) {
      $(window).resize(throttledSetPositions).scroll(throttledSetPositions);

      ref({
        ...(ref() ? ref() : {}),
        ...scroll,
        instance: scrollbar
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      element.classList.remove('fb-scroll');

      let instance = OverlayScrollbars(element);
      if (instance) {
        instance.destroy();
      }

      if (ko.isObservable(ref)) ref(null);

      $(window).off('resize', throttledSetPositions);
      $(window).off('scroll', throttledSetPositions);
    });

    return { controlsDescendantBindings: true };
  }
};
