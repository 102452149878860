// https://refreshless.com/nouislider/

/**
 * - value {Number|Array}
 * - min {Number}
 * - max {Number}
 * - step {Number}
 */

import { isArray as _isArray } from 'lodash';

import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export const binding = {
  init: function init(element, valueAccessor) {
    let cbs = [];

    let config = valueAccessor() || {};

    const { value, min, max, step } = config;
    let startValue = ko.toJS(value);
    const isSingleValue = !Array.isArray(startValue);

    if (!element.noUiSlider) {
      noUiSlider.create(element, {
        animate: false,
        start: isSingleValue ? [startValue] : startValue,
        connect: isSingleValue ? 'lower' : true,
        step: step || 1,
        range: {
          min: ko.isObservable(min) ? min() : min || 0,
          max: ko.isObservable(max) ? max() : max || 100,
        },
        tooltips: {
          from: (value) => Math.round(parseFloat(value)),
          to: (value) => Math.round(parseFloat(value))
        }
      });
    }

    if (ko.isObservable(value)) {
      element.noUiSlider.on('update', (newValue) => {
        let formattedValue = newValue.map((v) => parseInt(v));
        let currentValue = ko.toJS(value);
        if (isSingleValue) {
          if (currentValue !== formattedValue[0]) value(formattedValue[0]);
        } else {
          if (JSON.stringify(formattedValue) !== JSON.stringify(currentValue))
            value(formattedValue);
        }
      });

      let cb = value.subscribe((v) => {
        element.noUiSlider.set(v);
      });
      cbs.push(() => cb.dispose());
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      cbs.forEach((cb) => cb());
    });
  },
  update: function(element, valueAccessor) {
    const maxVal = ko.unwrap(valueAccessor().max);
    const oldMax = element.noUiSlider.options.range.max;
    const minVal = ko.unwrap(valueAccessor().min);
    const oldMin = element.noUiSlider.options.range.min;
    if ((oldMax !== maxVal) || (oldMin !== minVal)) {
      element.noUiSlider.updateOptions({
        range: {
          min: minVal,
          max: maxVal,
        },
      }, true);

      const lastValue = ko.unwrap(valueAccessor().value);

      if (
        (_isArray(lastValue) && lastValue[0] === oldMin) ||
        (!_isArray(lastValue) && lastValue === oldMin)
      ) {
        element.noUiSlider.set(_isArray(lastValue) ? [minVal, lastValue[1]] : minVal);
      }
      if (
        (_isArray(lastValue) && lastValue[1] === oldMax) ||
        (!_isArray(lastValue) && lastValue === oldMax)
      ) {
        element.noUiSlider.set(_isArray(lastValue) ? [lastValue[0], maxVal] : maxVal);
      }
    }
  }
};
