import { FoquzComponent } from 'Models/foquz-component';
import { getChannelData } from 'Utils/channels';

export class ViewModel extends FoquzComponent {
  constructor(params, element) {
    super(params);

    this.channel = getChannelData(params.channel);
    this.repeats = params.repeats;
    this.inactive = params.inactive;

    if (this.channel) {
      let tooltip = `Канал связи "${this.channel.name}". Количество повторов: ${this.repeats}`;
      if (this.inactive)
        tooltip = `Канал связи "${this.channel.name}" не используется для рассылки`;

      $(element).tooltip({
        title: tooltip
      });
    }
  }
}
