class AutosizeInputOptions {
  constructor(params = {}) {
    this.space = "space" in params ? params.space : 30;
  }
}

class AutosizeInput {
  constructor(input, options = {}) {
    this._input = $(input);
    this._options = {
      ...AutosizeInput.getDefaultOptions(),
      ...options,
    };
    this._options = $.extend({}, AutosizeInput.getDefaultOptions(), options);
    // Init mirror
    this._mirror = $(
      '<span style="position:absolute; top:-999px; left:0; white-space:pre;"/>'
    );
    let mirror = this._mirror.get(0);

    let inputStyle = getComputedStyle(input);

    // Copy to mirror
    $.each(
      [
        "fontFamily",
        "fontSize",
        "fontWeight",
        "fontStyle",
        "letterSpacing",
        "textTransform",
        "wordSpacing",
        "textIndent",
      ],
      function (i, val) {
        //_this._mirror[0].style[val] = _this._input.css(val);
        mirror.style[val] = inputStyle[val];
      }
    );

    $("body").append(this._mirror);

    // Bind events - change update paste click mousedown mouseup focus blur
    // IE 9 need keydown to keep updating while deleting (keeping backspace in - else it will first update when backspace is released)
    // IE 9 need keyup incase text is selected and backspace/deleted is hit - keydown is to early
    // How to fix problem with hitting the delete "X" in the box - but not updating!? mouseup is apparently to early
    // Could bind separatly and set timer
    // Add so it automatically updates if value of input is changed http://stackoverflow.com/a/1848414/58524
    this.updateHandler = (e) => this.update();
    this._input.on(
      "keydown keyup input propertychange change blur",
      this.updateHandler
    );

    // Update
    this.update();
  }

  getOptions() {
    return this._options;
  }

  update() {
    var value = this._input.val() || this._input.attr("placeholder") || "";

    // Update mirror
    this._mirror.text(value);
    // Calculate the width
    var newWidth = this._mirror.width() + this._options.space;
    // Update the width
    this._input.width(newWidth);
  }

  dispose() {
    this._input.off(
      "keydown keyup input propertychange change blur",
      this.updateHandler
    );
  }
}

AutosizeInput._defaultOptions = new AutosizeInputOptions();
AutosizeInput.getDefaultOptions = function () {
  return this._defaultOptions;
};
AutosizeInput.getInstanceKey = function () {
  // Use camelcase because .data()['autosize-input-instance'] will not work
  return "autosizeInputInstance";
};

function init(input, options) {
  var validTypes = [
    "text",
    "password",
    "search",
    "url",
    "tel",
    "email",
    "number",
  ];

  if (input.tagName !== "INPUT") return;
  if (!validTypes.includes(input.type)) return;

  if (input[AutosizeInput.getInstanceKey()]) return;

  input[AutosizeInput.getInstanceKey()] = new AutosizeInput(input, options);
}

function dispose(input) {
  let instance = input[AutosizeInput.getInstanceKey()];
  if (instance) instance.dispose();
}

export { init, dispose };
