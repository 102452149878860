export class ViewModel {
  constructor(params, element) {
    if (params.loader) {
      this.loading = params.loader.isPreviewLoading;
      this.preview = params.loader.preview;
      this.disabled = params.disabled;
      this.objectPosition = ko.computed(() => {
        let position;
        if (ko.isObservable(params.objectPosition)) {
          switch (params.objectPosition()) {
            case 0:
              position = 'top';
              break;
            case 1:
              position = 'center';
              break;
            case 2:
              position = 'bottom';
              break;
          
            default:
              position = 'center';
              break;
          }
        } else {
          position = 'center';
        }
        return 'media-load-button__preview_' + position;
      });

      element.classList.toggle('media-load-button--loading', !!this.loading());
      this.loading.subscribe((v) => {
        element.classList.toggle('media-load-button--loading', !!v);
      });

      element.classList.toggle('media-load-button--preview', !!this.preview());
      this.preview.subscribe((v) => {
        element.classList.toggle('media-load-button--preview', !!v);
      });

      this.remove = () => {
        !this.disabled && params.loader.remove();
      };
      this.load = () => {
        !this.disabled && params.loader.open();
      };

      if (params.loader.error()) element.classList.add('is-invalid');
      params.loader.error.subscribe((v) => {
        if (v) element.classList.add('is-invalid');
        else element.classList.remove('is-invalid');
      });
    } else {
      this.loading = params.loading;
      this.preview = params.preview;
      this.remove = () => {
        if (!this.disabled && typeof params.remove == 'function') params.remove();
      };

      this.load = () => {
        if (!this.disabled && typeof params.load == 'function') params.load();
      };
    }

    this.gallery = ko.pureComputed(() => {
      if (params.gallery) return ko.toJS(params.gallery);
      return [{ src: this.preview() }];
    });
    this.index = params.index;

  }
}
