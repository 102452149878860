var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var FoquzEvent = /** @class */ (function () {
    function FoquzEvent() {
        this.callbacks = [];
    }
    FoquzEvent.add = function (eventName) {
        if (!FoquzEvent.events[eventName]) {
            var event_1 = new FoquzEvent();
            FoquzEvent.events[eventName] = event_1;
        }
    };
    FoquzEvent.get = function (eventName) {
        FoquzEvent.add(eventName);
        return FoquzEvent.events[eventName];
    };
    FoquzEvent.prototype.on = function (cb) {
        if (typeof cb == "function")
            this.callbacks.push(cb);
    };
    FoquzEvent.prototype.once = function (cb) {
        var _this = this;
        var _cb = function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            cb.apply(void 0, params);
            _this.off(_cb);
        };
        this.on(_cb);
    };
    FoquzEvent.prototype.off = function (cb) {
        var index = this.callbacks.indexOf(cb);
        this.callbacks.splice(index, 1);
    };
    FoquzEvent.prototype.emit = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        __spreadArray([], this.callbacks, true).forEach(function (cb) { return cb.apply(void 0, params); });
    };
    FoquzEvent.events = {};
    return FoquzEvent;
}());
window.FoquzEvent = window.FoquzEvent || FoquzEvent;
export var Event = function () { return window.FoquzEvent; };
